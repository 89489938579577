////
/// VERTICAL TABS component
///
/// Custom project Vertical tabs
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .obe-vertical-tabs
/// @since version 1.0.0
////

.obe-vertical-tabs {
  background-color: color('white');
  display: none;

  /* Account information section */
  &__accountinfo-section {
    color: color('black');

    p-divider {
      display: none;
    }
  }

  &__accountinfo-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: rem(4);
  }

  &__accountinfo-title {
    font-family: "Helvetica-Neue-75";
    font-size: rem(20);
    line-height: rem(22);
    width: 100%;
  }

  &__accountinfo-number {
    font-family: "Helvetica-Neue-75";
    font-size: rem(16);
    margin-bottom: rem(14);
    color: color("primary");
  }

  &__accountinfo-info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.width100 {
      margin-bottom: rem(20);
    }

    &--none {
      margin-bottom: rem(20);
    }
  }

  &__accountinfo-info-col {
    margin-bottom: rem(5);
    width: 100%;

    &:first-child {
      font-family: "Helvetica-Neue-75";
      width: rem(195);
    }

    span {
      line-height: rem(22);
    }

    .o-btn.o-btn--link-italic.alignRight {
      display: none;
    }

    .o-btn.o-btn--link-italic {
      color: color('terciary');
      font-style: normal;
    }
  }

  &__accountinfo-info-text {
    a {
      text-decoration: underline;
    }
  }

  /* Subscriptions section */
  &__subscriptions {
    font-family: $ns-font-default;
    color: color('black');
  }

  &__subscriptions-section {

    &:not(:last-child) {
      margin-bottom: rem(40);
    }

    .o-btn.o-btn--link-italic {
      color: color('terciary');
      font-style: normal;
    }
  }

  &__subscriptions-title-container {
    .o-btn.o-btn--link-italic {
      display: none;
      color: color('terciary');
      font-style: normal;
    }
  }

  &__subscriptions-title {
    font-size: rem(20);
    font-family: $ns-font-default;
    font-weight: bold;
  }

  &__subscriptions-number {
    font-size: rem(16);
    font-family: $ns-font-default;
    font-weight: bold;
    margin-bottom: rem(14);
    color: color('primary');
  }

  &__subscriptions-info-row {
    margin-bottom: rem(5);
  }

  &__subscriptions-info-col {
    display: inline-block;
    font-size: rem(14);

    &:first-child {
      width: 53%;
      font-family: $ns-font-default;
      font-weight: bold;
    }

    &:last-child {
      width: 47%;
    }
  }

  &__subscriptions-info {
    margin-bottom: rem(23);
  }

  /* Contact preferences */
  &__contact {
    font-family: $ns-font-default;
    font-size: rem(14);
    line-height: rem(20);
    color: color('black');
  }

  &__contact-block:not(:last-child) {
    margin-bottom: rem(26);
    border-bottom: rem(1) solid color('grey-4');
  }

  &__contact-text {
    margin-bottom: rem(19);

    &--title {
      font-family: $ns-font-default;
      font-weight: bold;
      margin-bottom: 0;
      color: color('primary');
    }
  }

  &__contact-radio-wrapper {
    margin-bottom: rem(50);

    .o-radiobutton {
      display: block;
      margin-bottom: rem(21);
    }

    .p-radiobutton-label {
      font-family: $ns-font-default;
      font-weight: bold;
    }

    .o-radio {
      margin-bottom: rem(20);
    }

    .o-radio__label {
      align-items: flex-start;
    }

    .o-radio__icon {
      margin-top: rem(2);
    }

    .o-radio__txt {
      font-size: rem(14);
    }
  }

  &__contact-check-wrapper {

    .o-check {
      align-items: flex-start;
    }

    .o-check__wrapper {
      display: block;
    }

    .p-checkbox-label {
      line-height: rem(18);
    }

    & > .o-checkbox {
      margin-bottom: rem(29);
    }

    .o-checkbox__label {
      align-items: flex-start;
    }

    .o-checkbox__txt {
      font-size: rem(14);
      line-height: rem(18);
    }

    &--mb-medium {
      margin-bottom: rem(36);
    }

    &--mb-xmedium {
      margin-bottom: rem(30);
    }

    &--inline {
      padding-left: rem(30);

      .o-check__wrapper {
        margin-bottom: rem(7);
      }

      .o-checkbox {
        margin-bottom: rem(16);
      }
    }
  }

  &__contact-check-sub {
    padding-left: rem(30);

    .o-check__wrapper {
      margin-bottom: rem(11);
    }

    .o-checkbox {
      margin-bottom: rem(16);
    }
  }

  /* Notifications section */
  &__notifications {
    font-family: $ns-font-default;
    color: color('black');
  }

  &__notifications-switch {
    margin-bottom: rem(4);
  }

  &__notifications-switch-title {
    display: block;
    margin-top: rem(21);
  }

  &__notifications-switch-label {
    font-family: $ns-font-default;
    font-weight: bold;
    line-height: rem(19);
  }

  &__notifications-switch-sub {
    font-size: rem(14);
    font-family: $ns-font-default;
    font-weight: bold;
    vertical-align: top;
    color: color('primary');

    &:hover {
      color: color('primary')
    }
  }

  &__notifications-switch-wrapper {
    display: inline-block;
    margin-top: rem(21);
    vertical-align: top;

    .o-switch {
      margin-right: rem(26);
    }

    .p-inputswitch {
      vertical-align: middle;
    }
  }

  &__notifications-submit {
    margin-top: rem(20);
  }

  /* Users and permissions section */
  &__permissions {
    padding-right: rem(14);

    .o-radiobutton {
      margin-right: rem(40);
    }

    .o-radiobutton .p-radiobutton-label {
      margin-left: rem(10);
      font-family: $ns-font-default;
      font-weight: bold;
    }
  }

  &__permissions-row {
    margin-bottom: rem(25);

    .o-btn.o-btn--small {
      display: block;
      width: 100%;
    }

    &--mb-small {
      margin-bottom: rem(15);
    }
  }

  &__permissions-radio-wrapper {
    color: color('black');

    .o-radio {
      display: inline-block;
      width: auto;
      margin-right: rem(40);
    }
  }

  &__permissions-radio-title {
    margin-bottom: rem(10);
  }

  &__permissions-col {
    display: inline-block;
    width: auto;
    margin-right: rem(35);
  }

  /* Privacy section */
  &__privacy {
    font-family: $ns-font-default;
    color: color('black');
  }

  &__privacy-row {
    margin-bottom: rem(43);

    &--mb-medium {
      margin-bottom: rem(29);
    }
  }

  &__privacy-text {
    font-size: rem(16);
    font-family: $ns-font-default;
    margin-bottom: rem(21);
    line-height: rem(20);
    color: color('black');

    &--italic {
      font-style: italic;
    }
  }

  &__privacy-check-wrapper {
    .o-check {
      display: inline-block;
    }
  }

  &__privacy-radio-wrapper {
    .p-radiobutton-label {
      margin-left: rem(10);
      font-family: $ns-font-default;
      font-weight: bold;
    }
  }
}

/* Mobile component */
.obe-vertical-tabs-resp {
  display: block;

  /* Accordion */
  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        padding: rem(16) rem(19);
        border-color: color('terciary');
        background: color('terciary');

        .p-accordion-toggle-icon {
          position: absolute;
          right: rem(15);
          margin-right: 0;
          color: color('white');
        }

        .p-accordion-header-text {
          font-family: $ns-font-default;
          font-weight: bold;
          color: color('white');
          font-family: obviously, sans-serif;
          font-weight: 700;
          font-style: normal;
        }

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-accordion-header-link {
          background: color('terciary');
          border-color: color('terciary');
          color: color('white');

          .p-accordion-header-text,
          .p-accordion-toggle-icon {
            color: color('secondary');
          }
        }
      }

      &:not(.p-disabled).p-highlight {
        .p-accordion-header-link {
          background: color('terciary');
          border-color: color('terciary');
          color: color('white');

          .p-accordion-toggle-icon {
            color: color('secondary');
          }

          .p-accordion-header-text {
            color: color('secondary');
          }
        }
      }

      &:not(.p-disabled) .p-accordion-header-link:focus {
        box-shadow: none;
      }
    }
    p-accordiontab {
      &:first-child {
        .p-accordion-header {
          .p-accordion-header-link {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }

      &:last-child {
        .p-accordion-header:not(.p-highlight) {
          .p-accordion-header-link {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    /* Accordion content */
    .p-accordion-content {
      padding: rem(18) rem(20);
    }
  }
}

@include media-query(sm) {
  /* Desktop component */
  .obe-vertical-tabs {
    display: flex;
    flex-direction: row;
    min-height: rem(345);
    background-color: color("white");

    /* Tabs */
    &__tabs {
      display: inline-block;
      width: rem(300);
      background-color: color("terciary");
      width: rem(316);
    }

    &__tabs-list {
      padding-top: rem(13);
    }

    &__tabs-item {
      border-width: 0 0 rem(1) 0;
      border-style: solid;
      font-family: "Helvetica-Neue-75";
      border-color: color("terciary");

      button {
        width: 100%;
        padding: rem(15) rem(20);
        text-align: left;
        cursor: pointer;
        color: color("white");
        font-family: obviously, sans-serif;
        font-weight: 700;
        font-style: normal;
      }

      &--active {
        button {
          background-color: color("terciary");
          color: color("secondary");
        }
      }
    }

    /* Content */
    &__content {
      display: inline-block;
      padding: rem(30) rem(40);
      border-left: rem(1) solid color("grey-4");
      width: calc(100% - 316px);
      position: relative;
    }

    /* Account section */
    .displayFlex {
      display: flex;
      flex-wrap: wrap;

      p-divider {
        width: 100%;
      }
    }

    .widthAuto {
      width: auto;
    }

    .widthCol {
      width: rem(195);
    }

    .width100 {
      width: 100%;
    }

    &__accountinfo-section {
      p-divider {
        display: none;
      }
    }

    &__accountinfo-info {
      .displayFlex {
        margin-bottom: rem(22);
      }
    }

    &__accountinfo-info-col {
      .o-btn.o-btn--link-italic.alignRight {
        display: inline-block;
        position: absolute;
        right: 0px;
        padding: rem(10);
        margin-right: rem(40);
        color: color('terciary');
        font-style: normal;
      }
    }

    &__accountinfo-section {
      padding-top: rem(40);

      &:first-child {
        padding-top: 0;
        padding-bottom: rem(30);
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &__accountinfo-title-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: rem(4);
    }

    &__accountinfo-title {
      font-family: "Helvetica-Neue-75";
      font-size: rem(20);
      line-height: rem(22);
      width: 100%;
    }

    &__accountinfo-number {
      font-family: "Helvetica-Neue-75";
      font-size: rem(16);
      margin-bottom: rem(14);
      color: color("primary");
    }

    &__accountinfo-info-row {
      display: flex;
      flex-direction: row;

      &--none {
        display: none;
      }

      &:nth-child(2) {
        margin-bottom: rem(28);
      }

      &.width100 {
        margin-bottom: 0;
      }

      &.displayFlex {
        margin-bottom: rem(20);
      }
    }

    &__accountinfo-info-col {
      margin-bottom: rem(5);

      &:first-child {
        font-family: "Helvetica-Neue-75";
      }

      span {
        line-height: rem(22);
      }
    }

    /* Subscriptions section */
    &__subscriptions-section {
      padding-top: rem(40);

      &:first-child {
        padding-top: 0;
        padding-bottom: rem(30);
      }

      &:last-child {
        border-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }

      & > .o-btn.o-btn--link-italic {
        display: none;
      }

      .o-btn.o-btn--link-italic {
        color: color('terciary');
        font-style: normal;
      }
    }

    &__subscriptions-title-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: rem(4);

      .o-btn.o-btn--link-italic {
        display: inline-block;
      }
    }

    &__subscriptions-title {
      font-family: "Helvetica-Neue-75";
      font-size: rem(20);
      line-height: rem(22);
      width: 100%;
    }

    &__subscriptions-number {
      font-family: "Helvetica-Neue-75";
      font-size: rem(16);
      margin-bottom: rem(14);
      color: color("primary");
    }

    &__subscriptions-info {
      margin-bottom: 0;
    }

    &__subscriptions-info-row {
      display: flex;
      flex-direction: row;
    }

    &__subscriptions-info-col {
      margin-bottom: rem(5);
      font-size: rem(16);

      &:first-child {
        font-family: "Helvetica-Neue-75";
        width: rem(195);
      }

      span {
        line-height: rem(22);
      }
    }

    /* Contact preferences section */
    &__contact {
      .o-checkbox {
        margin-bottom: 0;
      }
    }

    &__contact-block {
      margin-bottom: rem(42);
      border-bottom: rem(1) solid color('grey-4');

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: rem(41);
      }

      &--no-border {
        border-bottom: none;
      }
    }

    &__contact-text {
      font-family: $ns-font-default;
      font-size: rem(16);
      line-height: rem(20);
      margin-bottom: rem(18);
      color: color('black');

      &--title {
        font-family: $ns-font-default;
        font-weight: bold;
        margin-bottom: 0;
        color: color('primary');
      }

      &--mb-medium {
        margin-bottom: rem(33);
      }

      &--mb-small {
        margin-bottom: rem(28);
      }

      strong {
        font-family: $ns-font-default;
        font-weight: bold;
      }
    }

    &__contact-radio-wrapper {
      margin-bottom: rem(41);

      .o-dropdown {
        .p-dropdown {
          margin-top: 6px;
      margin-left: 30px;
        }
      }
    }

    &__contact-check-wrapper {
      margin-bottom: rem(11);

      & > .o-check__wrapper {
        margin-bottom: rem(0);
      }

      .p-checkbox-label {
        font-size: rem(16);
      }

      &--mb-medium {
        margin-bottom: rem(24);
      }

      &--mb-big {
        margin-bottom: rem(40);
      }

      &--mb-xmedium {
        margin-bottom: rem(30);
      }

      &--inline {
        padding-left: 0;

        .o-checkbox {
          display: inline-block;
          width: auto;
          margin-right: rem(30);
          margin-bottom: rem(11);
        }
      }
    }

    &__contact-check-sub {
      padding-top: rem(11);
      padding-left: rem(41);

      .o-checkbox {
        display: inline-block;
        width: auto;
        margin-right: rem(30);
      }
    }

    /* Notifications section */
    &__notifications-switch {
      margin-bottom: rem(4);
    }

    &__notifications-switch-title {
      display: inline-block;
      width: 64%;
      margin-top: rem(21);
      vertical-align: top;
    }

    &__notifications-switch-label {
      font-family: $ns-font-default;
      font-weight: bold;
      line-height: rem(19);
    }

    &__notifications-switch-sub {
      font-size: rem(14);
      font-family: $ns-font-default;
      font-weight: bold;
      vertical-align: top;
      color: color('primary');
    }

    &__notifications-switch-wrapper {
      display: inline-block;
      width: 36%;
      margin-top: rem(21);
      vertical-align: top;

      .p-inputswitch {
        vertical-align: middle;
      }
    }

    &__notifications-submit {
      margin-top: rem(20);
    }

    /* Users and permissions section */
    &__permissions {
      padding-right: rem(14);
    }

    &__permissions-row {
      margin-bottom: rem(25);

      .o-btn.o-btn--small {
        width: auto;
      }

      &--mb-small {
        margin-bottom: rem(15);
      }
    }

    &__permissions-radio-wrapper {
      .o-radio {
        display: inline-block;
        width: auto;
        margin-right: rem(40);
      }
    }

    &__permissions-radio-title {
      margin-bottom: rem(10);
    }

    &__permissions-col {
      display: inline-block;
      width: auto;
      margin-right: rem(35);
    }

    /* Privacy section */

    &__privacy-row {
      margin-bottom: rem(43);

      &--mb-medium {
        margin-bottom: rem(29);
      }
    }

    &__privacy-text {
      font-size: rem(16);
      font-family: $ns-font-default;
      margin-bottom: rem(21);
      line-height: rem(20);
      color: color('black');

      &--italic {
        font-style: italic;
      }
    }

    &__privacy-check-wrapper {
      .o-checkbox {
        margin-bottom: rem(11);
      }
    }

    &__privacy-radio-wrapper {
      .o-radio {
        display: inline-block;
        width: auto;
        margin-right: rem(40);
      }
    }
  }

  /* Mobile component */
  .obe-vertical-tabs-resp {
    display: none;
  }
}

@include media-query(lg) {
  /* Desktop component */
  .obe-vertical-tabs {

    /* Subscriptions section */
    &__subscriptions-title {
      width: auto;
    }

    /* Contact preferences section */
    &__contact {
      .o-checkbox {
        margin-bottom: 0;
      }
    }

    &__contact-block {
      margin-bottom: rem(42);
      border-bottom: rem(1) solid color('grey-4');

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      &--no-border {
        border-bottom: none;
      }
    }

    &__contact-text {
      font-family: $ns-font-default;
      font-size: rem(16);
      line-height: rem(20);
      margin-bottom: rem(18);
      color: color('black');

      &--title {
        font-family: $ns-font-default;
        font-weight: bold;
        margin-bottom: 0;
        color: color('primary');
      }

      &--mb-medium {
        margin-bottom: rem(33);
      }

      &--mb-small {
        margin-bottom: rem(28);
      }

      strong {
        font-family: $ns-font-default;
        font-weight: bold;
      }
    }

    &__contact-radio-wrapper {
      margin-bottom: rem(33);

      .o-radiobutton {
        display: block;
        margin-bottom: rem(12);
      }

      .p-radiobutton-label {
        font-family: $ns-font-default;
        font-weight: bold;
        font-size: rem(16);
        margin-left: rem(6);
      }

      .o-dropdown {
        .p-dropdown {
          margin-top: 6px;
          margin-left: 30px;
        }
      }
    }

    &__contact-check-wrapper {
      margin-bottom: rem(6);

      .o-check__wrapper {
        display: inline-block;
      }

      &--mb-medium {
        margin-bottom: rem(19);
      }

      &--mb-big {
        margin-bottom: rem(36);
      }

      &--mb-xmedium {
        margin-bottom: rem(30);
      }

      &--inline {

        .o-checkbox {
          display: inline-block;
          width: auto;
          margin-right: rem(30);
          margin-bottom: rem(11);
        }
      }
    }

    &__contact-check-sub {
      padding-top: rem(6);
      padding-left: rem(41);

      .o-check__wrapper {
        margin-bottom: rem(7);
      }

      .o-checkbox {
        display: inline-block;
        width: auto;
        margin-right: rem(30);
      }
    }

    /* Notifications section */
    &__notifications-switch {
      margin-bottom: rem(4);
    }

    &__notifications-switch-title {
      display: inline-block;
      width: 64%;
      margin-top: rem(21);
      vertical-align: top;
    }

    &__notifications-switch-label {
      font-family: $ns-font-default;
      font-weight: bold;
      line-height: rem(19);
    }

    &__notifications-switch-sub {
      font-size: rem(14);
      font-family: $ns-font-default;
      font-weight: bold;
      vertical-align: top;
      color: color('primary');
    }

    &__notifications-switch-wrapper {
      display: inline-block;
      width: 36%;
      margin-top: rem(21);
      vertical-align: top;

      .p-inputswitch {
        vertical-align: middle;
      }
    }

    &__notifications-submit {
      margin-top: rem(20);
    }

    /* Users and permissions section */
    &__permissions {
      padding-right: rem(14);
    }

    &__permissions-row {
      margin-bottom: rem(25);

      &--mb-small {
        margin-bottom: rem(15);
      }
    }

    &__permissions-radio-wrapper {
      .o-radio {
        display: inline-block;
        width: auto;
        margin-right: rem(40);
      }
    }

    &__permissions-radio-title {
      margin-bottom: rem(10);
    }

    &__permissions-col {
      display: inline-block;
      width: auto;
      margin-right: rem(35);
    }

    /* Privacy section */

    &__privacy-row {
      margin-bottom: rem(43);

      &--mb-medium {
        margin-bottom: rem(29);
      }
    }

    &__privacy-text {
      font-size: rem(16);
      font-family: $ns-font-default;
      margin-bottom: rem(21);
      line-height: rem(20);
      color: color('black');

      &--italic {
        font-style: italic;
      }
    }

    &__privacy-check-wrapper {
      .o-checkbox {
        margin-bottom: rem(11);
      }
    }

    &__privacy-radio-wrapper {
      .o-radio {
        display: inline-block;
        width: auto;
        margin-right: rem(40);
      }
    }
  }

  /* Mobile component */
  .obe-vertical-tabs-resp {
    display: none;
  }
}
