////
/// CSS Reset
///
/// You do not need no to care about this, 
/// only for compatibility browsers.
///
////
html,
body,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary,
audio,
canvas,
video,
mark,
code,
kbd,
pre,
samp,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
strong,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
div {
	margin: 0;
	padding: 0;
	border: none;
	font-weight: normal;
	font-style: normal;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
	text-decoration: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

input,
button,
select,
textarea {
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-style: normal;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
	text-decoration: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

ul,
ol,
dl {
	list-style: none;
}

/*-----------------------------------
	#TAGS HTML5 BROWSER NO SUPPORT
-------------------------------------*/


article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

dfn {
	font-style: italic;
}

mark {
	background-color: #ff0;
	color: #000;
}





/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}





code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 1rem;
}





/**
 * Add the correct font style in Android 4.3-.
 */

dfn {
  font-style: italic;
}






/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}





/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}




/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}






/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}






/*-----------------------------------
	#LINKS
-------------------------------------*/
a {
	cursor: pointer;
	background-color: color('black',0);
    text-decoration-skip: objects;
	-webkit-text-decoration-skip: objects; 
}

a:focus,
a:hover {
	text-decoration:underline;
}

a:visited,
a:active {
	text-decoration: none;
}
a:active,
a:hover {
  outline-width: 0;
}






/*-----------------------------------
	#HEADINGS, WITH AND WITHOUT LINK
-------------------------------------*/

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}






/*-----------------------------------
	#RESET TABLE
-------------------------------------*/

table {
	border-collapse: collapse;
	border-spacing: 0;
}




/*-----------------------------------
	#RESET FORM
-------------------------------------*/

/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 * 3. Remove shading to form elements 
 */

button,
input,
optgroup,
select,
textarea {
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  // -ms-appearance:none; /* 3 */
  // -moz-appearance: none; /* 3 Firefox */
  // -webkit-appearance: none; /* 3 Safari and Chrome */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}


[type="radio"],
[type="checkbox"]{
  + label{
    cursor: pointer;
  }
}



/*-----------------------------------
	#RESET INTERACTIVE
-------------------------------------*/

/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */

details, /* 1 */
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}





/*-----------------------------------
	#RESET SCRIPTING
-------------------------------------*/

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}




/*-----------------------------------
	#RESET HIDDEN
-------------------------------------*/


/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}


/*--------------------------------
	# END GENERIC RESET
--------------------------------*/
