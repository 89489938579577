////
/// NEW SIM component
///
/// Custom project New sim
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}new-sim
/// @since version 1.0.0
////

.#{$ns}new-sim {
  background-color: color('white');
  padding: rem(38) rem(29);
  font-family: $ns-font-default;

  /* Header */
  &__header {
    padding-bottom: rem(33);
  }

  &__header-number {
    font-size: rem(14);
    font-family: $ns-font-default;
    font-weight: bold;
  }

  &__header-title {
    font-size: rem(30);
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  /* Main */
  &__main-form-title-wrapper {
    margin-bottom: rem(14);
  }

  &__main-form-title {
    font-size: rem(20);
    font-family: $ns-font-default;
    font-weight: bold;
    margin-right: rem(29);
  }

  &__main-form-subtitle {
    font-size: rem(14);
    font-style: italic;
    color: color('grey-3');
  }

  &__main-form-row {
    padding-bottom: rem(16);
    margin-bottom: rem(15);

    .p-error {
      display: block;
      font-size: rem(14);
      color: color('error');
      position: absolute;
      bottom: rem(-20);
      white-space: nowrap;
    }

    &--mb-large {
      margin-bottom: rem(55);
    }
  }

  &__main-form-inputnumber {
    display: inline-block;
    margin-right: rem(30);
    position: relative;

    label {
      font-size: rem(14);
      display: block;
      margin-bottom: rem(6);
    }
  }

  &__main-form-dropdown {
    display: inline-block;
    margin-right: rem(30);

    label {
      font-size: rem(14);
      display: block;
      margin-bottom: rem(6);
    }

    .o-dropdown {
      .p-dropdown-label {
        padding-left: rem(20);
      }
    }
  }

  &__main-form-inputtext {
    display: inline-block;
    margin-right: rem(30);
    position: relative;

    label {
      font-size: rem(14);
      display: block;
      margin-bottom: rem(6);
    }

    .o-inputtext__input {
      color: color('black');
    }
  }

  &__main-label {
    display: inline-block;
    font-size: rem(14);

    &--bold {
      font-family: $ns-font-default;
      font-weight: bold;
    }
  }

  .o-btn {
    width: 100%;
  }
}

@include media-query(sm) {
  .#{$ns}new-sim {
    .o-btn {
      width: auto;
    }
  }
}
