////
/// SPINNER component
///
/// Custom project Spinner
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}review
/// @since version 1.0.0
////

.#{$ns}spinner {
  .p-progress-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.obe-spinner {
  .p-progress-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
