@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fmpn7g');
  src:  url('fonts/icomoon.eot?fmpn7g#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fmpn7g') format('truetype'),
    url('fonts/icomoon.woff?fmpn7g') format('woff'),
    url('fonts/icomoon.svg?fmpn7g#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-light-down:before {
  content: "\e90b";
}
.icon-error:before {
  content: "\e907";
}
.icon-info:before {
  content: "\e908";
}
.icon-warning .path1:before {
  content: "\e909";
  color: rgb(255, 204, 0);
}
.icon-warning .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-chevron-light-left:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e905";
}
.icon-chevron-light-right:before {
  content: "\e904";
}
.icon-file:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-icon-close:before {
  content: "\e902";
}
.icon-success:before {
  content: "\e900";
  color: #32c832;
}
