////
/// DIALOG component
///
/// Custom project dialog
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}dialog
/// @since version 1.0.0
////

.#{$ns}dialog {

  &__txt {
    font-family: $ns-font-default;
    margin-bottom: rem(10);
    font-size: rem(16);
    color: color('quaternary');

    &--bold {
      font-family: $ns-font-default;
      font-weight: bold;
      color: color('black');
    }

    &--mb {
      margin-bottom: rem(28);
    }

    &--center {
      text-align: center;
    }
  }

  &__inputtext {
    width: 100%;
    max-width: none;

    &--big {
      font-size: rem(24);
    }

    &--center {
      text-align: center;
    }
  }

  .p-dialog {
    height: 100%;
    max-height: none;
    border-radius: 0;
    font-family: $ns-font-default;
    background-color: color('white');

    .p-dialog-mask.p-component-overlay {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .p-dialog-header {
      padding: rem(22) rem(27) rem(22) rem(20);
      font-family: obviously, sans-serif;
      font-weight: 700;
      font-style: normal;
      color: color('terciary');
      font-size: rem(20);
      border-radius: 0;

      .p-dialog-title {
        color: color('terciary');
        font-size: rem(20);
        font-family: obviously, sans-serif;
        font-weight: 700;
        font-style: normal;
      }

      .p-dialog-header-icon {
        width: auto;
        height: auto;
        color: color('quaternary');
        font-size: rem(14);

        &:enabled:hover {
          background-color: transparent;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
        }
      }
    }

    .p-dialog-content {
      padding: 0 rem(26) rem(26);
      color: color('black');
      flex-grow: 0;
    }

    .p-dialog-footer {
      border-radius: 0;
      text-align: left;
      padding: 0 rem(20) rem(20);
      border-radius: 0 0 rem(5) rem(5);

      .o-btn--block {
        width: 100%;
      }
    }
  }

  .card {
    border: 0;
  }

  .p-field-radiobutton {
    margin-bottom: rem(20);
    display: flex;
    align-items: center;

    & > label {
      margin-left: rem(8);
    }
  }

  &__content {
    &.modal-content {
      border: 0;
    }
  }

  &__input-grid-row {
    display: block;
    padding: rem(15) 0;

    &--between {
      display: flex;
      justify-content: space-between;
    }

    &--pr {
      padding-right: rem(60);
    }
  }

  &__inputnumber {
    .p-inputnumber {
      width: rem(300);
    }
  }

  &__input-label {
    font-family: $ns-font-default;
    font-weight: bold;
  }

  &__icon {
    font-size: rem(60);

    &--error {
      color: color('error');
      display: block;
      text-align: center;
    }
  }

  p-button {
    &[disabled="true"] {
      cursor: default;
    }
  }

  &--small {
    .p-dialog {
      width: 100%;
    }
  }

  &--medium {
    .p-dialog {
      width: 100%;
    }
  }

  &--no-footer {
    .p-dialog-content {
      border-radius: 0 0 rem(5) rem(5);
    }
  }
}

@include media-query(sm) {
  .#{$ns}dialog {
    .p-dialog {
      height: auto;
      max-height: 90%;
      background-color: transparent;
    }

    &--small {
      .p-dialog {
        width: rem(375);
      }
    }

    &--medium {
      .p-dialog {
        width: rem(750);
      }
    }
  }
}

/*
  My profile dialog
*/
.iris {
  .obe-dialog {
    .p-dialog {
      max-height: 100%;
      width: 100vw;
      height: 100%;

      .p-dialog-mask.p-component-overlay {
        background-color: rgba(0, 0, 0, 0.5);
      }

      .p-dialog-header {
        padding: rem(22) rem(27) rem(22) rem(20);
        font-family: obviously, sans-serif;
        font-weight: 700;
        font-style: normal;
        color: color('terciary');
        font-size: rem(20);
        border-radius: 0;

        .p-dialog-header-icon {
          width: auto;
          height: auto;

          &:enabled:hover {
            background-color: transparent;
          }
        }
      }

      .p-dialog-content {
        padding: rem(22) rem(20) rem(18);
        flex-grow: 1;
      }

      .p-dialog-footer {
        border-radius: 0;
        text-align: left;
        padding: 0 rem(20) rem(20);

        .o-btn--block {
          width: 100%;
        }
      }

      .o-inputtext {
        &__label {
          font-family: $ns-font-default;
          font-weight: bold;
          color: color('black');
          font-size: rem(18);
          width: 100%;
          margin-bottom: rem(3);
          display: inline-block;
        }

        &__input {
          width: 100%;
          max-width: none;
          height: rem(50);
          border-radius: 0;
          border: rem(2) solid color(grey-1);
          margin-bottom: rem(3);
          padding: rem(5) rem(13);
          font-family: $ns-font-default;
          font-weight: bold;
          font-size: 1rem;
          color: color('black');

          &:enabled:focus {
            border-color: color('black');
          }
        }
      }

      .o-radiobutton {
        .p-radiobutton-label {
          font-family: $ns-font-default;
        }
      }

      .o-dropdown {
        .p-dropdown {
          height: rem(50);
          align-items: center;
          border: rem(2) solid color('grey-1');
        }

        .p-dropdown-label {
          font-family: $ns-font-default;
          font-weight: bold;
        }
      }

      .o-inputmask {
        &__input {
          width: 100%;
          height: rem(50);
          border-radius: 0;
          border: rem(2) solid color('grey-1');

          &:enabled:focus {
            border-color: color('black');
            box-shadow: none;
          }
        }
      }

      .alert {
        .o-inputtext__icon {
          margin-right: rem(3);
          vertical-align: middle;
        }

        .o-inputtext__error {
          display: inline-block;
        }

        &.alert-danger {
          background-color: transparent;
          padding: 0;
          border: 0;
        }
      }

      .obe-dialog__sub-input {
        .o-inputtext {
          &__label {
            font-size: rem(16);
          }
        }
      }
    }

    &__content {
      &--sep-big {
        padding: rem(18) rem(45) rem(35);
      }
    }

    &__footer {
      margin-top: rem(25);
    }

    &__label {
      font-family: $ns-font-default;
      font-weight: bold;
      display: block;
      margin-bottom: rem(3);
      font-size: rem(18);
    }

    &__slider-container {
      margin: rem(15) 0;
    }

    &__slider-value {
      font-family: $ns-font-default;
      font-weight: bold;
      font-size: rem(19);
      margin-bottom: rem(9);
      color: color('quaternary');
    }

    &__sub-input {
      margin: rem(-8) 0 rem(18) rem(16);
      width: calc(100% - 16px);

      .o-inputtext__label {
        font-size: rem(16);
      }

      .o-inputtext__input {
        font-family: $ns-font-default;
      }
    }

    &__icon-success {
      font-size: rem(80);
      text-align: center;
      display: block;
      margin-bottom: rem(14);
    }

    &__txt-success {
      font-family: $ns-font-default;
      font-weight: bold;
      font-size: rem(24);
      text-align: center;
    }

    &__txt-success-small {
      font-family: $ns-font-default;
      font-size: rem(18);
      text-align: center;
      line-height: rem(22);
    }

    &__mb-medium {
      margin-bottom: rem(24);
    }

    &--two-columns {
      .o-dropdown {
        .p-dropdown {
          width: 100%;
        }
      }

      .obe-dialog {
        &__row {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          // margin-bottom: rem(16);
        }
        &__col {
          width: 100%;
          margin-bottom: rem(16);

          .o-inputtext.p-field {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include media-query(sm) {
    .obe-dialog {
      .p-dialog {
        height: auto;
        max-height: 90%;

        .p-dialog-mask.p-component-overlay {
          background-color: rgba(0, 0, 0, 0.5);
        }

        .p-dialog-header {
          padding: rem(22) rem(27) rem(22) rem(20);
          font-family: obviously, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: rem(20);
          border-radius: 0;

          .p-dialog-header-icon {
            width: auto;
            height: auto;

            &:enabled:hover {
              background-color: transparent;
            }
          }
        }

        .p-dialog-content {
          padding: rem(22) rem(20) rem(18);
          flex-grow: 0;
        }

        .p-dialog-footer {
          border-radius: 0;
          text-align: left;
          padding: 0 rem(20) rem(20);

          .o-btn--block {
            width: 100%;
          }
        }
      }

      &__content {
        &--sep-big {
          padding: rem(18) rem(25) rem(35);
        }
      }

      &__label {
        font-family: $ns-font-default;
        font-weight: bold;
        display: block;
        margin-bottom: rem(3);
        font-size: rem(18);
      }

      &__slider-container {
        margin: rem(15) 0;
      }

      &__slider-value {
        font-family: $ns-font-default;
        font-weight: bold;
        font-size: rem(19);
        margin-bottom: rem(9);
      }

      &--small {
        .p-dialog {
          width: rem(375);
        }
      }

      &--medium {
        .p-dialog {
          width: rem(750);
        }
      }

      &--two-columns {
        .o-dropdown {
          .p-dropdown {
            width: 100%;
          }
        }

        .obe-dialog {
          &__row {
            width: 100%;
            display: flex;
            margin-bottom: rem(4);
          }
          &__col {
            width: calc(50% - 15px);

            &:nth-child(odd) {
              margin-right: rem(15);
            }

            &:nth-child(even) {
              margin-left: rem(15);
            }
          }
        }

        .p-dialog {
          .p-dialog-content {
            padding-top: rem(27);
          }
        }
      }
    }
  }
}
