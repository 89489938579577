/*-------------------------------------------------------------
    #check
-----------------------------------------------------------------*/

.o-check {
  margin-right: rem(6);

  .p-checkbox-box {
    border-radius: 0;
    border: rem(2) solid color('grey-1');
    width: rem(20);
    height: rem(20);

    &.p-highlight {
      background-color: color('terciary');
      border-color: color('terciary');
    }

    .p-checkbox-icon {
      color: color('white');
    }
  }

  &.p-checkbox {
    width: rem(20);
    height: rem(20);

    &:not(.p-checkbox-disabled) .p-checkbox-box {
      &.p-highlight:hover {
        border-color: color('terciary');
        background: color('terciary');
      }

      &.p-focus {
        border-color: color('terciary');
        box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
      }

      &:hover {
        border-color: color('terciary');
      }
    }
  }

  &__label {
    a {
      color: color('black');
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &--inline {
      display: inline;
    }
  }
}
