////
/// TABLES component
///
/// Custom project Steps component
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}tables
/// @since version 1.0.0
////

.#{$ns}tables {
  min-width: rem(940);

    .p-datatable {
        font-family: $ns-font-default;
        background-color: color('white');
        padding: rem(8) rem(31) rem(31);

        th,
        td {
          width: auto;
          font-size: rem(14);
        }

        .p-datatable-wrapper{
            margin: 1rem 1rem;

            .p-datatable-thead {
                border-bottom: rem(1) solid color('black');
                tr > th {
                    color: color('black');
                    padding: rem(12) rem(16);
                    border: none;
                    background: color('white');
                    padding-left: 0;

                    &:nth-child(1),
                    &:nth-child(3) {
                      display: none;
                    }
                }
            }
            .p-datatable-tbody{
                > tr {
                    border-bottom: rem(1) solid color('grey-4');

                    > td {
                        color: color('black');
                        border: none;
                        color: color('quaternary');
                        padding: rem(12) rem(16);
                        padding-left: 0;

                        &:nth-child(1),
                        &:nth-child(3) {
                          display: none;
                        }

                        .p-button-label {
                          display: none;
                        }

                        &.error{
                            color: color('unpaid');
                            font-family: $ns-font-default;
                            font-weight: bold;
                        }
                        &.success{
                          color: color('success');
                        }
                    }
                }
            }
        }
        .p-paginator-bottom{
          border-bottom: 0;
          justify-content: flex-end;

            .p-paginator-first, .p-paginator-last{
                display: none;
            }

            .p-paginator-prev, .p-paginator-next{
                border-radius: 0px;
                // border: 1px solid color('black');
                color: color('quaternary');
                &:hover, &:focus{
                  color: color('black');
                  background: transparent;
                  box-shadow: none;
                  outline: none;
                  border: none;
                }
            }
            .p-paginator-page{
                color: color('black');
                border-radius: 0px;
                min-width: rem(30);
                height: rem(30);

                &:hover{
                    background: transparent;
                    color: color('black');
                }

                &.p-highlight{
                    background: color('quaternary');
                    color: color('white');
                }

                &:focus {
                  box-shadow: 0 0 0 0.2rem color('primary', 0.4);
                }
            }
        }

        .p-datatable-thead {
          & > tr {
            & > th {
              color: color('black');
              background-color: color('white');
              padding-bottom: rem(7);
              border-color: color('black');
              font-family: $ns-font-default;
              font-weight: bold;
            }
          }
        }
    }

    .p-button{
        color: color('quaternary');
        background: color('white');
        border: none;
        font-family: $ns-font-default;
        padding: 0;
        line-height: rem(24);
        border-radius: 0;

        &:hover{
            color: color('quaternary');
            background: color('white');
            border: none;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem color('primary', 0.4);
        }

        .p-button-icon-right{
            margin-left: 0.25rem;
            font-size: rem(11);

            &::before{
                vertical-align: sub;
            }
        }
    }
}

@include media-query(md) {
  .#{$ns}tables {
    .p-datatable {
      .p-datatable-wrapper {
        .p-datatable-thead {
          & > tr {
            & > th {
              &:nth-child(1),
              &:nth-child(3) {
                display: table-cell;
              }
            }
          }
        }

        .p-datatable-tbody {
          > tr {
            > td {
              &:nth-child(1),
              &:nth-child(3) {
                display: table-cell;
              }
              &:last-child {
                text-align: right;
              }

              .p-button-label {
                display: block;
              }
            }
          }
        }
      }

      th,
      td {
        width: rem(140);
        font-size: rem(16);
      }
    }
  }
}
