////
/// CONSUMPTION component
///
/// Custom project consumption
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}consumption
/// @since version 1.0.0
////

.#{$ns}consumption {
  background-color: color('white');
  padding: rem(25) rem(26);
  font-family: $ns-font-default;
  position: relative;

  /* Header */
  &__header {
    margin-bottom: rem(41);
  }

  &__header-block {
    &--flex {
      display: flex;
      align-items: flex-end;
    }
  }

  &__header-tariff {
    display: block;
    font-size: rem(24);
    color: color('terciary');
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    line-height: rem(32);
    margin-bottom: rem(16);
  }

  &__header-number {
    font-size: rem(14);
    font-family: $ns-font-default;
    line-height: rem(22);
    color: color('quaternary');
  }

  &__header-tariff-info {
    font-size: rem(16);
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
    padding-right: rem(22);
    line-height: rem(20);

    span {
      font-weight: bold;
    }
  }

  /* Main */
  &__main {
    margin-bottom: rem(64);
  }

  &__main-title {
    font-size: rem(20);
    font-family: $ns-font-default;
    font-weight: bold;
    display: block;
    margin-bottom: rem(19);
  }

  &__main-col {
    width: 100%;
    margin-bottom: rem(28);
  }

  &__main-block {
    display: flex;

    &:not(:last-child) {
      margin-bottom: rem(29);
    }
  }

  &__main-icon {
    display: inline-block;
    background-color: color('secondary');
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    margin-right: rem(18);
  }

  &__main-data {
    width: calc(100% - 58px);
    display: inline-block;
  }

  &__main-data-title {
    font-family: $ns-font-default;
    font-weight: bold;
    margin-bottom: rem(5);
    display: block;
  }

  &__main-data-details {
    display: flex;
    justify-content: space-between;
  }

  &__main-data-usage {
    strong {
      font-weight: normal;
      font-family: $ns-font-default;
      font-weight: bold;
    }
  }

  &__main-data-progressbar {
    margin-bottom: rem(2);
  }

  &__main-data-update {
    font-size: rem(14);
    color: color('grey-3');
  }

  /* Footer */
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer-item {
    display: inline-block;
    margin-right: rem(50);
  }

  &__footer-link {
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
    line-height: rem(32);

    span {
      &:first-child {
        font-weight: bold;
      }
    }

    &:hover {
      color: color('quaternary');
    }
  }

  &__footer-link-icon {
    font-size: rem(12);
    margin-left: rem(13);
  }

  &__footer-back-link {
    align-self: flex-end;
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
    display: none;

    &:hover {
      text-decoration: none;
      color: color('quaternary');

      .#{$ns}consumption {
        &__footer-back-text {
          text-decoration: underline;
        }
      }
    }
  }

  &__footer-back-icon {
    font-size: rem(12);
    margin-right: rem(12);
  }
}

@include media-query(md) {
  .#{$ns}consumption {
    /* Header */
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(33);
    }

    &__header-number {
      margin-bottom: rem(1);
      display: block;
    }

    &__header-tariff {
      font-size: rem(24);
    }

    &__header-tariff-info {
      font-size: rem(20);
      padding-right: 0;
      margin-bottom: rem(15);
    }

    /* Main */
    &__main-title {
      margin-bottom: rem(28);
    }

    &__main-content {
      display: flex;
      flex-wrap: wrap;
    }

    &__main-col {
      width: calc(50% - 40px);
      margin-bottom: 0;

      &:nth-child(odd) {
        margin-right: rem(20);
      }

      &:nth-child(even) {
        margin-left: rem(20);
      }
    }

    &__main-block {
      &:not(:last-child) {
        margin-bottom: rem(24);
      }
    }

    /* Footer */
    &__footer-link {
      line-height: rem(41);
    }

    &__footer-back-link {
      display: block;
    }
  }
}
