/*-------------------------------------------------------------
	#INPUT TEXT
-----------------------------------------------------------------*/

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
}

.o-inputtext {
  &__input {
    width: 100%;
    max-width: rem(260);
    height: rem(42);
    border-radius: 0;
    border-color: color('quaternary');
    font-family: $ns-font-default;
    color: color('quaternary');
    padding: rem(8);
    outline: 0;

    &.ng-dirty.ng-invalid {
      border-color: color('error');
    }

    &.p-inputtext {
      border-width: rem(2);
      border-radius: 0;

      &:enabled:focus {
        border-color: color('terciary');
        box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
      }

      &:hover:not([disabled]) {
        border-color: color('terciary');
      }
    }

    & + .p-error {
      color: color('error');
    }

    &::placeholder {
      color: color('quaternary');
    }
  }

  &.p-float-label {
    input {
      border-width: 0 0 rem(1);
      padding: rem(8) 0;

      &:focus ~ label,
      &.p-filled ~ label {
        font-size: rem(12);
        color: color('terciary');
        background-color: color('white');
        top: rem(2);
      }

      &.p-inputtext:enabled:focus {
        box-shadow: none;
      }

      &.ng-dirty.ng-invalid {
        border-color: color('error');
      }
    }

    > .ng-invalid.ng-dirty + label {
      color: color('error');
    }

    > label {
      top: rem(23);
      left: 0;
    }

    .p-button {
      width: rem(108);
      height: rem(40);
      border-radius: 0;
      color: color('white');
      background-color: color('primary');
      font-family: $ns-font-default;
      font-weight: bold;
      font-size: rem(16);
    }

    .p-error {
      margin-top: rem(6);
    }
  }


  > label {
    font-family: $ns-font-default;
    color: color('black');
    font-size: rem(16);
  }

  .p-error {
    display: block;
    color: color('error');
    font-size: rem(12);
  }

  &__label {
    font-size: rem(16);
    margin-right: rem(8);
  }

  &--mb-small {
    margin-bottom: rem(27);
  }

  &--rounded {
    .o-inputtext__input {
      border-radius: rem(10);
    }
  }

  &--inline {
    .o-inputtext {
      &__label {
        display: block;
      }
    }
  }
}

.p-error {
  color: color('error');
}



@include media-query(sm) {
  .o-inputtext {
    &__input {
      &--block {
        display: block;
        max-width: none;
      }
    }
  }
}
