////
/// REVIEW component
///
/// Custom project Review
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}review
/// @since version 1.0.0
////

.#{$ns}review {
  font-family: $ns-font-default;

  &__tariff {
    background-color: color('white');
    padding: rem(32) rem(25);
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    border-bottom: rem(1) solid color('black');
  }

  &__tariff-col {
    display: inline-block;
  }

  &__tariff-state {
    font-size: rem(14);
    font-family: $ns-font-default;
    color: color('quaternary');
    display: block;
  }

  &__tariff-title {
    font-size: rem(24);
    color: color('terciary');
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    display: block;
    margin-bottom: rem(8);
  }

  &__tariff-data {
    display: block;
    margin-bottom: rem(3);
  }

  &__tariff-price {
    font-size: rem(20);
    font-family: $ns-font-default;
    font-weight: bold;
    line-height: rem(34);
    margin-right: rem(23);
  }

  &__highlighted {
    display: inline-block;
    width: rem(42);
    height: rem(42);
    border-radius: 50%;
    background-color: color('pink');
    transform: rotate(21deg);
    vertical-align: middle;
  }

  &__btn {
    width: 100%;
  }

  .o-btn.o-btn--link {
    margin: 0 auto;
    display: block;
    margin-top: rem(14);
  }

  &__footer {
    padding: rem(32) rem(25);
    background-color: color('white');
  }

  .p-field-checkbox {
    margin-bottom: rem(21);
    align-items: flex-start;

    & > label {
      line-height: rem(20);
    }
  }
}

@include media-query(xs) {
  .#{$ns}review {
    &__btn {
      width: auto;
      margin-right: rem(40);
    }

    .o-btn.o-btn--link {
      margin: 0;
      display: inline;
    }
  }
}

@include media-query(sm) {
  .#{$ns}review {
    &__tariff-title {
      margin-bottom: 0;
    }

    &__tariff-data {
      font-family: $ns-font-default;
      font-weight: bold;
      vertical-align: middle;
      margin-bottom: 0;
      display: inline;

      &:not(:last-child) {
        margin-right: rem(59);
      }
    }
  }
}
