/*-------------------------------------------------------------
	#SHADOW WRAPPER
-----------------------------------------------------------------*/

.o-shadow-wrapper {
  overflow: hidden;

  &__shadow {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: color('secondary');
      z-index: -1;
    }

    &:before {
      top: 50vw;
      left: 100%;
      width: 100vw;
      height: calc(100% + 1px);
      transform: skewY(45deg);
    }

    &:after {
      top: 100%;
      left: 50vh;
      width: 100%;
      height: 100vh;
      transform: skewx(45deg);
    }
  }

  &__height-short {
    min-height: calc(100vh - 683px);
  }

  &__height-medium {
    min-height: calc(100vh - 806px);
  }

  &__height-large {
    min-height: calc(100vh - 263px);
  }

  &__height-xlarge {
    min-height: calc(100vh - 113px);
  }
}

@include media-query(sm) {
  .o-shadow-wrapper {
    &__height-short {
      min-height: calc(100vh - 628px);
    }

    &__height-medium {
      min-height: calc(100vh - 747px);
    }

    &__height-large {
      min-height: calc(100vh - 233px);
    }

    &__height-xlarge {
      min-height: calc(100vh - 113px);
    }
  }
}
