////
/// ADMIN component
///
/// Custom project Admin
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}admin
/// @since version 1.0.0
////

.#{$ns}admin {
  background-color: color('white');
  padding: rem(32);

  h2 {
    font-size: rem(18);
    color: color('black');
  }

  .o-btn {
    align-self: flex-end;
  }

  .o-inputtext__input {
    align-self: flex-end;
  }

  .o-inputtext,
  .o-dropdown .p-dropdown {
    max-width: rem(253);
  }
}
