////
/// USAGE component
///
/// Custom project Usage
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}usage
/// @since version 1.0.0
////

.#{$ns}usage {

  .p-card {
    position: relative;
    background-color: color('white');
    box-shadow: none;
    border-radius: 0;
    font-family: $ns-font-default;
    color: color('black');
    height: rem(456);

    .p-card-content {
      padding-bottom: 0;
      padding-top: rem(5);

      .p-d-flex {
        &:last-child {
          margin-top: rem(3);
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: rem(65);
      height: rem(65);
      background-color: color('primary');
      bottom: rem(-33);
      right: rem(-33);
      transform: rotate(45deg);
    }
  }

  .p-card-title {
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: rem(30);
    color: color('terciary');
  }

  .p-card-body {
    padding: rem(31) rem(29);
  }

  .p-text-bold {
    font-family: $ns-font-default;
    font-weight: bold;
  }

  .p-knob-text {
    fill: color('black');
    font-family: $ns-font-default;
    font-weight: bold;
  }

  .p-d-flex.p-flex-column {
    margin-top: rem(5);
  }

  &__link {
    color: color('quaternary');
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: color('quaternary');

      & > div {
        text-decoration: underline;
      }
    }

    & > div {
      display: inline-block;
    }
  }

  &__icon {
    font-size: rem(11);
    color: color('primary');
    margin-left: rem(12);
  }

  &__alert {
    background-color: color('secondary');
    color: color('black');
    position: absolute;
    top: rem(-18);
    left: 0;
    width: 100%;
    padding: rem(6) rem(15);
    font-size: rem(14);

    strong {
      font-family: $ns-font-default;
      font-weight: bold;
      font-weight: normal;
      margin-right: rem(4);
    }
  }

  &__alert-icon {
    vertical-align: middle;
    margin-right: rem(8);

    .path1 {
      font-size: rem(27);

      &:before {
        color: color('black');
      }
    }

    .path2 {
      font-size: rem(27);

      &:before {
        color: color('secondary');
      }
    }
  }

  &__alert-link {
    color: color('black');
    font-size: rem(14);
    text-decoration: underline;
    font-family: $ns-font-default;

    &:hover {
      color: color('black');
    }
  }
}
