/*-------------------------------------------------------------
	#PASSWORD
-----------------------------------------------------------------*/

.o-password {

  .p-inputtext {
    width: 100%;
    max-width: rem(280);
    height: rem(40);
    border-radius: 0;
    border-width: 0 0 rem(1);
    border-color: color('quaternary');
    font-family: $ns-font-default;
    padding-right: rem(36);

    &.p-filled,
    &:focus {
      ~ label {
        color: color('primary');
        background-color: color('white');
        top: rem(2);
      }
    }
  }

  label {
    color: color('quaternary');
    font-family: $ns-font-default;
  }

  &__strength {
    display: inline-block;

    &--verystrong {
      color: color('verystrong');
    }

    &--strong {
      color: color('strong');
    }

    &--medium {
      color: color('medium');
    }

    &--weak {
      color: color('weak');
    }

    &--right {
      position: absolute;
      margin-left: rem(16);
      bottom: 0;
      right: 0;

      .o-password__strength-txt {
        display: block;
        line-height: rem(14);
        margin-bottom: rem(5);
      }

      .o-password__strength-bar {
        max-width: rem(75);
        display: block;
      }
    }
  }

  &__strength-txt {
    font-size: rem(14);
    font-family: $ns-font-default;
    font-weight: bold;
    display: block;
    line-height: rem(10);
  }

  &__strength-bar {
    display: none;
  }

  &--mb-small {
    margin-bottom: rem(27);
  }

  &.p-inputtext {
    width: 100%;
    max-width: rem(280);
    height: rem(40);
    border-radius: 0;
    border-color: color('grey-1');
    font-family: $ns-font-default;

    &.p-filled,
    &:focus {
      ~ label {
        color: color('primary');
        background-color: color('white');
        top: rem(2);
      }
    }
  }
  &.p-float-label {
    input {
      border-width: 0 0 rem(1);
      padding: rem(8) 0;

      &:focus ~ label,
      &.p-filled ~ label {
        font-size: rem(12);
        color: color('terciary');
        background-color: color('white');
        top: rem(2);
      }

      &.p-inputtext:enabled:focus {
        box-shadow: none;
      }

      &.ng-invalid {
        border-color: color('error');
      }
    }

    > .ng-invalid.ng-dirty + label {
      color: color('error');
    }

    > label {
      top: rem(23);
      left: 0;
    }

    .p-inputtext {
      width: 100%;
      max-width: none;
    }

    .p-error {
      display: block;
      font-size: rem(12);
      line-height: rem(18);
    }
  }

  &--tooltip {
    .p-inputtext {
      padding-right: rem(34);
    }

    .#{$ns}tooltip {
      display: none;
      left: rem(235);
    }
  }
}

.p-password-info {
  font-family: $ns-font-default;
}

@include media-query(sm) {
  .o-password {
    .p-inputtext {
      max-width: rem(260);
    }

    &__strength {
      &--verystrong {
        .o-password__strength-bar {
          &:after {
            width: 100%;
            background-color: color('verystrong');
          }
        }
      }

      &--strong {
        .o-password__strength-bar {
          &:after {
            width: 100%;
            background-color: color('strong');
          }
        }
      }

      &--medium {
        .o-password__strength-bar {
          &:after {
            width: 50%;
            background-color: color('medium');
          }
        }
      }

      &--weak {
        .o-password__strength-bar {
          &:after {
            width: 25%;
            background-color: color('weak');
          }
        }
      }

      &--right {
        right: auto;
      }
    }

    &__strength-bar {
      width: rem(160);
      height: rem(5);
      display: inline-block;
      position: relative;
      background-color: color('quaternary');

      &:after {
        content: '';
        height: rem(5);
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.p-float-label {
      .p-inputtext {
        max-width: rem(260);
      }
    }

    &--tooltip {
      .#{$ns}tooltip {
        display: block;
      }
    }
  }
}
