////
/// BREADCRUMBS component
///
/// Custom project Breadcrumbs
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}breadcrumbs
/// @since version 1.0.0
////

.#{$ns}breadcrumbs {
  background-color: color('quaternary');
  border-color: color('quaternary');
  padding: rem(12) rem(0);

  &.p-breadcrumb {
    ul {
      max-width: rem(1124);
      margin: 0 auto;
      padding: 0 rem(16);

      li {
        &.p-breadcrumb-chevron {
          color: color('white');
          margin: 0 rem(20);
          font-size: rem(10);
        }
      }
    }
  }

  .p-menuitem-text {
    color: color('white');
  }

  &.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: color('white');
    font-family: $ns-font-default;
    font-weight: bold;
    font-size: rem(16);
  }
}

@include media-query(xs) {
  .#{$ns}breadcrumbs {
    &.p-breadcrumb {
      ul {
        max-width: rem(480);
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}breadcrumbs {
    &.p-breadcrumb {
      ul {
        max-width: rem(768);
      }
    }
  }
}

@include media-query(md) {
  .#{$ns}breadcrumbs {
    &.p-breadcrumb {
      ul {
        max-width: rem(992);
      }
    }
  }
}

@include media-query(lg) {
  .#{$ns}breadcrumbs {
    &.p-breadcrumb {
      ul {
        max-width: rem(1024);
      }
    }
  }
}

@include media-query(xl) {
  .#{$ns}breadcrumbs {
    &.p-breadcrumb {
      ul {
        max-width: rem(1124);
      }
    }
  }
}
