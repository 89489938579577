
////
/// Colors trump
///
/// Simple class for set color, backgrounds and borders colors from our 
////

@if $include__trump--colors {

    @each $color, $value in $color-map {
        .t-color-background-#{$color}{
            background-color: #{$value} !important;
        }
        .t-color-border-#{$color}{
            border-color: #{$value} !important;
        }
        .t-color-#{$color}{
            color: #{$value} !important;
        }
        @each $status in focus, active, hover {
            .t-color-background-#{$status}-#{$color}{
                &:#{$status} {
                    background-color: #{$value} !important;
                }
            }
            .t-color-border-#{$status}-#{$color}{
                &:#{$status} {
                    border-color: #{$value} !important;
                }
            }
            .t-color-#{$status}-#{$color}{
                &:#{$status} {
                    color: #{$value} !important;
                }
            }
        }
    }

}
