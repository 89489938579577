////
/// STEPS component
///
/// Custom project Steps component
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}steps
/// @since version 1.0.0
////

.#{$ns}steps {
  &__list {
    display: flex;
  }

  &__step-title {
    display: none;
    font-size: rem(16);
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
  }

  &__step {
    display: flex;
    margin-right: rem(28);
    align-items: center;

    &--completed {
      .#{$ns}steps {
        &__step-order {
          color: color('black');
          margin-right: rem(35);

          &:after {
            background-color: color('secondary');
          }
        }
      }
    }

    &--progress {
      margin-right: rem(35);

      .#{$ns}steps {
        &__step-title {
          display: inline;
        }
      }
    }
  }

  &__step-order {
    color: color('white');
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    display: inline-block;
    line-height: rem(24);
    position: relative;
    z-index: 0;

    &:first-child {
      margin-left: rem(15);
    }

    &:after {
      content: '';
      position: absolute;
      top: rem(-7);
      left: -15px;
      width: rem(40);
      height: rem(40);
      border-radius: 50%;
      background-color: color('quaternary');
      z-index: -1;
    }
  }

  &--dark-background {
    .#{$ns}steps {
      &__step-title {
        color: color('white');
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}steps {
    &__step {
      margin-right: rem(35);
    }
  }
}

@include media-query(md) {
  .#{$ns}steps {
    &__list {
      display: block;
    }

    &__step {
      display: inline-block;
      margin-right: rem(50);
      margin-bottom: rem(14);

      &--completed {
        .#{$ns}steps {
          &__step-order {
            margin-right: rem(27);
          }
        }
      }
    }

    &__step-title {
      display: inline;
      font-size: rem(20);
    }

    &__step-order {
      margin-right: rem(27);
    }
  }
}
