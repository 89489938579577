////
/// BILLING component
///
/// Custom project Billing
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}billing
/// @since version 1.0.0
////

.#{$ns}billing {
  background-color: color('white');
  height: rem(270);
  padding: rem(52) rem(29);

  &__title {
    font-size: rem(20);
    color: color('terciary');
    line-height: rem(19);
    margin-bottom: rem(24);
  }

  &__payment-info {
    margin-bottom: rem(12);
  }

  &__sub-title {
    font-size: rem(16);
    color: color('black');
    margin-bottom: rem(10);
    font-family: $ns-font-default;
    font-weight: bold;
  }

  &__payment-method {
    color: color('quaternary');
    font-size: rem(16);
    line-height: rem(20);
  }

  &__link {
    font-size: rem(16);
    color: color('quaternary');
    text-decoration: underline;

    &:hover {
      color: color('quaternary');
    }
  }
}
