/*-------------------------------------------------------------
	#SWITCH
-----------------------------------------------------------------*/

.o-switch {
  .p-inputswitch {
    width: rem(37);
    height: rem(14);

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: color('primary');
      }

      &:not(.p-disabled):hover .p-inputswitch-slider {
        background: color('primary');
      }
    }
  }

  .p-inputswitch-slider {
    width: rem(37);
    height: rem(14);

    &::before {
      border: 1px solid color('grey-1');
      box-shadow: 0px 3px 6px #00000029;
      left: 0;
    }
  }
}

// .o-switch {
//   position: relative;
//   display: inline-block;
//   width: rem(37);
//   height: rem(14);

//   &__input {
//       opacity: 0;
//       width: 0;
//       height: 0;

//       &:checked {
//           + .o-switch__slider {
//               background-color: color('primary');

//               &::before {
//                   @include transform(translateX(19px));
//               }
//           }
//       }

//       &:focus {
//           + .o-switch__slider {
//               box-shadow: 0 0 1px color('primary');
//           }
//       }
//   }

//   &__slider {
//       position: absolute;
//       cursor: pointer;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       background-color: color('grey-1');
//       -webkit-transition: .4s;
//       transition: .4s;
//       border-radius: 34px;

//       &::before {
//           position: absolute;
//           content: "";
//           height: rem(20);
//           width: rem(20);
//           left: rem(0);
//           bottom: rem(-2);
//           background-color: color('white');
//           -webkit-transition: .4s;
//           transition: .4s;
//           border-radius: 50%;
//           box-shadow: 0px 3px 6px #00000029;
//           border: rem(1) solid color('grey-1');
//       }
//   }
// }
