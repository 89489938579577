////
/// NAVIGATION component
///
/// Custom project Navigation
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}navigation
/// @since version 1.0.0
////

.#{$ns}navigation {
  left: 0;
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;

  &.p-tabmenu {
    overflow: initial;

    .p-tabmenu-nav {
      background-color: color('terciary');
      border: none;
      justify-content: space-evenly;

      .p-tabmenuitem {
        .p-menuitem-link {
          font-family: Arial;
          font-weight: bold;
          font-size: rem(21);
          color: color('white', 0.5);
          background-color: color('terciary');
          border-color: color('terciary');
          // border-width: 0 0 rem(5) 0;
          padding: rem(13) 0 rem(6);
          border-radius: 0;
          flex-wrap: wrap;

          // border: 0;

          &:not(.p-disabled):focus {
            box-shadow: 0 0 0 0.2rem color('secondary', 0.5);
          }
        }

        .p-menuitem-text {
          font-size: rem(10);
          width: 100%;
          text-align: center;
        }

        .p-menuitem-icon {
          display: block;
          width: 100%;
          text-align: center;
          margin-right: 0;
          margin-bottom: rem(2);
        }

        &.p-highlight {
          .p-menuitem-link {
            // border-color: color('terciary');
            color: color('white');
          }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
          .p-menuitem-link {
            background-color: color('terciary');
            // border-color: color('terciary');
            color: color('white');
          }
        }
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}navigation {
    position: static;

    &.p-tabmenu {
      .p-tabmenu-nav {
        background-color: color('primary');
        border: none;
        justify-content: flex-start;

        .p-tabmenuitem {
          margin-right: rem(58);

          .p-menuitem-link  {
            font-family: Arial;
            font-weight: bold;
            font-size: rem(21);
            color: color('white');
            background-color: color('primary');
            border-color: color('primary');
            border-width: 0 0 rem(5) 0;
            padding: rem(13) 0 rem(6);
            border-radius: 0;

            &:not(.p-disabled):focus {
              box-shadow: 0 0 0 0.2rem color('secondary', 0.5);
            }
          }

          .p-menuitem-text {
            font-size: rem(21);
          }

          .p-menuitem-icon {
            display: none;
          }

          &.p-highlight {
            .p-menuitem-link {
              border-color: color('secondary');
            }
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            .p-menuitem-link {
              background-color: color('primary');
              border-color: color('secondary');
              color: color('white');
            }
          }
        }
      }
    }
  }
}
