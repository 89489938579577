/*-------------------------------------------------------------
	#LINK
-----------------------------------------------------------------*/

.o-link {
  color: color('black');
  font-family: $ns-font-default;
  font-weight: bold;

  &--back {
    .icon-chevron-light-left {
      color: color('primary');
      font-size: rem(11);
      margin-right: rem(13);
    }

    &:hover {
      text-decoration: none;

      .o-link__text {
        text-decoration: underline;
        color: color('primary');
      }
    }
  }
}
