////
/// WEBFORM component
///
/// Custom project Webform
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}webform
/// @since version 1.0.0
////

.#{$ns}webform {
  background-color: color('white');
  padding: rem(38) rem(29);

  &__legend {
    font-size: rem(30);
    color: color('terciary');
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: rem(33);
  }

  &__section-title {
    font-family: $ns-font-default;
    font-weight: bold;
    font-size: rem(20);
    color: color('black');
    margin-right: rem(29);
  }

  &__section-subtitle {
    font-family: $ns-font-default;
    font-size: rem(14);
    color: color('quaternary');
    font-style: italic;
  }

  &__txt {
    font-size: rem(16);
    font-family: $ns-font-default;
    color: color('quaternary');

    &--bold {
      font-weight: bold;
      color: color('black');
    }
  }

  .o-inputnumber {
    &.p-float-label {
      input {
        max-width: none;
        width: 100%;
      }
    }
  }

  .o-inputtext {
    &__input {
      max-width: none;
      width: 100%;
    }
  }

  .o-btn {
    width: 100%;
  }

  .o-password__strength {
    vertical-align: middle;
    margin-left: rem(25);
    position: absolute;
    top: rem(21);
    right: 0;
  }
}

@include media-query(sm) {
  .#{$ns}webform {
    .o-inputnumber {
      &.p-float-label {
        input {
          max-width: rem(260);
        }
      }
    }

    .o-inputtext {
      &__input {
        max-width: rem(260);
      }
    }

    .o-btn {
      width: auto;
      min-width: rem(180);
    }

    .o-password__strength {
      margin-left: rem(22);
      top: rem(16);
      right: auto;
    }
  }
}
