////
/// LOGIN component
///
/// Custom project login
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}login
/// @since version 1.0.0
////

.#{$ns}login {
  background-color: color('white');
  padding: rem(33) rem(30) rem(30);

  &__card {
    color: color('black');
  }

  //main
  &__main{
    &-title {
      font-size: rem(30);
      font-family: obviously, sans-serif;
      font-weight: 700;
      font-style: normal;
      color: color('terciary');
      margin-bottom: rem(17);
      display: block;
    }
    &-description {
      line-height: rem(18);
    }

    .comment-box{
      line-height: rem(18);
    }
    &-comment{
      color: color('grey-3');
      font-size: rem(14);
      padding: rem(22);
      font-style: italic;
      display: block;
      padding-top: 0;
    }

    &-inputbox {
      padding: rem(25) 0 rem(28);
      position: relative;

      .o-inputtext,
      .o-inputtext__input,
      .p-inputmask,
      .o-password {
        width: 100%;
        max-width: none;

        &.second-input{
          margin-top: rem(24);
        }

        &.p-inputtext {
          max-width: none;
        }

        &::placeholder {
          font-style: italic;
          font-family: $ns-font-default;
        }
      }
      .o-inputtext {
        &.p-float-label {
          .o-password {
            padding-right: rem(92);
            padding-bottom: 0;
          }
        }
      }
      .p-inputmask {
        text-align: center;
        font-size: rem(24);
        color: color('grey-3');

        &::placeholder {
          font-style: normal;
          font-size: rem(32);
        }
      }

      .p-inputmask--small {
        .p-inputmask {
          font-size: rem(16);

          &::placeholder {
            font-size: rem(16);
          }
        }
      }

      .o-password__strength-bar {
        border-radius: 0;
      }
    }
    .p-field-checkbox{
      margin-bottom: rem(30);
      label{
        margin-left: rem(10);
      }
    }
    &-buttonbox{
      padding: 0 0 rem(20) 0;

      button{
        width: 100%;

        &:enabled:hover, &:enabled:active{
         @extend %btn;
        }

        &:disabled{
          background: transparent linear-gradient(102deg, #939393 0%, #C1C5C7 100%) 0% 0% no-repeat padding-box;
          color: color('white');
        }
      }
    }
    &-tooltip {
      &.#{$ns}tooltip {
        left: auto;
        right: rem(10);
        top: rem(10);
        display: none;
      }
    }
  }
  &__footer{
    &-back{
      color: color('black');
      font-weight: bold;
      &::before{
        content: "\e900";
        font-family: 'primeicons';
        color: color('primary');
        font-size: 0.75rem;
        line-height: 2rem;
        margin-right: rem(6);
        font-weight: normal;
      }
      &:hover, &:focus {
        text-decoration: none;
      }
      &:hover {
        color: color('black');
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}login {
    &__main {
      &-inputbox {
        .o-inputtext,
        .o-inputtext__input,
        .p-inputmask,
        .o-password {
          width: 100%;
          max-width: rem(260);
        }

        .o-inputtext {
          &.p-float-label {
            .o-password {
              padding-right: rem(34);
            }
          }
        }

        .#{$ns}tooltip__text {
          display: block;
        }
      }

      &-buttonbox {
        button {
          max-width: rem(260);
        }
      }

      &-tooltip {
        &.#{$ns}tooltip {
          display: block;
        }
      }
    }
  }
}
