/*-------------------------------------------------------------
	#PROGRESSBAR
-----------------------------------------------------------------*/

.o-progressbar {
  .p-progressbar {
    height: rem(10);
    border-radius: 0;
    background-color: color('quaternary');
  }

  .p-progressbar-value {
    background-color: color('secondary');
  }

  &--completed {
    .p-progressbar-value {
      background-color: color('error');
    }
  }
}
