////
/// TOOLTIP component
///
/// Custom project Tooltip component
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}tooltip
/// @since version 1.0.0
////

.#{$ns}tooltip {
  margin-left: rem(16);
  color: color('terciary');
  position: absolute;
  margin-left: 0;
  top: rem(12);

  &__text {
    &.p-tooltip {
      .p-tooltip-text {
        font-size: rem(13);
        font-family: $ns-font-default;
        color: color('black');
        background-color: color('white');
        border-radius: 0;
      }

      .p-tooltip-arrow {
        border-right-color: color('white');
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}tooltip {
    left: rem(273);
  }
}
