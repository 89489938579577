///*-------------------------------------------------------------------------
//   #SETTINGS CONFIG
//--------------------------------------------------------------------------*/

//	CONTENT THIS FILE:
//		# NAMESPACE
//      # CONFIG - FONTS
//		# BASIC VALUE FOR PROJECT
//      # CONFIG - SETTINGS



///*-------------------------------------------------------------------------
//   	# NAMESPACE
//--------------------------------------------------------------------------*/
$ns				:   iris-;



///*-----------------------------------------------------
///     # CONFIG - FONTS
///-----------------------------------------------------*/
$ns-font-default				  : Arial, Helvetica, sans-serif;
$ns-font-alternative			: 'Source Sans Pro', verdana;



///*-------------------------------------------------------------------------
//   	# BASIC VALUE FOR PROJECT
//--------------------------------------------------------------------------*/

$ns-font-family          :	$ns-font-default;
$ns-font-size            :	16;
$ns-line-height          :	1.2;
$ns-background-color     :	white;
$ns-path-font            :	"../../fonts/";
$ns-path-img             :	"../../images/";
