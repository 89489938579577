////
/// FOOTER component
///
/// Custom project footer
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}footer
/// @since version 1.0.0
////

.#{$ns}footer {
  background-color: color('white');
  color: color('primary');
  padding: rem(3) 0;

  padding-bottom: rem(52);

  &__col {
    line-height: rem(16);

    &:first-child {
      padding-left: rem(139);
    }

    &:last-child {
      padding-right: rem(139);
    }
  }

  &__txt {
    display: inline-block;
    font-size: rem(14);
    margin-right: rem(20);

    a {
      color: color('primary');
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .t-display-flex {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@include media-query(sm) {
  .#{$ns}footer {
    padding-bottom: rem(3);

    .t-display-flex {
      justify-content: flex-end;
      flex-wrap: nowrap;
    }
  }
}

@include media-query(md) {
  .#{$ns}footer {
    .t-display-flex {
      flex-wrap: nowrap;
    }
  }
}
