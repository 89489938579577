////
/// HTML element
///
/// This is the html's style sheet
////
html {
    position                    : relative;
    height                      : 100%;
    min-height                  : 100%;
    font-family                 : $ns-font-family;
    font-size                   : $ns-font-size + px;
    -webkit-text-size-adjust    : 100%;
    -ms-text-size-adjust        : 100%;
}