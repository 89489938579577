/*-------------------------------------------------------------
    #SLIDER
-----------------------------------------------------------------*/

.o-slider {
  .p-slider {
    &.p-slider-horizontal {
      height: rem(6);
    }

    .p-slider-range {
      background-color: color('primary');
    }

    .p-slider-handle {
      border-color: color('black');
      width: rem(20);
      height: rem(20);

      &:focus {
        box-shadow: none;
      }
    }

    &:not(.p-disabled) {
      .p-slider-handle {
        &:hover {
          background-color: color('white');
          border-color: color('black');
        }
      }
    }
  }
}
