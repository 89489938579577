/*-------------------------------------------------------------
    #BOX
-----------------------------------------------------------------*/
.o-box {
  height: rem(456);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: rem(-33);
    left: rem(-33);
    width: rem(65);
    height: rem(65);
    transform: rotate(45deg);
    background-color: color('primary');
  }

  figure {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: block;
    }
  }
}
