@charset "UTF-8";
.iris-container,
.iris-container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
}
.iris-container .iris-grid,
.iris-container-full .iris-grid {
  margin-right: -8px;
  margin-left: -8px;
}

@media (min-width: 480px) {
  .iris-container {
    max-width: 480px;
  }
}
@media (min-width: 768px) {
  .iris-container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .iris-container {
    max-width: 992px;
  }
}
@media (min-width: 1024px) {
  .iris-container {
    max-width: 1024px;
  }
}
@media (min-width: 1124px) {
  .iris-container {
    max-width: 1124px;
  }
}

.iris-grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  word-spacing: -0.43em;
  list-style-type: none;
}
.iris-grid::before, .iris-grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*=iris-col-] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 8px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
[class*=iris-col-]::before, [class*=iris-col-]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}
[class*=iris-col-] .iris-grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -8px;
}

.iris-col-12 {
  width: 100%;
  *width: 99.9%;
}

.iris-col-11 {
  width: 91.6666666667%;
  *width: 91.5666666667%;
}

.iris-col-10 {
  width: 83.3333333333%;
  *width: 83.2333333333%;
}

.iris-col-9 {
  width: 75%;
  *width: 74.9%;
}

.iris-col-8 {
  width: 66.6666666667%;
  *width: 66.5666666667%;
}

.iris-col-7 {
  width: 58.3333333333%;
  *width: 58.2333333333%;
}

.iris-col-6 {
  width: 50%;
  *width: 49.9%;
}

.iris-col-5 {
  width: 41.6666666667%;
  *width: 41.5666666667%;
}

.iris-col-4 {
  width: 33.3333333333%;
  *width: 33.2333333333%;
}

.iris-col-3 {
  width: 25%;
  *width: 24.9%;
}

.iris-col-2 {
  width: 16.6666666667%;
  *width: 16.5666666667%;
}

.iris-col-1 {
  width: 8.3333333333%;
  *width: 8.2333333333%;
}

@media (min-width: 480px) {
  .iris-col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }

  .iris-col-xs-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .iris-col-xs-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .iris-col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }

  .iris-col-xs-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .iris-col-xs-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .iris-col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }

  .iris-col-xs-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .iris-col-xs-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .iris-col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }

  .iris-col-xs-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .iris-col-xs-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 768px) {
  .iris-col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }

  .iris-col-sm-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .iris-col-sm-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .iris-col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }

  .iris-col-sm-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .iris-col-sm-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .iris-col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }

  .iris-col-sm-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .iris-col-sm-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .iris-col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }

  .iris-col-sm-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .iris-col-sm-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 992px) {
  .iris-col-md-12 {
    width: 100%;
    *width: 99.9%;
  }

  .iris-col-md-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .iris-col-md-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .iris-col-md-9 {
    width: 75%;
    *width: 74.9%;
  }

  .iris-col-md-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .iris-col-md-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .iris-col-md-6 {
    width: 50%;
    *width: 49.9%;
  }

  .iris-col-md-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .iris-col-md-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .iris-col-md-3 {
    width: 25%;
    *width: 24.9%;
  }

  .iris-col-md-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .iris-col-md-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 1024px) {
  .iris-col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .iris-col-lg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .iris-col-lg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .iris-col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .iris-col-lg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .iris-col-lg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .iris-col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .iris-col-lg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .iris-col-lg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .iris-col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .iris-col-lg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .iris-col-lg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 1124px) {
  .iris-col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .iris-col-xlg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .iris-col-xlg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .iris-col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .iris-col-xlg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .iris-col-xlg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .iris-col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .iris-col-xlg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .iris-col-xlg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .iris-col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .iris-col-xlg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .iris-col-xlg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
.iris-col-auto {
  -ms-flex: 1 0 0px;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
  width: auto;
}

@media (min-width: 480px) {
  .iris-col-xs-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .iris-col-sm-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .iris-col-md-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .iris-col-lg-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 1124px) {
  .iris-col-xlg-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
.iris-order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.iris-order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}

.iris-order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}

.iris-order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}

.iris-order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}

.iris-order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}

.iris-order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}

.iris-order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}

.iris-order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}

.iris-order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.iris-order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.iris-order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

.iris-order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

@media only screen and (min-width: 480px) {
  .iris-order-xs-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .iris-order-xs-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .iris-order-xs-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .iris-order-xs-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .iris-order-xs-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .iris-order-xs-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .iris-order-xs-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .iris-order-xs-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .iris-order-xs-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .iris-order-xs-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .iris-order-xs-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .iris-order-xs-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .iris-order-xs-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 768px) {
  .iris-order-sm-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .iris-order-sm-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .iris-order-sm-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .iris-order-sm-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .iris-order-sm-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .iris-order-sm-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .iris-order-sm-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .iris-order-sm-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .iris-order-sm-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .iris-order-sm-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .iris-order-sm-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .iris-order-sm-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .iris-order-sm-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 992px) {
  .iris-order-md-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .iris-order-md-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .iris-order-md-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .iris-order-md-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .iris-order-md-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .iris-order-md-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .iris-order-md-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .iris-order-md-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .iris-order-md-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .iris-order-md-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .iris-order-md-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .iris-order-md-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .iris-order-md-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .iris-order-lg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .iris-order-lg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .iris-order-lg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .iris-order-lg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .iris-order-lg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .iris-order-lg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .iris-order-lg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .iris-order-lg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .iris-order-lg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .iris-order-lg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .iris-order-lg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .iris-order-lg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .iris-order-lg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 1124px) {
  .iris-order-xlg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .iris-order-xlg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .iris-order-xlg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .iris-order-xlg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .iris-order-xlg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .iris-order-xlg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .iris-order-xlg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .iris-order-xlg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .iris-order-xlg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .iris-order-xlg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .iris-order-xlg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .iris-order-xlg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .iris-order-xlg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
.iris-offset-11 {
  margin-left: 91.6666666667%;
  *margin-left: 91.5666666667%;
}

.iris-offset-10 {
  margin-left: 83.3333333333%;
  *margin-left: 83.2333333333%;
}

.iris-offset-9 {
  margin-left: 75%;
  *margin-left: 74.9%;
}

.iris-offset-8 {
  margin-left: 66.6666666667%;
  *margin-left: 66.5666666667%;
}

.iris-offset-7 {
  margin-left: 58.3333333333%;
  *margin-left: 58.2333333333%;
}

.iris-offset-6 {
  margin-left: 50%;
  *margin-left: 49.9%;
}

.iris-offset-5 {
  margin-left: 41.6666666667%;
  *margin-left: 41.5666666667%;
}

.iris-offset-4 {
  margin-left: 33.3333333333%;
  *margin-left: 33.2333333333%;
}

.iris-offset-3 {
  margin-left: 25%;
  *margin-left: 24.9%;
}

.iris-offset-2 {
  margin-left: 16.6666666667%;
  *margin-left: 16.5666666667%;
}

.iris-offset-1 {
  margin-left: 8.3333333333%;
  *margin-left: 8.2333333333%;
}

@media only screen and (min-width: 480px) {
  .iris-offset-xs-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .iris-offset-xs-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .iris-offset-xs-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .iris-offset-xs-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .iris-offset-xs-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .iris-offset-xs-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .iris-offset-xs-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .iris-offset-xs-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .iris-offset-xs-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .iris-offset-xs-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .iris-offset-xs-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .iris-offset-xs-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 768px) {
  .iris-offset-sm-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .iris-offset-sm-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .iris-offset-sm-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .iris-offset-sm-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .iris-offset-sm-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .iris-offset-sm-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .iris-offset-sm-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .iris-offset-sm-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .iris-offset-sm-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .iris-offset-sm-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .iris-offset-sm-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .iris-offset-sm-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 992px) {
  .iris-offset-md-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .iris-offset-md-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .iris-offset-md-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .iris-offset-md-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .iris-offset-md-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .iris-offset-md-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .iris-offset-md-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .iris-offset-md-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .iris-offset-md-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .iris-offset-md-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .iris-offset-md-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .iris-offset-md-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 1024px) {
  .iris-offset-lg-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .iris-offset-lg-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .iris-offset-lg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .iris-offset-lg-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .iris-offset-lg-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .iris-offset-lg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .iris-offset-lg-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .iris-offset-lg-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .iris-offset-lg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .iris-offset-lg-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .iris-offset-lg-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .iris-offset-lg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media only screen and (min-width: 1124px) {
  .iris-offset-xlg-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .iris-offset-xlg-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .iris-offset-xlg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .iris-offset-xlg-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .iris-offset-xlg-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .iris-offset-xlg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .iris-offset-xlg-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .iris-offset-xlg-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .iris-offset-xlg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .iris-offset-xlg-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .iris-offset-xlg-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .iris-offset-xlg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
.iris-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.iris-no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.iris-no-wrap [class*=iris-col-] {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

.iris-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.iris-direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.iris-direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.iris-direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.iris-direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.iris-align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.iris-align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.iris-align-end [class*=iris-col-] {
  vertical-align: bottom;
}

.iris-align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.iris-align-center [class*=iris-col-] {
  vertical-align: middle;
}

.iris-align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.iris-align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.iris-align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.iris-align-content-end [class*=iris-col-] {
  vertical-align: bottom;
}

.iris-align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.iris-align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.iris-align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.iris-align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.iris-align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.iris-align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.iris-align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.iris-align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.iris-justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.iris-justify-start.grid {
  text-align: left;
}

.iris-justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.iris-justify-end.grid {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}
.iris-justify-end.grid [class*=iris-col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.iris-justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.iris-justify-center.grid {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}
.iris-justify-center.grid [class*=iris-col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.iris-justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.iris-justify-space-between.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}
.iris-justify-space-between.grid [class*=iris-col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.iris-justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.iris-justify-space-around.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}
.iris-justify-space-around.grid [class*=iris-col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.iris-grid-bleed [class*=iris-col-] {
  padding: 0;
}

.iris-col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.iris-col-grid.iris-direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.iris-col-bleed {
  padding: 0;
}

.iris-col-bleed-x {
  padding: 8px 0;
}

.iris-col-bleed-y {
  padding: 0 8px;
}

.iris-flex-img {
  display: block;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  width: 100%;
  *width: auto;
}

.iris-flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}
.iris-flex-footer > :last-child {
  margin-bottom: 0;
}

.hide-accessible, .o-radio__ipt, .o-checkbox__ipt {
  position: absolute;
  left: -9999rem;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@font-face {
  font-family: "Helvetica-Neue";
  font-style: "normal";
  font-weight: "400";
  src: url("../fonts/helvetica--neue/HelvNeue55.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica-Neue-35";
  font-style: "normal";
  font-weight: "100";
  src: url("../fonts/helvetica--neue/HelvNeue35.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica-Neue-45";
  font-style: "normal";
  font-weight: "300";
  src: url("../fonts/helvetica--neue/HelvNeue45.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica-Neue-65";
  font-style: "normal";
  font-weight: "500";
  src: url("../fonts/helvetica--neue/HelvNeue65.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica-Neue-75";
  font-style: "normal";
  font-weight: "700";
  src: url("../fonts/helvetica--neue/HelvNeue75.ttf") format("truetype");
}
html,
body,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary,
audio,
canvas,
video,
mark,
code,
kbd,
pre,
samp,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
strong,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
div {
  margin: 0;
  padding: 0;
  border: none;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul,
ol,
dl {
  list-style: none;
}

/*-----------------------------------
	#TAGS HTML5 BROWSER NO SUPPORT
-------------------------------------*/
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1rem;
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*-----------------------------------
	#LINKS
-------------------------------------*/
a {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  text-decoration-skip: objects;
  -webkit-text-decoration-skip: objects;
}

a:focus,
a:hover {
  text-decoration: underline;
}

a:visited,
a:active {
  text-decoration: none;
}

a:active,
a:hover {
  outline-width: 0;
}

/*-----------------------------------
	#HEADINGS, WITH AND WITHOUT LINK
-------------------------------------*/
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

/*-----------------------------------
	#RESET TABLE
-------------------------------------*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*-----------------------------------
	#RESET FORM
-------------------------------------*/
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 * 3. Remove shading to form elements 
 */
button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

[type=radio] + label,
[type=checkbox] + label {
  cursor: pointer;
}

/*-----------------------------------
	#RESET INTERACTIVE
-------------------------------------*/
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*-----------------------------------
	#RESET SCRIPTING
-------------------------------------*/
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/*-----------------------------------
	#RESET HIDDEN
-------------------------------------*/
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/*--------------------------------
	# END GENERIC RESET
--------------------------------*/
a:focus, a:active {
  outline: 0;
}

body {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  background-color: #d73e0f;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

button {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}
button:hover, button:focus, button:active {
  outline: 0;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  color: #0f1efa;
  line-height: 2.25rem;
  font-style: normal;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

html {
  position: relative;
  height: 100%;
  min-height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  font-style: italic;
}
img[width], img[height] {
  width: auto;
  height: auto;
}
figure img {
  width: 100%;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

/*-------------------------------------------------------------
    #ALERTS
-----------------------------------------------------------------*/
.o-alert {
  position: relative;
  padding: 1.1875rem 1.25rem;
  margin-bottom: 0.75rem;
  border: 2px solid black;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  /*
  * Elements
  */
  /**
   * Modifiers others
   */
  /**
   * Modifiers colors
   */
}
.o-alert__txt {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
.o-alert__txt:last-child {
  margin-bottom: 0;
}
.o-alert__close {
  position: absolute;
  top: 0.625rem;
  bottom: auto;
  right: 0.625rem;
  left: auto;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  padding: 0;
  font-size: 1.375rem;
  line-height: 1.2;
  color: #cccccc;
  border: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.o-alert__close:hover, .o-alert__close:focus, .o-alert__close:active {
  outline: 0;
  color: #cccccc;
}
.o-alert__icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  font-size: 2rem;
  min-width: 1.25rem;
  text-align: center;
  transform: translateY(-50%);
}
.o-alert--close {
  padding-right: 2.5rem;
}
.o-alert--icon {
  padding-left: 4.1875rem;
}
.o-alert--primary {
  color: #d73e0f;
  background-color: white;
  border-color: #d73e0f;
}
.o-alert--error {
  color: #cd3c14;
  background-color: white;
  border-color: #cd3c14;
}

/*-------------------------------------------------------------
    #BUTTONS
-----------------------------------------------------------------*/
.o-btn,
.o-btn.p-button,
.iris-login__main-buttonbox button:enabled:hover,
.iris-login__main-buttonbox button:enabled:active {
  display: inline;
  padding: 1rem 2rem;
  font-family: obviously,  sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0px solid #0f1efa;
  cursor: pointer;
  background: #0f1efa;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  /**
    primeng button
  */
  /**
      * Actions
    */
  /**
         * Disabled
   */
  /**
         * Modifiers others
   */
  /**
    Modifiers separations
  */
}
.o-btn:after,
.o-btn.p-button:after,
.iris-login__main-buttonbox button:enabled:hover:after,
.iris-login__main-buttonbox button:enabled:active:after {
  content: "";
  position: absolute;
  border-width: 0.625rem;
  border-color: white gold gold white;
  border-style: solid;
  bottom: 0;
  right: 0;
}
.o-btn.p-button:enabled:hover,
.o-btn.p-button.p-button:enabled:hover,
.iris-login__main-buttonbox button.p-button:enabled:hover {
  color: #0f1efa;
  background-color: gold;
  opacity: 1;
}
.o-btn.p-button:enabled:hover:before,
.o-btn.p-button.p-button:enabled:hover:before,
.iris-login__main-buttonbox button.p-button:enabled:hover:before {
  content: "";
  position: absolute;
  border-width: 0.625rem;
  border-color: white transparent transparent white;
  border-style: solid;
  top: 0;
  left: 0;
}
.o-btn.p-button:enabled:hover:after,
.o-btn.p-button.p-button:enabled:hover:after,
.iris-login__main-buttonbox button.p-button:enabled:hover:after {
  content: none;
}
.o-btn:hover,
.o-btn.p-button:hover,
.iris-login__main-buttonbox button:hover:enabled {
  text-decoration: none;
  opacity: 1;
  color: #0f1efa;
  background-color: gold;
}
.o-btn:hover:before,
.o-btn.p-button:hover:before,
.iris-login__main-buttonbox button:hover:enabled:before {
  content: "";
  position: absolute;
  border-width: 0.625rem;
  border-color: white transparent transparent white;
  border-style: solid;
  top: 0;
  left: 0;
}
.o-btn:hover:after,
.o-btn.p-button:hover:after,
.iris-login__main-buttonbox button:hover:enabled:after {
  content: none;
}
.o-btn:active, .o-btn:focus,
.o-btn.p-button:active,
.o-btn.p-button:focus,
.iris-login__main-buttonbox button:active:enabled,
.iris-login__main-buttonbox button:focus:enabled:hover {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}
.o-btn:disabled,
.o-btn.p-button:disabled,
.iris-login__main-buttonbox button:disabled:enabled:hover,
.iris-login__main-buttonbox button:disabled:enabled:active {
  color: white;
  background-color: #888888;
  border-color: #888888;
  cursor: default !important;
  pointer-events: unset;
  opacity: 1;
}
.o-btn:disabled:after,
.o-btn.p-button:disabled:after,
.iris-login__main-buttonbox button:disabled:enabled:hover:after,
.iris-login__main-buttonbox button:disabled:enabled:active:after {
  border-color: white #444444 #444444 white;
}
.o-btn:disabled:hover:before,
.o-btn.p-button:disabled:hover:before,
.iris-login__main-buttonbox button:disabled:hover:enabled:before {
  content: none;
}
.o-btn:disabled:hover:after,
.o-btn.p-button:disabled:hover:after,
.iris-login__main-buttonbox button:disabled:hover:enabled:after {
  content: "";
  border-color: white #444444 #444444 white;
}
.o-btn[disabled=true],
.o-btn.p-button[disabled=true],
.iris-login__main-buttonbox button[disabled=true]:enabled:hover,
.iris-login__main-buttonbox button[disabled=true]:enabled:active {
  cursor: default;
}
.o-btn--block,
.o-btn.p-button--block {
  display: block;
  width: 100%;
}
.o-btn--block-auto,
.o-btn.p-button--block-auto {
  display: block !important;
}
.o-btn--link,
.o-btn.p-button--link {
  border: none;
  color: black;
  text-decoration: underline;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
}
.o-btn--link:before, .o-btn--link:after,
.o-btn.p-button--link:before,
.o-btn.p-button--link:after {
  content: none;
}
.o-btn--link:hover,
.o-btn.p-button--link:hover {
  opacity: 1;
  color: black;
  background: none;
}
.o-btn--link:hover:before, .o-btn--link:hover:after,
.o-btn.p-button--link:hover:before,
.o-btn.p-button--link:hover:after {
  content: none;
}
.o-btn--link-italic,
.o-btn.p-button--link-italic {
  border: none;
  text-decoration: underline;
  padding: 0;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  background-color: transparent;
  color: gold;
}
.o-btn--link-italic:after,
.o-btn.p-button--link-italic:after {
  content: none;
}
.o-btn--link-italic:hover,
.o-btn.p-button--link-italic:hover {
  text-decoration: underline;
  color: #d73e0f;
  background-color: transparent;
}
.o-btn--link-italic:hover:before,
.o-btn.p-button--link-italic:hover:before {
  content: none;
}
.o-btn--secondary,
.o-btn.p-button--secondary {
  border-radius: 0;
  background-color: #d73e0f;
  padding: 0.6875rem 1.625rem;
}
.o-btn--link-black,
.o-btn.p-button--link-black {
  border: none;
  text-decoration: underline;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: transparent;
  color: black;
}
.o-btn--link-black:hover,
.o-btn.p-button--link-black:hover {
  text-decoration: underline;
  background-color: transparent;
  color: black;
}
.o-btn--link-black:hover:before,
.o-btn.p-button--link-black:hover:before {
  content: none;
}
.o-btn--link-black:after,
.o-btn.p-button--link-black:after {
  content: none;
}
.o-btn--mt-small,
.o-btn.p-button--mt-small {
  margin-top: 2.1875rem;
}

/*-------------------------------------------------------------
    #CHECKBOX
-----------------------------------------------------------------*/
.o-checkbox {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.o-checkbox__ipt {
  height: 0;
  width: 0;
}
.o-checkbox__ipt:focus + .o-checkbox__label .o-checkbox__icon {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: #d73e0f;
  -webkit-box-shadow: 0 0 0 3px rgba(215, 62, 15, 0.5);
  -ms-box-shadow: 0 0 0 3px rgba(215, 62, 15, 0.5);
  box-shadow: 0 0 0 3px rgba(215, 62, 15, 0.5);
}
.o-checkbox__ipt:checked + .o-checkbox__label > .o-checkbox__icon {
  border: 10px solid #d73e0f;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.o-checkbox__ipt:checked + .o-checkbox__label > .o-checkbox__icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.5rem;
  border-right: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transform-origin: 0 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}
.o-checkbox__ipt:disabled + .o-checkbox__label .o-checkbox__icon {
  background-color: #cccccc;
  border-color: #e6e6e6;
}
.o-checkbox__ipt:disabled + .o-checkbox__label .o-checkbox__txt {
  color: #e6e6e6;
}
.o-checkbox__label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  -ms-transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.o-checkbox__label:hover > .o-checkbox__icon {
  background-color: rgba(0, 0, 0, 0.1);
}
.o-checkbox__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: white;
  border: 2px solid #cccccc;
  cursor: pointer;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  -ms-transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.o-checkbox__txt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  width: calc(100% - 32px);
}

/**
* Animations keyframes
*/
@keyframes shrink-bounce {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: white;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
    -ms-transform: translate3d(0, 0, 0) rotate(45deg);
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.5rem;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
    -ms-transform: translate3d(0, 0, 0) rotate(45deg);
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.5rem;
    height: 0.875rem;
    border-color: white;
    -webkit-transform: translate3d(0, -14px, 0) rotate(45deg);
    -ms-transform: translate3d(0, -14px, 0) rotate(45deg);
    transform: translate3d(0, -14px, 0) rotate(45deg);
  }
}
/*-------------------------------------------------------------
    #radio
-----------------------------------------------------------------*/
.o-radio {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.o-radio__ipt {
  height: 0;
  width: 0;
}
.o-radio__ipt:focus + .o-radio__label .o-radio__icon {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: #d73e0f;
  -webkit-box-shadow: 0 0 0 3px rgba(215, 62, 15, 0.5);
  -ms-box-shadow: 0 0 0 3px rgba(215, 62, 15, 0.5);
  box-shadow: 0 0 0 3px rgba(215, 62, 15, 0.5);
}
.o-radio__ipt:checked + .o-radio__label > .o-radio__icon {
  border: 10px solid #d73e0f;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.o-radio__ipt:checked + .o-radio__label > .o-radio__icon:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.625rem;
  height: 0.625rem;
  background-color: white;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.o-radio__ipt:disabled + .o-radio__label .o-radio__icon {
  background-color: #cccccc;
  border-color: #e6e6e6;
}
.o-radio__ipt:disabled + .o-radio__label .o-radio__txt {
  color: #e6e6e6;
}
.o-radio__label {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  -ms-transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.o-radio__label:hover > .o-radio__icon {
  background-color: rgba(0, 0, 0, 0.1);
}
.o-radio__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #cccccc;
  cursor: pointer;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  -ms-transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.o-radio__txt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  color: black;
}
.o-radio .p-radiobutton .p-radiobutton-box {
  border-color: #eeeeee;
}
.o-radio .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: white;
}
.o-radio .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #0f1efa;
  background-color: #0f1efa;
}
.o-radio .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0f1efa;
  background-color: #0f1efa;
}
.o-radio .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #eeeeee;
}
.o-radio .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}

@keyframes shrink-bounce {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
/*-------------------------------------------------------------
    #TEXT
-----------------------------------------------------------------*/
.o-txt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  color: black;
  /**
   * Modifiers others
   */
}
.o-txt--link {
  color: #d73e0f;
}
.o-txt--bold {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.o-txt--inline-block {
  display: inline-block;
}
.o-txt--block {
  display: block;
}
.o-txt--grey {
  color: #444444;
}
.o-txt--link-grey {
  color: #444444;
  text-decoration: underline;
}
.o-txt--link-grey:hover {
  color: #444444;
}
.o-txt strong {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

/*-------------------------------------------------------------
	#SWITCH
-----------------------------------------------------------------*/
.o-switch .p-inputswitch {
  width: 2.3125rem;
  height: 0.875rem;
}
.o-switch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #d73e0f;
}
.o-switch .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #d73e0f;
}
.o-switch .p-inputswitch-slider {
  width: 2.3125rem;
  height: 0.875rem;
}
.o-switch .p-inputswitch-slider::before {
  border: 1px solid #cccccc;
  box-shadow: 0px 3px 6px #00000029;
  left: 0;
}

/*-------------------------------------------------------------
	#INPUT TEXT
-----------------------------------------------------------------*/
.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
}

.o-inputtext__input {
  width: 100%;
  max-width: 16.25rem;
  height: 2.625rem;
  border-radius: 0;
  border-color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  color: #444444;
  padding: 0.5rem;
  outline: 0;
}
.o-inputtext__input.ng-dirty.ng-invalid {
  border-color: #cd3c14;
}
.o-inputtext__input.p-inputtext {
  border-width: 0.125rem;
  border-radius: 0;
}
.o-inputtext__input.p-inputtext:enabled:focus {
  border-color: #0f1efa;
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}
.o-inputtext__input.p-inputtext:hover:not([disabled]) {
  border-color: #0f1efa;
}
.o-inputtext__input + .p-error {
  color: #cd3c14;
}
.o-inputtext__input::placeholder {
  color: #444444;
}
.o-inputtext.p-float-label input {
  border-width: 0 0 0.0625rem;
  padding: 0.5rem 0;
}
.o-inputtext.p-float-label input:focus ~ label, .o-inputtext.p-float-label input.p-filled ~ label {
  font-size: 0.75rem;
  color: #0f1efa;
  background-color: white;
  top: 0.125rem;
}
.o-inputtext.p-float-label input.p-inputtext:enabled:focus {
  box-shadow: none;
}
.o-inputtext.p-float-label input.ng-dirty.ng-invalid {
  border-color: #cd3c14;
}
.o-inputtext.p-float-label > .ng-invalid.ng-dirty + label {
  color: #cd3c14;
}
.o-inputtext.p-float-label > label {
  top: 1.4375rem;
  left: 0;
}
.o-inputtext.p-float-label .p-button {
  width: 6.75rem;
  height: 2.5rem;
  border-radius: 0;
  color: white;
  background-color: #d73e0f;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem;
}
.o-inputtext.p-float-label .p-error {
  margin-top: 0.375rem;
}
.o-inputtext > label {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-size: 1rem;
}
.o-inputtext .p-error {
  display: block;
  color: #cd3c14;
  font-size: 0.75rem;
}
.o-inputtext__label {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.o-inputtext--mb-small {
  margin-bottom: 1.6875rem;
}
.o-inputtext--rounded .o-inputtext__input {
  border-radius: 0.625rem;
}
.o-inputtext--inline .o-inputtext__label {
  display: block;
}

.p-error {
  color: #cd3c14;
}

@media screen and (min-width: 768px) {
  .o-inputtext__input--block {
    display: block;
    max-width: none;
  }
}
/*-------------------------------------------------------------
	#INPUT NUMBER
-----------------------------------------------------------------*/
.o-inputnumber__input .p-inputnumber-input {
  border-radius: 0;
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  padding: 0.5rem 0.9375rem;
  border-color: #444444;
  width: 100%;
}
.o-inputnumber__input .p-inputnumber-input:disabled {
  background-color: #f1f1f1;
}
.o-inputnumber__input .p-inputnumber-input.ng-invalid.ng-dirty {
  border-color: #cd3c14;
}
.o-inputnumber__input .p-inputnumber-input:hover:not([disabled]) {
  border-color: #0f1efa;
}
.o-inputnumber__input .p-inputnumber-input.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
  border-color: #0f1efa;
}
.o-inputnumber__label {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}
.o-inputnumber.p-float-label {
  display: block;
}
.o-inputnumber.p-float-label .p-inputnumber {
  width: 100%;
}
.o-inputnumber.p-float-label input {
  border-width: 0 0 0.0625rem;
  padding: 0.5rem 0;
  width: 100%;
  max-width: 16.25rem;
  height: 2.625rem;
  border-radius: 0;
  border-color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  color: #444444;
  padding: 0.5rem 0;
}
.o-inputnumber.p-float-label input.p-inputtext:enabled:focus {
  box-shadow: none;
}
.o-inputnumber.p-float-label .p-button {
  width: 6.75rem;
  height: 2.5rem;
  border-radius: 0;
  color: white;
  background-color: #d73e0f;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem;
}
.o-inputnumber.p-float-label > label {
  color: #444444;
  font-size: 1rem;
  top: 1.4375rem;
  left: 0;
}
.o-inputnumber.p-float-label input:focus ~ label,
.o-inputnumber.p-float-label input.p-filled ~ label,
.o-inputnumber.p-float-label .p-inputwrapper-focus ~ label,
.o-inputnumber.p-float-label .p-inputwrapper-filled ~ label {
  color: #0f1efa;
  font-size: 0.75rem;
  left: 0;
  top: 0.125rem;
}
.o-inputnumber.p-float-label.ng-invalid {
  border-color: #cd3c14;
}
.o-inputnumber.p-float-label > .ng-invalid.ng-dirty + label {
  color: #cd3c14;
}
.o-inputnumber.p-float-label .p-error {
  margin-top: 0.375rem;
}
.o-inputnumber .p-error {
  display: block;
  color: #cd3c14;
  font-size: 0.75rem;
}
.o-inputnumber--mb-small {
  margin-bottom: 1.6875rem;
}
.o-inputnumber--inline.p-float-label {
  display: inline;
}
.o-inputnumber--inline-block.p-float-label {
  display: inline-block;
  width: auto;
}
.o-inputnumber--rounded .o-inputnumber__input .p-inputnumber-input {
  border-radius: 0.625rem;
}

/*-------------------------------------------------------------
	#PASSWORD
-----------------------------------------------------------------*/
.o-password .p-inputtext {
  width: 100%;
  max-width: 17.5rem;
  height: 2.5rem;
  border-radius: 0;
  border-width: 0 0 0.0625rem;
  border-color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 2.25rem;
}
.o-password .p-inputtext.p-filled ~ label, .o-password .p-inputtext:focus ~ label {
  color: #d73e0f;
  background-color: white;
  top: 0.125rem;
}
.o-password label {
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
}
.o-password__strength {
  display: inline-block;
}
.o-password__strength--verystrong {
  color: #005300;
}
.o-password__strength--strong {
  color: #14e003;
}
.o-password__strength--medium {
  color: #fecc32;
}
.o-password__strength--weak {
  color: #e00303;
}
.o-password__strength--right {
  position: absolute;
  margin-left: 1rem;
  bottom: 0;
  right: 0;
}
.o-password__strength--right .o-password__strength-txt {
  display: block;
  line-height: 0.875rem;
  margin-bottom: 0.3125rem;
}
.o-password__strength--right .o-password__strength-bar {
  max-width: 4.6875rem;
  display: block;
}
.o-password__strength-txt {
  font-size: 0.875rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: block;
  line-height: 0.625rem;
}
.o-password__strength-bar {
  display: none;
}
.o-password--mb-small {
  margin-bottom: 1.6875rem;
}
.o-password.p-inputtext {
  width: 100%;
  max-width: 17.5rem;
  height: 2.5rem;
  border-radius: 0;
  border-color: #cccccc;
  font-family: Arial, Helvetica, sans-serif;
}
.o-password.p-inputtext.p-filled ~ label, .o-password.p-inputtext:focus ~ label {
  color: #d73e0f;
  background-color: white;
  top: 0.125rem;
}
.o-password.p-float-label input {
  border-width: 0 0 0.0625rem;
  padding: 0.5rem 0;
}
.o-password.p-float-label input:focus ~ label, .o-password.p-float-label input.p-filled ~ label {
  font-size: 0.75rem;
  color: #0f1efa;
  background-color: white;
  top: 0.125rem;
}
.o-password.p-float-label input.p-inputtext:enabled:focus {
  box-shadow: none;
}
.o-password.p-float-label input.ng-invalid {
  border-color: #cd3c14;
}
.o-password.p-float-label > .ng-invalid.ng-dirty + label {
  color: #cd3c14;
}
.o-password.p-float-label > label {
  top: 1.4375rem;
  left: 0;
}
.o-password.p-float-label .p-inputtext {
  width: 100%;
  max-width: none;
}
.o-password.p-float-label .p-error {
  display: block;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.o-password--tooltip .p-inputtext {
  padding-right: 2.125rem;
}
.o-password--tooltip .iris-tooltip {
  display: none;
  left: 14.6875rem;
}

.p-password-info {
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 768px) {
  .o-password .p-inputtext {
    max-width: 16.25rem;
  }
  .o-password__strength--verystrong .o-password__strength-bar:after {
    width: 100%;
    background-color: #005300;
  }
  .o-password__strength--strong .o-password__strength-bar:after {
    width: 100%;
    background-color: #14e003;
  }
  .o-password__strength--medium .o-password__strength-bar:after {
    width: 50%;
    background-color: #fecc32;
  }
  .o-password__strength--weak .o-password__strength-bar:after {
    width: 25%;
    background-color: #e00303;
  }
  .o-password__strength--right {
    right: auto;
  }
  .o-password__strength-bar {
    width: 10rem;
    height: 0.3125rem;
    display: inline-block;
    position: relative;
    background-color: #444444;
  }
  .o-password__strength-bar:after {
    content: "";
    height: 0.3125rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .o-password.p-float-label .p-inputtext {
    max-width: 16.25rem;
  }
  .o-password--tooltip .iris-tooltip {
    display: block;
  }
}
/*-------------------------------------------------------------
    #OVERLAY PANEL
-----------------------------------------------------------------*/
.iris-overlay.p-overlaypanel {
  border-radius: 0;
  opacity: 1;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.iris-overlay.p-overlaypanel.p-overlaypanel-flipped::before, .iris-overlay.p-overlaypanel.p-overlaypanel-flipped::after {
  border-top-color: #444444;
}
.iris-overlay.p-overlaypanel:before, .iris-overlay.p-overlaypanel:after {
  border-bottom-color: #444444;
}
.iris-overlay.p-overlaypanel .p-overlaypanel-content {
  background: #444444;
  position: relative;
  overflow: hidden;
}
.iris-overlay.p-overlaypanel .o-overlay {
  width: 15rem;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.iris-overlay.p-overlaypanel .o-overlay .p-overlaypanel-content {
  padding: 0;
}
.iris-overlay.p-overlaypanel .o-overlay__content {
  text-align: left;
}
.iris-overlay.p-overlaypanel .o-overlay__main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iris-overlay.p-overlaypanel .o-overlay__main-section:last-child {
  border-bottom: 0;
}
.iris-overlay.p-overlaypanel .o-overlay__main-section:not(:last-child) {
  margin-bottom: 0.5rem;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8125rem;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link:hover, .iris-overlay.p-overlaypanel .o-overlay__main-link:focus {
  text-decoration: none;
  color: gold;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link:hover::after, .iris-overlay.p-overlaypanel .o-overlay__main-link:focus::after {
  color: gold;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link::after {
  content: "";
  font-family: "primeicons";
  color: white;
  font-size: 0.75rem;
  float: right;
  line-height: 2rem;
  margin-left: 1.25rem;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link--mb-none {
  margin-bottom: 0;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link--no-icon::after {
  content: none;
}
.iris-overlay.p-overlaypanel .o-overlay__main-link-icon {
  font-size: 0.75rem;
}
.iris-overlay.p-overlaypanel .o-overlay__main-info {
  font-size: 0.875rem;
  color: #cccccc;
  display: flex;
  justify-content: space-between;
  margin-right: 1.1875rem;
  line-height: 1.1875rem;
  margin-bottom: 0.4375rem;
}
.iris-overlay.p-overlaypanel .o-overlay__main-client-name {
  padding-bottom: 0.4375rem;
  text-align: center;
  color: #cccccc;
  font-family: Arial, Helvetica, sans-serif;
}
.iris-overlay.p-overlaypanel .o-overlay__main-client-name span:first-child {
  margin-right: 0.1875rem;
}
.iris-overlay.p-overlaypanel .o-overlay__main-client-name span:last-child {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-overlay--medium.p-overlaypanel {
  min-width: 15rem;
}

@media screen and (min-width: 768px) {
  .o-overlay__main-section--responsive {
    display: none;
  }
}
/*-------------------------------------------------------------
	#PASSWORD
-----------------------------------------------------------------*/
.o-password .p-inputtext {
  width: 100%;
  max-width: 17.5rem;
  height: 2.5rem;
  border-radius: 0;
  border-width: 0 0 0.0625rem;
  border-color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 2.25rem;
}
.o-password .p-inputtext.p-filled ~ label, .o-password .p-inputtext:focus ~ label {
  color: #d73e0f;
  background-color: white;
  top: 0.125rem;
}
.o-password label {
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
}
.o-password__strength {
  display: inline-block;
}
.o-password__strength--verystrong {
  color: #005300;
}
.o-password__strength--strong {
  color: #14e003;
}
.o-password__strength--medium {
  color: #fecc32;
}
.o-password__strength--weak {
  color: #e00303;
}
.o-password__strength--right {
  position: absolute;
  margin-left: 1rem;
  bottom: 0;
  right: 0;
}
.o-password__strength--right .o-password__strength-txt {
  display: block;
  line-height: 0.875rem;
  margin-bottom: 0.3125rem;
}
.o-password__strength--right .o-password__strength-bar {
  max-width: 4.6875rem;
  display: block;
}
.o-password__strength-txt {
  font-size: 0.875rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: block;
  line-height: 0.625rem;
}
.o-password__strength-bar {
  display: none;
}
.o-password--mb-small {
  margin-bottom: 1.6875rem;
}
.o-password.p-inputtext {
  width: 100%;
  max-width: 17.5rem;
  height: 2.5rem;
  border-radius: 0;
  border-color: #cccccc;
  font-family: Arial, Helvetica, sans-serif;
}
.o-password.p-inputtext.p-filled ~ label, .o-password.p-inputtext:focus ~ label {
  color: #d73e0f;
  background-color: white;
  top: 0.125rem;
}
.o-password.p-float-label input {
  border-width: 0 0 0.0625rem;
  padding: 0.5rem 0;
}
.o-password.p-float-label input:focus ~ label, .o-password.p-float-label input.p-filled ~ label {
  font-size: 0.75rem;
  color: #0f1efa;
  background-color: white;
  top: 0.125rem;
}
.o-password.p-float-label input.p-inputtext:enabled:focus {
  box-shadow: none;
}
.o-password.p-float-label input.ng-invalid {
  border-color: #cd3c14;
}
.o-password.p-float-label > .ng-invalid.ng-dirty + label {
  color: #cd3c14;
}
.o-password.p-float-label > label {
  top: 1.4375rem;
  left: 0;
}
.o-password.p-float-label .p-inputtext {
  width: 100%;
  max-width: none;
}
.o-password.p-float-label .p-error {
  display: block;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.o-password--tooltip .p-inputtext {
  padding-right: 2.125rem;
}
.o-password--tooltip .iris-tooltip {
  display: none;
  left: 14.6875rem;
}

.p-password-info {
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 768px) {
  .o-password .p-inputtext {
    max-width: 16.25rem;
  }
  .o-password__strength--verystrong .o-password__strength-bar:after {
    width: 100%;
    background-color: #005300;
  }
  .o-password__strength--strong .o-password__strength-bar:after {
    width: 100%;
    background-color: #14e003;
  }
  .o-password__strength--medium .o-password__strength-bar:after {
    width: 50%;
    background-color: #fecc32;
  }
  .o-password__strength--weak .o-password__strength-bar:after {
    width: 25%;
    background-color: #e00303;
  }
  .o-password__strength--right {
    right: auto;
  }
  .o-password__strength-bar {
    width: 10rem;
    height: 0.3125rem;
    display: inline-block;
    position: relative;
    background-color: #444444;
  }
  .o-password__strength-bar:after {
    content: "";
    height: 0.3125rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .o-password.p-float-label .p-inputtext {
    max-width: 16.25rem;
  }
  .o-password--tooltip .iris-tooltip {
    display: block;
  }
}
/*-------------------------------------------------------------
	#LINK
-----------------------------------------------------------------*/
.o-link {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.o-link--back .icon-chevron-light-left {
  color: #d73e0f;
  font-size: 0.6875rem;
  margin-right: 0.8125rem;
}
.o-link--back:hover {
  text-decoration: none;
}
.o-link--back:hover .o-link__text {
  text-decoration: underline;
  color: #d73e0f;
}

/*-------------------------------------------------------------
	#PROGRESSBAR
-----------------------------------------------------------------*/
.o-progressbar .p-progressbar {
  height: 0.625rem;
  border-radius: 0;
  background-color: #444444;
}
.o-progressbar .p-progressbar-value {
  background-color: gold;
}
.o-progressbar--completed .p-progressbar-value {
  background-color: #cd3c14;
}

/*-------------------------------------------------------------
    #check
-----------------------------------------------------------------*/
.o-check {
  margin-right: 0.375rem;
}
.o-check .p-checkbox-box {
  border-radius: 0;
  border: 0.125rem solid #cccccc;
  width: 1.25rem;
  height: 1.25rem;
}
.o-check .p-checkbox-box.p-highlight {
  background-color: #0f1efa;
  border-color: #0f1efa;
}
.o-check .p-checkbox-box .p-checkbox-icon {
  color: white;
}
.o-check.p-checkbox {
  width: 1.25rem;
  height: 1.25rem;
}
.o-check.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #0f1efa;
  background: #0f1efa;
}
.o-check.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #0f1efa;
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}
.o-check.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #0f1efa;
}
.o-check__label a {
  color: black;
  text-decoration: underline;
}
.o-check__label a:hover {
  text-decoration: none;
}
.o-check__label--inline {
  display: inline;
}

/*-------------------------------------------------------------
    #DROPDOWN
-----------------------------------------------------------------*/
.o-dropdown .p-dropdown {
  border-radius: 0;
  border-width: 0.125rem;
  border-color: #444444;
  width: 100%;
  max-width: 16.25rem;
  height: 2.625rem;
}
.o-dropdown .p-dropdown-label {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4375rem;
}
.o-dropdown .p-dropdown-panel {
  border-radius: 0;
}
.o-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: black;
}
.o-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #d73e0f;
  color: white;
}
.o-dropdown .p-dropdown-trigger-icon {
  color: black;
  font-size: 0.75rem;
}
.o-dropdown .p-dropdown-items-wrapper {
  font-family: Arial, Helvetica, sans-serif;
}
.o-dropdown__label {
  display: block;
  font-size: 1rem;
  color: #444444;
}
.o-dropdown__label--inline {
  display: inline-block;
  margin-right: 0.5rem;
}

/*-------------------------------------------------------------
	#SHADOW WRAPPER
-----------------------------------------------------------------*/
.o-shadow-wrapper {
  overflow: hidden;
}
.o-shadow-wrapper__shadow {
  position: relative;
}
.o-shadow-wrapper__shadow:before, .o-shadow-wrapper__shadow:after {
  content: "";
  position: absolute;
  background-color: gold;
  z-index: -1;
}
.o-shadow-wrapper__shadow:before {
  top: 50vw;
  left: 100%;
  width: 100vw;
  height: calc(100% + 1px);
  transform: skewY(45deg);
}
.o-shadow-wrapper__shadow:after {
  top: 100%;
  left: 50vh;
  width: 100%;
  height: 100vh;
  transform: skewx(45deg);
}
.o-shadow-wrapper__height-short {
  min-height: calc(100vh - 683px);
}
.o-shadow-wrapper__height-medium {
  min-height: calc(100vh - 806px);
}
.o-shadow-wrapper__height-large {
  min-height: calc(100vh - 263px);
}
.o-shadow-wrapper__height-xlarge {
  min-height: calc(100vh - 113px);
}

@media screen and (min-width: 768px) {
  .o-shadow-wrapper__height-short {
    min-height: calc(100vh - 628px);
  }
  .o-shadow-wrapper__height-medium {
    min-height: calc(100vh - 747px);
  }
  .o-shadow-wrapper__height-large {
    min-height: calc(100vh - 233px);
  }
  .o-shadow-wrapper__height-xlarge {
    min-height: calc(100vh - 113px);
  }
}
/*-------------------------------------------------------------
	#RADIOBUTTON
-----------------------------------------------------------------*/
.o-radiobutton.p-radiobutton .p-radiobutton-box {
  border: 0.125rem solid #cccccc;
}
.o-radiobutton.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #0f1efa;
  background: #0f1efa;
}
.o-radiobutton.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #0f1efa;
}
.o-radiobutton.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0f1efa;
  background: #0f1efa;
}
.o-radiobutton.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}
.o-radiobutton.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: white;
}

.iris .o-radiobutton__label {
  margin-left: 0.625rem;
}
.iris .o-radiobutton--block {
  display: block;
}
.iris .o-radiobutton--mb {
  margin-bottom: 1rem;
}
.iris .o-radiobutton--mb-big {
  margin-bottom: 1.6875rem;
}
.iris .o-radiobutton .p-radiobutton .p-radiobutton-box {
  border: 0.125rem solid #cccccc;
}
.iris .o-radiobutton .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #0f1efa;
  background: #0f1efa;
}
.iris .o-radiobutton .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #0f1efa;
}
.iris .o-radiobutton .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0f1efa;
  background: #0f1efa;
}
.iris .o-radiobutton .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}
.iris .o-radiobutton .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: white;
}

/*-------------------------------------------------------------
    #SLIDER
-----------------------------------------------------------------*/
.o-slider .p-slider.p-slider-horizontal {
  height: 0.375rem;
}
.o-slider .p-slider .p-slider-range {
  background-color: #d73e0f;
}
.o-slider .p-slider .p-slider-handle {
  border-color: black;
  width: 1.25rem;
  height: 1.25rem;
}
.o-slider .p-slider .p-slider-handle:focus {
  box-shadow: none;
}
.o-slider .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background-color: white;
  border-color: black;
}

/*-------------------------------------------------------------
    #BOX
-----------------------------------------------------------------*/
.o-box {
  height: 28.5rem;
  position: relative;
  overflow: hidden;
}
.o-box:before {
  content: "";
  position: absolute;
  top: -2.0625rem;
  left: -2.0625rem;
  width: 4.0625rem;
  height: 4.0625rem;
  transform: rotate(45deg);
  background-color: #d73e0f;
}
.o-box figure {
  width: 100%;
  height: 100%;
}
.o-box figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  display: block;
}

.iris-header {
  color: white;
  padding-top: 1.6875rem;
}
.iris-header__nav {
  padding: 0.625rem 0.1875rem 0;
}
.iris-header__list {
  display: flex;
  justify-content: flex-end;
}
.iris-header__item {
  font-size: 0.875rem;
  color: white;
  font-family: Arial;
}
.iris-header__item:not(:last-child) {
  margin-right: 1.8125rem;
}
.iris-header__item--desktop {
  display: none;
}
.iris-header__item .icon-chevron-light-down {
  font-size: 0.75rem;
  margin-left: 0.25rem;
  vertical-align: middle;
}
.iris-header__link {
  color: white;
}
.iris-header__link:hover {
  color: white;
}
.iris-header__btn {
  color: white;
}
.iris-header__logo {
  background-repeat: no-repeat;
  width: 6.6875rem;
  height: 3.4375rem;
  display: block;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .iris-header {
    padding-top: 0;
  }
  .iris-header__logo {
    width: 11.6875rem;
    height: 5.6875rem;
  }
}
.iris-main {
  width: 100%;
  background-color: white;
}
.iris-main__container {
  padding: 2.125rem 1.375rem;
}
.iris-main__title {
  font-size: 1.875rem;
  line-height: 2rem;
  margin-bottom: 1.6875rem;
}
.iris-main__txt {
  line-height: 1.25rem;
  color: #444444;
}
.iris-main__txt--mb-small {
  margin-bottom: 1.875rem;
}
.iris-main__success-txt {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.8125rem;
  color: black;
  margin-bottom: 0.4375rem;
}
.iris-main__success-subtxt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #444444;
}
.iris-main__success-subtxt a {
  color: #444444;
  text-decoration: underline;
}
.iris-main__wrapper {
  width: 100%;
  margin-bottom: 1.8125rem;
}
.iris-main__wrapper .p-float-label.o-inputnumber .p-inputnumber-input {
  width: 100%;
  max-width: none;
}
.iris-main__wrapper .p-float-label.o-inputtext .o-inputtext__input {
  width: 100%;
  max-width: none;
}
.iris-main__wrapper .p-float-label.o-password .p-inputtext {
  width: 100%;
  max-width: none;
  padding-right: 5.875rem;
}
.iris-main__wrapper .p-float-label label {
  top: 1.25rem;
}
.iris-main__wrapper .o-inputnumber {
  width: 100%;
}
.iris-main__wrapper .o-inputnumber--inline-block {
  width: 100%;
  padding-right: 0;
}
.iris-main__wrapper .o-inputnumber__input {
  width: 100%;
}
.iris-main__wrapper .o-inputnumber__input .p-inputnumber {
  height: 2.5rem;
  border-radius: 0;
}
.iris-main__wrapper .o-inputtext {
  width: 100%;
}
.iris-main__wrapper .o-inputtext--inline-block {
  width: auto;
  padding-right: 1.5625rem;
}
.iris-main__wrapper .o-inputtext__input.o-inputtext__input--small {
  width: 13.5625rem;
  height: 2.5rem;
  border-radius: 0;
}
.iris-main__wrapper .o-password {
  position: relative;
}
.iris-main__wrapper .o-password__strength {
  vertical-align: middle;
  margin-left: 1.5625rem;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 0;
}
.iris-main__wrapper .p-error {
  display: block;
  color: #cd3c14;
  font-size: 0.75rem;
  margin-left: 0.1875rem;
}
.iris-main__wrapper .o-btn--block-auto {
  width: 100%;
}
.iris-main__wrapper--mb-medium {
  margin-bottom: 1.375rem;
}
.iris-main__icon-big {
  font-size: 6.25rem;
  display: block;
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}
.iris-main__link {
  color: #444444;
  text-decoration: underline;
  font-size: 0.875rem;
  margin-top: 0.75rem;
}
.iris-main__link:hover {
  color: #444444;
}
.iris-main__btn-clear {
  position: absolute;
  top: 1.25rem;
  left: 11.875rem;
  transform: translateY(-50%);
}
.iris-main__btn.p-button {
  background-color: #0f1efa !important;
  border-color: #0f1efa !important;
  width: 100% !important;
  margin-top: 0.3125rem;
}
.iris-main__btn.p-button:hover {
  color: #0f1efa !important;
  background-color: gold !important;
  border-color: gold !important;
}
.iris-main .iris-tooltip {
  display: none;
  left: 14.6875rem;
}

@media screen and (min-width: 768px) {
  .iris-main__txt--mb-small {
    margin-bottom: 2.5rem;
  }
  .iris-main__link {
    margin-top: 0.375rem;
  }
  .iris-main__wrapper .p-float-label.o-inputnumber .p-inputnumber-input {
    max-width: 16.25rem;
  }
  .iris-main__wrapper .p-float-label.o-inputtext .o-inputtext__input {
    max-width: 16.25rem;
  }
  .iris-main__wrapper .p-float-label.o-password .p-inputtext {
    max-width: 16.25rem;
    padding-right: 2.125rem;
  }
  .iris-main__wrapper .o-inputnumber--inline-block {
    width: 16.25rem;
  }
  .iris-main__wrapper .o-password__strength {
    margin-left: 1.375rem;
    right: auto;
  }
  .iris-main__wrapper .o-btn--block-auto {
    width: auto;
  }
  .iris-main__btn.p-button {
    width: 6.75rem !important;
  }
  .iris-main .iris-tooltip {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .iris-main__container {
    margin-left: auto;
    margin-right: auto;
    padding: 2.1875rem 1.5625rem;
  }
  .iris-main__title {
    font-size: 1.875rem;
    line-height: 1.25rem;
  }
}
.iris-footer {
  background-color: white;
  color: #d73e0f;
  padding: 0.1875rem 0;
  padding-bottom: 3.25rem;
}
.iris-footer__col {
  line-height: 1rem;
}
.iris-footer__col:first-child {
  padding-left: 8.6875rem;
}
.iris-footer__col:last-child {
  padding-right: 8.6875rem;
}
.iris-footer__txt {
  display: inline-block;
  font-size: 0.875rem;
  margin-right: 1.25rem;
}
.iris-footer__txt a {
  color: #d73e0f;
}
.iris-footer__txt:last-child {
  margin-right: 0;
}
.iris-footer .t-display-flex {
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .iris-footer {
    padding-bottom: 0.1875rem;
  }
  .iris-footer .t-display-flex {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 992px) {
  .iris-footer .t-display-flex {
    flex-wrap: nowrap;
  }
}
.iris-steps__list {
  display: flex;
}
.iris-steps__step-title {
  display: none;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
}
.iris-steps__step {
  display: flex;
  margin-right: 1.75rem;
  align-items: center;
}
.iris-steps__step--completed .iris-steps__step-order {
  color: black;
  margin-right: 2.1875rem;
}
.iris-steps__step--completed .iris-steps__step-order:after {
  background-color: gold;
}
.iris-steps__step--progress {
  margin-right: 2.1875rem;
}
.iris-steps__step--progress .iris-steps__step-title {
  display: inline;
}
.iris-steps__step-order {
  color: white;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  line-height: 1.5rem;
  position: relative;
  z-index: 0;
}
.iris-steps__step-order:first-child {
  margin-left: 0.9375rem;
}
.iris-steps__step-order:after {
  content: "";
  position: absolute;
  top: -0.4375rem;
  left: -15px;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #444444;
  z-index: -1;
}
.iris-steps--dark-background .iris-steps__step-title {
  color: white;
}

@media screen and (min-width: 768px) {
  .iris-steps__step {
    margin-right: 2.1875rem;
  }
}
@media screen and (min-width: 992px) {
  .iris-steps__list {
    display: block;
  }
  .iris-steps__step {
    display: inline-block;
    margin-right: 3.125rem;
    margin-bottom: 0.875rem;
  }
  .iris-steps__step--completed .iris-steps__step-order {
    margin-right: 1.6875rem;
  }
  .iris-steps__step-title {
    display: inline;
    font-size: 1.25rem;
  }
  .iris-steps__step-order {
    margin-right: 1.6875rem;
  }
}
.iris-login {
  background-color: white;
  padding: 2.0625rem 1.875rem 1.875rem;
}
.iris-login__card {
  color: black;
}
.iris-login__main-title {
  font-size: 1.875rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0f1efa;
  margin-bottom: 1.0625rem;
  display: block;
}
.iris-login__main-description {
  line-height: 1.125rem;
}
.iris-login__main .comment-box {
  line-height: 1.125rem;
}
.iris-login__main-comment {
  color: #666666;
  font-size: 0.875rem;
  padding: 1.375rem;
  font-style: italic;
  display: block;
  padding-top: 0;
}
.iris-login__main-inputbox {
  padding: 1.5625rem 0 1.75rem;
  position: relative;
}
.iris-login__main-inputbox .o-inputtext,
.iris-login__main-inputbox .o-inputtext__input,
.iris-login__main-inputbox .p-inputmask,
.iris-login__main-inputbox .o-password {
  width: 100%;
  max-width: none;
}
.iris-login__main-inputbox .o-inputtext.second-input,
.iris-login__main-inputbox .o-inputtext__input.second-input,
.iris-login__main-inputbox .p-inputmask.second-input,
.iris-login__main-inputbox .o-password.second-input {
  margin-top: 1.5rem;
}
.iris-login__main-inputbox .o-inputtext.p-inputtext,
.iris-login__main-inputbox .o-inputtext__input.p-inputtext,
.iris-login__main-inputbox .p-inputmask.p-inputtext,
.iris-login__main-inputbox .o-password.p-inputtext {
  max-width: none;
}
.iris-login__main-inputbox .o-inputtext::placeholder,
.iris-login__main-inputbox .o-inputtext__input::placeholder,
.iris-login__main-inputbox .p-inputmask::placeholder,
.iris-login__main-inputbox .o-password::placeholder {
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.iris-login__main-inputbox .o-inputtext.p-float-label .o-password {
  padding-right: 5.75rem;
  padding-bottom: 0;
}
.iris-login__main-inputbox .p-inputmask {
  text-align: center;
  font-size: 1.5rem;
  color: #666666;
}
.iris-login__main-inputbox .p-inputmask::placeholder {
  font-style: normal;
  font-size: 2rem;
}
.iris-login__main-inputbox .p-inputmask--small .p-inputmask {
  font-size: 1rem;
}
.iris-login__main-inputbox .p-inputmask--small .p-inputmask::placeholder {
  font-size: 1rem;
}
.iris-login__main-inputbox .o-password__strength-bar {
  border-radius: 0;
}
.iris-login__main .p-field-checkbox {
  margin-bottom: 1.875rem;
}
.iris-login__main .p-field-checkbox label {
  margin-left: 0.625rem;
}
.iris-login__main-buttonbox {
  padding: 0 0 1.25rem 0;
}
.iris-login__main-buttonbox button {
  width: 100%;
}
.iris-login__main-buttonbox button:disabled {
  background: transparent linear-gradient(102deg, #939393 0%, #C1C5C7 100%) 0% 0% no-repeat padding-box;
  color: white;
}
.iris-login__main-tooltip.iris-tooltip {
  left: auto;
  right: 0.625rem;
  top: 0.625rem;
  display: none;
}
.iris-login__footer-back {
  color: black;
  font-weight: bold;
}
.iris-login__footer-back::before {
  content: "";
  font-family: "primeicons";
  color: #d73e0f;
  font-size: 0.75rem;
  line-height: 2rem;
  margin-right: 0.375rem;
  font-weight: normal;
}
.iris-login__footer-back:hover, .iris-login__footer-back:focus {
  text-decoration: none;
}
.iris-login__footer-back:hover {
  color: black;
}

@media screen and (min-width: 768px) {
  .iris-login__main-inputbox .o-inputtext,
.iris-login__main-inputbox .o-inputtext__input,
.iris-login__main-inputbox .p-inputmask,
.iris-login__main-inputbox .o-password {
    width: 100%;
    max-width: 16.25rem;
  }
  .iris-login__main-inputbox .o-inputtext.p-float-label .o-password {
    padding-right: 2.125rem;
  }
  .iris-login__main-inputbox .iris-tooltip__text {
    display: block;
  }
  .iris-login__main-buttonbox button {
    max-width: 16.25rem;
  }
  .iris-login__main-tooltip.iris-tooltip {
    display: block;
  }
}
.iris-tables {
  min-width: 58.75rem;
}
.iris-tables .p-datatable {
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
  padding: 0.5rem 1.9375rem 1.9375rem;
}
.iris-tables .p-datatable th,
.iris-tables .p-datatable td {
  width: auto;
  font-size: 0.875rem;
}
.iris-tables .p-datatable .p-datatable-wrapper {
  margin: 1rem 1rem;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-thead {
  border-bottom: 0.0625rem solid black;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-thead tr > th {
  color: black;
  padding: 0.75rem 1rem;
  border: none;
  background: white;
  padding-left: 0;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-thead tr > th:nth-child(1), .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-thead tr > th:nth-child(3) {
  display: none;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr {
  border-bottom: 0.0625rem solid #eeeeee;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td {
  color: black;
  border: none;
  color: #444444;
  padding: 0.75rem 1rem;
  padding-left: 0;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td:nth-child(1), .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td:nth-child(3) {
  display: none;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td .p-button-label {
  display: none;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td.error {
  color: #c30c0c;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td.success {
  color: #32c832;
}
.iris-tables .p-datatable .p-paginator-bottom {
  border-bottom: 0;
  justify-content: flex-end;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-first, .iris-tables .p-datatable .p-paginator-bottom .p-paginator-last {
  display: none;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-prev, .iris-tables .p-datatable .p-paginator-bottom .p-paginator-next {
  border-radius: 0px;
  color: #444444;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-prev:hover, .iris-tables .p-datatable .p-paginator-bottom .p-paginator-prev:focus, .iris-tables .p-datatable .p-paginator-bottom .p-paginator-next:hover, .iris-tables .p-datatable .p-paginator-bottom .p-paginator-next:focus {
  color: black;
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-page {
  color: black;
  border-radius: 0px;
  min-width: 1.875rem;
  height: 1.875rem;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-page:hover {
  background: transparent;
  color: black;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-page.p-highlight {
  background: #444444;
  color: white;
}
.iris-tables .p-datatable .p-paginator-bottom .p-paginator-page:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 62, 15, 0.4);
}
.iris-tables .p-datatable .p-datatable-thead > tr > th {
  color: black;
  background-color: white;
  padding-bottom: 0.4375rem;
  border-color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-tables .p-button {
  color: #444444;
  background: white;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  line-height: 1.5rem;
  border-radius: 0;
}
.iris-tables .p-button:hover {
  color: #444444;
  background: white;
  border: none;
}
.iris-tables .p-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 62, 15, 0.4);
}
.iris-tables .p-button .p-button-icon-right {
  margin-left: 0.25rem;
  font-size: 0.6875rem;
}
.iris-tables .p-button .p-button-icon-right::before {
  vertical-align: sub;
}

@media screen and (min-width: 992px) {
  .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-thead > tr > th:nth-child(1), .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-thead > tr > th:nth-child(3) {
    display: table-cell;
  }
  .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td:nth-child(1), .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td:nth-child(3) {
    display: table-cell;
  }
  .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td:last-child {
    text-align: right;
  }
  .iris-tables .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr > td .p-button-label {
    display: block;
  }
  .iris-tables .p-datatable th,
.iris-tables .p-datatable td {
    width: 8.75rem;
    font-size: 1rem;
  }
}
.iris-invoice {
  width: 100%;
  background-color: white;
  padding: 1.9375rem 1.8125rem;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  overflow: hidden;
  height: 28.5rem;
  /* Header */
  /* Main */
  /* Footer */
  /* Empty data */
  /* Large component */
}
.iris-invoice:after {
  content: "";
  position: absolute;
  width: 4.0625rem;
  height: 4.0625rem;
  background-color: #d73e0f;
  bottom: -2.0625rem;
  right: -2.0625rem;
  transform: rotate(45deg);
}
.iris-invoice__header {
  margin-bottom: 0.6875rem;
}
.iris-invoice__header-icon {
  display: none;
}
.iris-invoice__header-title-txt {
  font-size: 1.875rem;
  color: #0f1efa;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
}
.iris-invoice__header-title-date {
  display: none;
}
.iris-invoice__main {
  display: flex;
  flex-wrap: wrap;
}
.iris-invoice__main-data {
  display: block;
  margin-bottom: 1.875rem;
  width: 100%;
}
.iris-invoice__main-data:nth-child(3) {
  margin-bottom: 1.25rem;
}
.iris-invoice__main-data-title {
  font-size: 1rem;
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.1875rem;
  margin-bottom: 0.25rem;
}
.iris-invoice__main-data-info {
  font-size: 1.5rem;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.1875rem;
}
.iris-invoice__main-data-info--primary {
  color: #d73e0f;
}
.iris-invoice__btn.o-btn {
  padding: 1rem 2.375rem;
}
.iris-invoice__footer {
  position: absolute;
  bottom: 1.9375rem;
}
.iris-invoice__footer-link {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  color: #444444;
  font-size: 1rem;
  line-height: 1.1875rem;
  justify-content: space-between;
}
.iris-invoice__footer-link:not(:last-child) {
  margin-bottom: 0.625rem;
}
.iris-invoice__footer-link:hover {
  color: #444444;
  text-decoration: none;
}
.iris-invoice__footer-link:hover a:first-child {
  text-decoration: underline;
}
.iris-invoice__footer-link:hover .icon-chevron-light-right {
  text-decoration: none;
}
.iris-invoice__footer-link .icon-chevron-light-right {
  color: #d73e0f;
  font-size: 0.6875rem;
  margin-left: 0.8125rem;
}
.iris-invoice__empty-data {
  height: 15.625rem;
}
.iris-invoice__empty-data h3 {
  color: black;
}
.iris-invoice--large {
  height: auto;
}
.iris-invoice--large:after {
  content: none;
}
.iris-invoice--large .iris-invoice {
  /* Footer */
}
.iris-invoice--large .iris-invoice__header-title-date {
  display: block;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.625rem;
  color: #444444;
}
.iris-invoice--large .iris-invoice__main > section {
  margin-bottom: 1.875rem;
}
.iris-invoice--large .iris-invoice__main > section:nth-child(1) {
  order: 1;
  width: 100%;
}
.iris-invoice--large .iris-invoice__main > section:nth-child(2) {
  order: 3;
  width: 50%;
}
.iris-invoice--large .iris-invoice__main > section:nth-child(3) {
  order: 2;
  width: 50%;
}
.iris-invoice--large .iris-invoice__main > section:nth-child(4) {
  display: block;
  order: 4;
}
.iris-invoice--large .iris-invoice__main-data {
  margin-bottom: 0;
}
.iris-invoice--large .iris-invoice__main-data:nth-child(1) {
  order: 1;
}
.iris-invoice--large .iris-invoice__main-data:nth-child(2) {
  order: 3;
  width: 50%;
}
.iris-invoice--large .iris-invoice__main-data:nth-child(3) {
  order: 2;
  width: 50%;
}
.iris-invoice--large .iris-invoice__main-data:nth-child(4) {
  display: block;
  order: 4;
  width: 100%;
}
.iris-invoice--large .iris-invoice__btn {
  width: 100%;
}
.iris-invoice--large .iris-invoice__footer {
  display: none;
}

@media screen and (min-width: 768px) {
  .iris-invoice {
    /* Large component */
  }
  .iris-invoice--large .iris-invoice__main > section:nth-child(3) {
    width: 35%;
  }
  .iris-invoice--large .iris-invoice__main-data:nth-child(3) {
    width: 35%;
  }
  .iris-invoice--large .iris-invoice__btn {
    width: auto;
  }
}
@media screen and (min-width: 992px) {
  .iris-invoice {
    /* Large component */
  }
  .iris-invoice--large {
    padding: 2.5625rem 2.4375rem;
    height: 16.875rem;
  }
  .iris-invoice--large:after {
    content: none;
  }
  .iris-invoice--large .iris-invoice {
    /* Header */
    /* Main */
  }
  .iris-invoice--large .iris-invoice__header {
    margin-bottom: 1.1875rem;
  }
  .iris-invoice--large .iris-invoice__header-icon {
    display: inline-block;
    font-size: 3.5rem;
    margin-right: 2rem;
  }
  .iris-invoice--large .iris-invoice__header-title {
    display: inline-block;
  }
  .iris-invoice--large .iris-invoice__header-title-txt {
    font-size: 1.875rem;
  }
  .iris-invoice--large .iris-invoice__main {
    justify-content: flex-start;
  }
  .iris-invoice--large .iris-invoice__main > section {
    width: auto;
    margin-bottom: 0;
    margin-right: 3.8125rem;
  }
  .iris-invoice--large .iris-invoice__main > section:nth-child(2) {
    order: 3;
    width: auto;
  }
  .iris-invoice--large .iris-invoice__main > section:nth-child(3) {
    order: 2;
    width: auto;
  }
  .iris-invoice--large .iris-invoice__main > section:nth-child(4) {
    width: auto;
  }
  .iris-invoice--large .iris-invoice__main-data {
    width: auto;
    margin-bottom: 0;
    margin-right: 3.8125rem;
  }
  .iris-invoice--large .iris-invoice__main-data:nth-child(2) {
    order: 3;
    width: auto;
  }
  .iris-invoice--large .iris-invoice__main-data:nth-child(3) {
    order: 2;
    width: auto;
  }
  .iris-invoice--large .iris-invoice__main-data-title {
    font-size: 1rem;
    color: #444444;
  }
  .iris-invoice--large .iris-invoice__main-data-info {
    font-size: 1.5rem;
    color: black;
  }
  .iris-invoice--large .iris-invoice__btn {
    margin-top: 1.5625rem;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .iris-invoice {
    /* Large component */
  }
  .iris-invoice--large .iris-invoice__main > section:nth-child(1) {
    width: auto;
  }
}
.iris-usage .p-card {
  position: relative;
  background-color: white;
  box-shadow: none;
  border-radius: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  height: 28.5rem;
}
.iris-usage .p-card .p-card-content {
  padding-bottom: 0;
  padding-top: 0.3125rem;
}
.iris-usage .p-card .p-card-content .p-d-flex:last-child {
  margin-top: 0.1875rem;
}
.iris-usage .p-card:after {
  content: "";
  position: absolute;
  width: 4.0625rem;
  height: 4.0625rem;
  background-color: #d73e0f;
  bottom: -2.0625rem;
  right: -2.0625rem;
  transform: rotate(45deg);
}
.iris-usage .p-card-title {
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.875rem;
  color: #0f1efa;
}
.iris-usage .p-card-body {
  padding: 1.9375rem 1.8125rem;
}
.iris-usage .p-text-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-usage .p-knob-text {
  fill: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-usage .p-d-flex.p-flex-column {
  margin-top: 0.3125rem;
}
.iris-usage__link {
  color: #444444;
  text-decoration: none;
}
.iris-usage__link:hover {
  text-decoration: none;
  color: #444444;
}
.iris-usage__link:hover > div {
  text-decoration: underline;
}
.iris-usage__link > div {
  display: inline-block;
}
.iris-usage__icon {
  font-size: 0.6875rem;
  color: #d73e0f;
  margin-left: 0.75rem;
}
.iris-usage__alert {
  background-color: gold;
  color: black;
  position: absolute;
  top: -1.125rem;
  left: 0;
  width: 100%;
  padding: 0.375rem 0.9375rem;
  font-size: 0.875rem;
}
.iris-usage__alert strong {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-weight: normal;
  margin-right: 0.25rem;
}
.iris-usage__alert-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}
.iris-usage__alert-icon .path1 {
  font-size: 1.6875rem;
}
.iris-usage__alert-icon .path1:before {
  color: black;
}
.iris-usage__alert-icon .path2 {
  font-size: 1.6875rem;
}
.iris-usage__alert-icon .path2:before {
  color: gold;
}
.iris-usage__alert-link {
  color: black;
  font-size: 0.875rem;
  text-decoration: underline;
  font-family: Arial, Helvetica, sans-serif;
}
.iris-usage__alert-link:hover {
  color: black;
}

.iris-products {
  font-family: Arial, Helvetica, sans-serif;
}
.iris-products__list {
  position: relative;
}
.iris-products__item {
  background-color: white;
  padding: 1.6875rem 2.9375rem 2.4375rem 1.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.iris-products__item:not(:last-child) {
  border-bottom: 0.0625rem solid #444444;
}
.iris-products__details {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.0625rem;
  border-bottom: 0.125rem solid #cccccc;
}
.iris-products__details-tariff {
  width: 100%;
  margin-bottom: 1.5625rem;
}
.iris-products__details-tariff-number {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  color: #444444;
  margin-bottom: 0.25rem;
}
.iris-products__details-tariff-name {
  font-size: 1.5rem;
  line-height: 1.375rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  display: block;
  color: #0f1efa;
}
.iris-products__details-info {
  display: flex;
  margin-bottom: 1.375rem;
  width: 100%;
}
.iris-products__details-info-block:first-child {
  margin-right: 3.5rem;
}
.iris-products__details-info-block:last-child {
  margin-right: 0;
}
.iris-products__details-info-title {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
  display: block;
  margin-bottom: 0.1875rem;
}
.iris-products__details-info-data {
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: block;
  color: black;
}
.iris-products__links {
  margin-top: 0.5625rem;
}
.iris-products__link {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
  display: block;
  line-height: 1.625rem;
}
.iris-products__link:hover {
  color: #444444;
}
.iris-products__link span:first-child {
  font-weight: bold;
}
.iris-products__link-icon {
  font-size: 0.75rem;
  margin-left: 0.75rem;
}
.iris-products__new-tariff {
  position: absolute;
  margin-left: 6.6875rem;
  bottom: -5rem;
}
.iris-products__new-tariff-link {
  border: 0.375rem solid #444444;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  padding: 0.625rem;
  margin-right: 1.25rem;
}
.iris-products__new-tariff-link:hover {
  text-decoration: none;
}
.iris-products__new-tariff-icon {
  font-size: 1.125rem;
  color: black;
  display: block;
}
.iris-products__new-tarif-txt {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
}

@media screen and (min-width: 768px) {
  .iris-products__link {
    display: inline-block;
    margin-right: 3.125rem;
    line-height: 1.5rem;
  }
}
@media screen and (min-width: 992px) {
  .iris-products__details-tariff {
    order: 1;
    margin-bottom: 0;
  }
  .iris-products__details-tariff-number {
    margin-bottom: 0.375rem;
  }
  .iris-products__details-info {
    order: 3;
    margin-bottom: 0;
    width: auto;
  }
  .iris-products__links {
    order: 2;
  }
  .iris-products__new-tarif-txt {
    font-size: 1.25rem;
  }
}
.iris-consumption {
  background-color: white;
  padding: 1.5625rem 1.625rem;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  /* Header */
  /* Main */
  /* Footer */
}
.iris-consumption__header {
  margin-bottom: 2.5625rem;
}
.iris-consumption__header-block--flex {
  display: flex;
  align-items: flex-end;
}
.iris-consumption__header-tariff {
  display: block;
  font-size: 1.5rem;
  color: #0f1efa;
  font-family: obviously, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 2rem;
  margin-bottom: 1rem;
}
.iris-consumption__header-number {
  font-size: 0.875rem;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.375rem;
  color: #444444;
}
.iris-consumption__header-tariff-info {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
  padding-right: 1.375rem;
  line-height: 1.25rem;
}
.iris-consumption__header-tariff-info span {
  font-weight: bold;
}
.iris-consumption__main {
  margin-bottom: 4rem;
}
.iris-consumption__main-title {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: block;
  margin-bottom: 1.1875rem;
}
.iris-consumption__main-col {
  width: 100%;
  margin-bottom: 1.75rem;
}
.iris-consumption__main-block {
  display: flex;
}
.iris-consumption__main-block:not(:last-child) {
  margin-bottom: 1.8125rem;
}
.iris-consumption__main-icon {
  display: inline-block;
  background-color: gold;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1.125rem;
}
.iris-consumption__main-data {
  width: calc(100% - 58px);
  display: inline-block;
}
.iris-consumption__main-data-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 0.3125rem;
  display: block;
}
.iris-consumption__main-data-details {
  display: flex;
  justify-content: space-between;
}
.iris-consumption__main-data-usage strong {
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-consumption__main-data-progressbar {
  margin-bottom: 0.125rem;
}
.iris-consumption__main-data-update {
  font-size: 0.875rem;
  color: #666666;
}
.iris-consumption__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iris-consumption__footer-item {
  display: inline-block;
  margin-right: 3.125rem;
}
.iris-consumption__footer-link {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
  line-height: 2rem;
}
.iris-consumption__footer-link span:first-child {
  font-weight: bold;
}
.iris-consumption__footer-link:hover {
  color: #444444;
}
.iris-consumption__footer-link-icon {
  font-size: 0.75rem;
  margin-left: 0.8125rem;
}
.iris-consumption__footer-back-link {
  align-self: flex-end;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #444444;
  display: none;
}
.iris-consumption__footer-back-link:hover {
  text-decoration: none;
  color: #444444;
}
.iris-consumption__footer-back-link:hover .iris-consumption__footer-back-text {
  text-decoration: underline;
}
.iris-consumption__footer-back-icon {
  font-size: 0.75rem;
  margin-right: 0.75rem;
}

@media screen and (min-width: 992px) {
  .iris-consumption {
    /* Header */
    /* Main */
    /* Footer */
  }
  .iris-consumption__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.0625rem;
  }
  .iris-consumption__header-number {
    margin-bottom: 0.0625rem;
    display: block;
  }
  .iris-consumption__header-tariff {
    font-size: 1.5rem;
  }
  .iris-consumption__header-tariff-info {
    font-size: 1.25rem;
    padding-right: 0;
    margin-bottom: 0.9375rem;
  }
  .iris-consumption__main-title {
    margin-bottom: 1.75rem;
  }
  .iris-consumption__main-content {
    display: flex;
    flex-wrap: wrap;
  }
  .iris-consumption__main-col {
    width: calc(50% - 40px);
    margin-bottom: 0;
  }
  .iris-consumption__main-col:nth-child(odd) {
    margin-right: 1.25rem;
  }
  .iris-consumption__main-col:nth-child(even) {
    margin-left: 1.25rem;
  }
  .iris-consumption__main-block:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .iris-consumption__footer-link {
    line-height: 2.5625rem;
  }
  .iris-consumption__footer-back-link {
    display: block;
  }
}
.iris-dialog__txt {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0.625rem;
  font-size: 1rem;
  color: #444444;
}
.iris-dialog__txt--bold {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
}
.iris-dialog__txt--mb {
  margin-bottom: 1.75rem;
}
.iris-dialog__txt--center {
  text-align: center;
}
.iris-dialog__inputtext {
  width: 100%;
  max-width: none;
}
.iris-dialog__inputtext--big {
  font-size: 1.5rem;
}
.iris-dialog__inputtext--center {
  text-align: center;
}
.iris-dialog .p-dialog {
  height: 100%;
  max-height: none;
  border-radius: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
}
.iris-dialog .p-dialog .p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.iris-dialog .p-dialog .p-dialog-header {
  padding: 1.375rem 1.6875rem 1.375rem 1.25rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0f1efa;
  font-size: 1.25rem;
  border-radius: 0;
}
.iris-dialog .p-dialog .p-dialog-header .p-dialog-title {
  color: #0f1efa;
  font-size: 1.25rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
}
.iris-dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
  width: auto;
  height: auto;
  color: #444444;
  font-size: 0.875rem;
}
.iris-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: transparent;
}
.iris-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 30, 250, 0.4);
}
.iris-dialog .p-dialog .p-dialog-content {
  padding: 0 1.625rem 1.625rem;
  color: black;
  flex-grow: 0;
}
.iris-dialog .p-dialog .p-dialog-footer {
  border-radius: 0;
  text-align: left;
  padding: 0 1.25rem 1.25rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
}
.iris-dialog .p-dialog .p-dialog-footer .o-btn--block {
  width: 100%;
}
.iris-dialog .card {
  border: 0;
}
.iris-dialog .p-field-radiobutton {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
}
.iris-dialog .p-field-radiobutton > label {
  margin-left: 0.5rem;
}
.iris-dialog__content.modal-content {
  border: 0;
}
.iris-dialog__input-grid-row {
  display: block;
  padding: 0.9375rem 0;
}
.iris-dialog__input-grid-row--between {
  display: flex;
  justify-content: space-between;
}
.iris-dialog__input-grid-row--pr {
  padding-right: 3.75rem;
}
.iris-dialog__inputnumber .p-inputnumber {
  width: 18.75rem;
}
.iris-dialog__input-label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-dialog__icon {
  font-size: 3.75rem;
}
.iris-dialog__icon--error {
  color: #cd3c14;
  display: block;
  text-align: center;
}
.iris-dialog p-button[disabled=true] {
  cursor: default;
}
.iris-dialog--small .p-dialog {
  width: 100%;
}
.iris-dialog--medium .p-dialog {
  width: 100%;
}
.iris-dialog--no-footer .p-dialog-content {
  border-radius: 0 0 0.3125rem 0.3125rem;
}

@media screen and (min-width: 768px) {
  .iris-dialog .p-dialog {
    height: auto;
    max-height: 90%;
    background-color: transparent;
  }
  .iris-dialog--small .p-dialog {
    width: 23.4375rem;
  }
  .iris-dialog--medium .p-dialog {
    width: 46.875rem;
  }
}
/*
  My profile dialog
*/
.iris .obe-dialog .p-dialog {
  max-height: 100%;
  width: 100vw;
  height: 100%;
}
.iris .obe-dialog .p-dialog .p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.iris .obe-dialog .p-dialog .p-dialog-header {
  padding: 1.375rem 1.6875rem 1.375rem 1.25rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0f1efa;
  font-size: 1.25rem;
  border-radius: 0;
}
.iris .obe-dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
  width: auto;
  height: auto;
}
.iris .obe-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: transparent;
}
.iris .obe-dialog .p-dialog .p-dialog-content {
  padding: 1.375rem 1.25rem 1.125rem;
  flex-grow: 1;
}
.iris .obe-dialog .p-dialog .p-dialog-footer {
  border-radius: 0;
  text-align: left;
  padding: 0 1.25rem 1.25rem;
}
.iris .obe-dialog .p-dialog .p-dialog-footer .o-btn--block {
  width: 100%;
}
.iris .obe-dialog .p-dialog .o-inputtext__label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
  font-size: 1.125rem;
  width: 100%;
  margin-bottom: 0.1875rem;
  display: inline-block;
}
.iris .obe-dialog .p-dialog .o-inputtext__input {
  width: 100%;
  max-width: none;
  height: 3.125rem;
  border-radius: 0;
  border: 0.125rem solid #cccccc;
  margin-bottom: 0.1875rem;
  padding: 0.3125rem 0.8125rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: black;
}
.iris .obe-dialog .p-dialog .o-inputtext__input:enabled:focus {
  border-color: black;
}
.iris .obe-dialog .p-dialog .o-radiobutton .p-radiobutton-label {
  font-family: Arial, Helvetica, sans-serif;
}
.iris .obe-dialog .p-dialog .o-dropdown .p-dropdown {
  height: 3.125rem;
  align-items: center;
  border: 0.125rem solid #cccccc;
}
.iris .obe-dialog .p-dialog .o-dropdown .p-dropdown-label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris .obe-dialog .p-dialog .o-inputmask__input {
  width: 100%;
  height: 3.125rem;
  border-radius: 0;
  border: 0.125rem solid #cccccc;
}
.iris .obe-dialog .p-dialog .o-inputmask__input:enabled:focus {
  border-color: black;
  box-shadow: none;
}
.iris .obe-dialog .p-dialog .alert .o-inputtext__icon {
  margin-right: 0.1875rem;
  vertical-align: middle;
}
.iris .obe-dialog .p-dialog .alert .o-inputtext__error {
  display: inline-block;
}
.iris .obe-dialog .p-dialog .alert.alert-danger {
  background-color: transparent;
  padding: 0;
  border: 0;
}
.iris .obe-dialog .p-dialog .obe-dialog__sub-input .o-inputtext__label {
  font-size: 1rem;
}
.iris .obe-dialog__content--sep-big {
  padding: 1.125rem 2.8125rem 2.1875rem;
}
.iris .obe-dialog__footer {
  margin-top: 1.5625rem;
}
.iris .obe-dialog__label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: block;
  margin-bottom: 0.1875rem;
  font-size: 1.125rem;
}
.iris .obe-dialog__slider-container {
  margin: 0.9375rem 0;
}
.iris .obe-dialog__slider-value {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.1875rem;
  margin-bottom: 0.5625rem;
  color: #444444;
}
.iris .obe-dialog__sub-input {
  margin: -0.5rem 0 1.125rem 1rem;
  width: calc(100% - 16px);
}
.iris .obe-dialog__sub-input .o-inputtext__label {
  font-size: 1rem;
}
.iris .obe-dialog__sub-input .o-inputtext__input {
  font-family: Arial, Helvetica, sans-serif;
}
.iris .obe-dialog__icon-success {
  font-size: 5rem;
  text-align: center;
  display: block;
  margin-bottom: 0.875rem;
}
.iris .obe-dialog__txt-success {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
}
.iris .obe-dialog__txt-success-small {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.375rem;
}
.iris .obe-dialog__mb-medium {
  margin-bottom: 1.5rem;
}
.iris .obe-dialog--two-columns .o-dropdown .p-dropdown {
  width: 100%;
}
.iris .obe-dialog--two-columns .obe-dialog__row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.iris .obe-dialog--two-columns .obe-dialog__col {
  width: 100%;
  margin-bottom: 1rem;
}
.iris .obe-dialog--two-columns .obe-dialog__col .o-inputtext.p-field {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .iris .obe-dialog .p-dialog {
    height: auto;
    max-height: 90%;
  }
  .iris .obe-dialog .p-dialog .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .iris .obe-dialog .p-dialog .p-dialog-header {
    padding: 1.375rem 1.6875rem 1.375rem 1.25rem;
    font-family: obviously,  sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.25rem;
    border-radius: 0;
  }
  .iris .obe-dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: auto;
    height: auto;
  }
  .iris .obe-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background-color: transparent;
  }
  .iris .obe-dialog .p-dialog .p-dialog-content {
    padding: 1.375rem 1.25rem 1.125rem;
    flex-grow: 0;
  }
  .iris .obe-dialog .p-dialog .p-dialog-footer {
    border-radius: 0;
    text-align: left;
    padding: 0 1.25rem 1.25rem;
  }
  .iris .obe-dialog .p-dialog .p-dialog-footer .o-btn--block {
    width: 100%;
  }
  .iris .obe-dialog__content--sep-big {
    padding: 1.125rem 1.5625rem 2.1875rem;
  }
  .iris .obe-dialog__label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    display: block;
    margin-bottom: 0.1875rem;
    font-size: 1.125rem;
  }
  .iris .obe-dialog__slider-container {
    margin: 0.9375rem 0;
  }
  .iris .obe-dialog__slider-value {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.1875rem;
    margin-bottom: 0.5625rem;
  }
  .iris .obe-dialog--small .p-dialog {
    width: 23.4375rem;
  }
  .iris .obe-dialog--medium .p-dialog {
    width: 46.875rem;
  }
  .iris .obe-dialog--two-columns .o-dropdown .p-dropdown {
    width: 100%;
  }
  .iris .obe-dialog--two-columns .obe-dialog__row {
    width: 100%;
    display: flex;
    margin-bottom: 0.25rem;
  }
  .iris .obe-dialog--two-columns .obe-dialog__col {
    width: calc(50% - 15px);
  }
  .iris .obe-dialog--two-columns .obe-dialog__col:nth-child(odd) {
    margin-right: 0.9375rem;
  }
  .iris .obe-dialog--two-columns .obe-dialog__col:nth-child(even) {
    margin-left: 0.9375rem;
  }
  .iris .obe-dialog--two-columns .p-dialog .p-dialog-content {
    padding-top: 1.6875rem;
  }
}

.iris-new-tariff {
  font-family: Arial, Helvetica, sans-serif;
}
.iris-new-tariff__title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.iris-new-tariff__txt {
  line-height: 1.25rem;
}
.iris-new-tariff__tariff-wrapper {
  padding-top: 0.375rem;
  padding-bottom: 3.0625rem;
}
.iris-new-tariff__tariff {
  background-color: white;
  width: 16.25rem;
  padding: 1.25rem;
}
.iris-new-tariff__tariff:not(:last-child) {
  margin-right: 1.25rem;
}
.iris-new-tariff__data {
  background-color: #d73e0f;
  width: 100%;
  height: 9.6875rem;
  position: relative;
  text-align: right;
  margin-bottom: 1.125rem;
  overflow: hidden;
}
.iris-new-tariff__data-title {
  color: white;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 7px 6px;
  width: 5.625rem;
  height: 5.625rem;
  background-color: #d73e0f;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
}
.iris-new-tariff__data-title:before, .iris-new-tariff__data-title:after {
  content: "";
  position: absolute;
  background-color: #0f1efa;
}
.iris-new-tariff__data-title:before {
  top: 50vw;
  left: 100%;
  width: 100vw;
  height: calc(100% + 1px);
  transform: skewY(45deg);
}
.iris-new-tariff__data-title:after {
  top: 100%;
  left: 50vh;
  width: 100%;
  height: 100vh;
  transform: skewx(45deg);
}
.iris-new-tariff__data-title > span {
  display: block;
}
.iris-new-tariff__data-title > span:first-child {
  font-size: 3.25rem;
}
.iris-new-tariff__data-title > span:last-child {
  font-size: 1.25rem;
}
.iris-new-tariff__data-recom {
  color: white;
  font-size: 0.875rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: top left;
  bottom: -0.9375rem;
  left: 0;
}
.iris-new-tariff__data--recom {
  background-color: #f57de0;
}
.iris-new-tariff__offer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.125rem;
}
.iris-new-tariff__offer-txt {
  font-size: 1rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0b56fb;
  line-height: 1.25rem;
  margin-top: 0.375rem;
}
.iris-new-tariff__offer-price {
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  color: white;
  background-color: #0f1efa;
  padding: 0.6875rem 1rem;
  border-radius: 50% 0 50% 50%;
}
.iris-new-tariff__offer-price > span:first-child {
  font-size: 2.5rem;
  display: block;
  line-height: 2.1875rem;
}
.iris-new-tariff__offer-price > span:last-child {
  font-size: 0.625rem;
}
.iris-new-tariff__current {
  padding: 1rem 2rem;
  line-height: 1.1875rem;
  font-size: 1rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0f1efa;
}
.iris-new-tariff__tariff-title {
  margin-bottom: 0.3125rem;
}
.iris-new-tariff__tariff-tip-title {
  font-size: 1.5rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 0 0.5625rem;
  margin: 0.625rem 0 1rem;
}
.iris-new-tariff__txt {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #444444;
  padding: 0 0.5625rem;
  line-height: 1.125rem;
  margin-bottom: 1.125rem;
}
.iris-new-tariff__tariff-tag {
  position: absolute;
  color: white;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 0.1875rem 0;
  width: 70%;
  border-radius: 1.25rem;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
}
.iris-new-tariff__tariff-contract {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: italic;
  color: #d73e0f;
  margin-bottom: 1.1875rem;
}
.iris-new-tariff .p-carousel .p-carousel-prev,
.iris-new-tariff .p-carousel .p-carousel-next {
  display: none;
}
.iris-new-tariff .p-carousel .p-carousel-indicator button {
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 50%;
  background-color: #444444;
}
.iris-new-tariff .p-carousel .p-carousel-indicator button:focus {
  box-shadow: none;
}
.iris-new-tariff .p-carousel .p-carousel-indicator button:hover {
  background-color: gold;
}
.iris-new-tariff .p-carousel .p-carousel-indicator.p-highlight button {
  background-color: gold;
}
.iris-new-tariff .p-carousel .p-carousel-item {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1124px) {
  .iris-new-tariff__tariff--tip {
    position: relative;
  }
  .iris-new-tariff__tariff--tip:before, .iris-new-tariff__tariff--tip:after {
    content: "";
    position: absolute;
    background-color: gold;
    z-index: -1;
  }
  .iris-new-tariff__tariff--tip:before {
    top: 50vw;
    left: 100%;
    width: 100vw;
    height: calc(100% + 1px);
    transform: skewY(45deg);
  }
  .iris-new-tariff__tariff--tip:after {
    top: 100%;
    left: 50vh;
    width: 100%;
    height: 100vh;
    transform: skewx(45deg);
  }
  .iris-new-tariff .p-carousel .p-carousel-indicators {
    display: none;
  }
  .iris-new-tariff .p-carousel .p-carousel-content {
    overflow: visible;
  }
  .iris-new-tariff .p-carousel .p-carousel-items-content {
    overflow: visible;
  }
}
.iris-review {
  font-family: Arial, Helvetica, sans-serif;
}
.iris-review__tariff {
  background-color: white;
  padding: 2rem 1.5625rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 0.0625rem solid black;
}
.iris-review__tariff-col {
  display: inline-block;
}
.iris-review__tariff-state {
  font-size: 0.875rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #444444;
  display: block;
}
.iris-review__tariff-title {
  font-size: 1.5rem;
  color: #0f1efa;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  display: block;
  margin-bottom: 0.5rem;
}
.iris-review__tariff-data {
  display: block;
  margin-bottom: 0.1875rem;
}
.iris-review__tariff-price {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 2.125rem;
  margin-right: 1.4375rem;
}
.iris-review__highlighted {
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  background-color: #f57de0;
  transform: rotate(21deg);
  vertical-align: middle;
}
.iris-review__btn {
  width: 100%;
}
.iris-review .o-btn.o-btn--link {
  margin: 0 auto;
  display: block;
  margin-top: 0.875rem;
}
.iris-review__footer {
  padding: 2rem 1.5625rem;
  background-color: white;
}
.iris-review .p-field-checkbox {
  margin-bottom: 1.3125rem;
  align-items: flex-start;
}
.iris-review .p-field-checkbox > label {
  line-height: 1.25rem;
}

@media screen and (min-width: 480px) {
  .iris-review__btn {
    width: auto;
    margin-right: 2.5rem;
  }
  .iris-review .o-btn.o-btn--link {
    margin: 0;
    display: inline;
  }
}
@media screen and (min-width: 768px) {
  .iris-review__tariff-title {
    margin-bottom: 0;
  }
  .iris-review__tariff-data {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    vertical-align: middle;
    margin-bottom: 0;
    display: inline;
  }
  .iris-review__tariff-data:not(:last-child) {
    margin-right: 3.6875rem;
  }
}
.iris-success {
  display: flex;
  flex-flow: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
  padding: 1.5rem 2rem;
}
.iris-success__icon {
  font-size: 6.4375rem;
  margin-bottom: 0.875rem;
}
.iris-success__title {
  font-size: 1.875rem;
  color: #0f1efa;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0.625rem;
}
.iris-success__txt {
  font-size: 1rem;
  line-height: 1.5625rem;
  color: #444444;
}
.iris-success__link {
  color: #444444;
}
.iris-success__link .icon-chevron-light-left {
  font-size: 0.75rem;
  margin-right: 0.5625rem;
}
.iris-success__link--bold {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-success__link span:last-child {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-success__link:hover {
  color: #444444;
  text-decoration: none;
}
.iris-success__link:hover span:last-child {
  text-decoration: underline;
}

.iris-new-sim {
  background-color: white;
  padding: 2.375rem 1.8125rem;
  font-family: Arial, Helvetica, sans-serif;
  /* Header */
  /* Main */
}
.iris-new-sim__header {
  padding-bottom: 2.0625rem;
}
.iris-new-sim__header-number {
  font-size: 0.875rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-new-sim__header-title {
  font-size: 1.875rem;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
}
.iris-new-sim__main-form-title-wrapper {
  margin-bottom: 0.875rem;
}
.iris-new-sim__main-form-title {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-right: 1.8125rem;
}
.iris-new-sim__main-form-subtitle {
  font-size: 0.875rem;
  font-style: italic;
  color: #666666;
}
.iris-new-sim__main-form-row {
  padding-bottom: 1rem;
  margin-bottom: 0.9375rem;
}
.iris-new-sim__main-form-row .p-error {
  display: block;
  font-size: 0.875rem;
  color: #cd3c14;
  position: absolute;
  bottom: -1.25rem;
  white-space: nowrap;
}
.iris-new-sim__main-form-row--mb-large {
  margin-bottom: 3.4375rem;
}
.iris-new-sim__main-form-inputnumber {
  display: inline-block;
  margin-right: 1.875rem;
  position: relative;
}
.iris-new-sim__main-form-inputnumber label {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.375rem;
}
.iris-new-sim__main-form-dropdown {
  display: inline-block;
  margin-right: 1.875rem;
}
.iris-new-sim__main-form-dropdown label {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.375rem;
}
.iris-new-sim__main-form-dropdown .o-dropdown .p-dropdown-label {
  padding-left: 1.25rem;
}
.iris-new-sim__main-form-inputtext {
  display: inline-block;
  margin-right: 1.875rem;
  position: relative;
}
.iris-new-sim__main-form-inputtext label {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.375rem;
}
.iris-new-sim__main-form-inputtext .o-inputtext__input {
  color: black;
}
.iris-new-sim__main-label {
  display: inline-block;
  font-size: 0.875rem;
}
.iris-new-sim__main-label--bold {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-new-sim .o-btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .iris-new-sim .o-btn {
    width: auto;
  }
}
.iris-tooltip {
  margin-left: 1rem;
  color: #0f1efa;
  position: absolute;
  margin-left: 0;
  top: 0.75rem;
}
.iris-tooltip__text.p-tooltip .p-tooltip-text {
  font-size: 0.8125rem;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  background-color: white;
  border-radius: 0;
}
.iris-tooltip__text.p-tooltip .p-tooltip-arrow {
  border-right-color: white;
}

@media screen and (min-width: 768px) {
  .iris-tooltip {
    left: 17.0625rem;
  }
}
.iris-faq {
  background-color: white;
  position: relative;
}
.iris-faq__title {
  background-color: transparent;
  padding: 1.25rem 0;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  top: -2.125rem;
}
.iris-faq__title:after {
  content: "";
  position: absolute;
  background: #444444;
  width: 100%;
  height: 100%;
  top: 0;
  left: -2.375rem;
  transform: skewX(45deg);
  z-index: -1;
}
.iris-faq__title:before {
  content: "";
  position: absolute;
  background: #444444;
  width: 100%;
  height: 100%;
  transform: skewX(45deg);
  top: 0;
  left: calc(-100% - 37px);
}
.iris-faq__title-txt {
  color: gold;
  font-size: 1.25rem;
  width: 100%;
  max-width: 71.25rem;
  padding: 0 1.4375rem;
  margin: 0 auto;
}
.iris-faq__content-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
  margin-bottom: 0.8125rem;
  display: block;
}
.iris-faq__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4375rem;
}
.iris-faq__list-item {
  width: 100%;
}
.iris-faq__sub-list-item:before {
  font-family: "icomoon";
  content: "";
  color: #d73e0f;
  font-size: 0.75rem;
  margin-right: 0.4375rem;
}
.iris-faq__list-content {
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
}
.iris-faq__list-content:hover {
  color: #444444;
}

@media screen and (min-width: 480px) {
  .iris-faq__title-txt {
    padding: 0 1rem;
    max-width: 30rem;
  }
}
@media screen and (min-width: 768px) {
  .iris-faq__title:after {
    width: 62%;
  }
  .iris-faq__title-txt {
    max-width: 48rem;
  }
}
@media screen and (min-width: 992px) {
  .iris-faq__title:after {
    width: 63%;
  }
  .iris-faq__title-txt {
    font-size: 1.75rem;
    max-width: 62rem;
  }
  .iris-faq__content-title {
    margin-bottom: 1.375rem;
  }
  .iris-faq__list-item {
    width: calc(100%/3);
  }
}
@media screen and (min-width: 1024px) {
  .iris-faq__title:after {
    width: 60%;
  }
  .iris-faq__title-txt {
    max-width: 64rem;
  }
}
@media screen and (min-width: 1124px) {
  .iris-faq__title-txt {
    max-width: 70.25rem;
  }
}
.iris-message .p-message {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-message .p-message .p-message-wrapper {
  background-color: white;
  padding: 0.9375rem 1.25rem;
}
.iris-message .p-message .p-message-summary {
  color: black;
}
.iris-message .p-message .p-message-detail {
  color: black;
}
.iris-message .p-message.p-message-success {
  border: 0.125rem solid #32c832;
}
.iris-message .p-message.p-message-success .p-message-icon:before {
  font-family: "icomoon";
  content: "";
  color: #32c832;
  font-size: 1.875rem;
}
.iris-message .p-message.p-message-warn {
  border: 0.125rem solid #ffcc00;
}
.iris-message .p-message.p-message-warn .p-message-icon:before {
  font-family: "icomoon";
  content: "";
  color: #ffcc00;
  font-size: 2rem;
}
.iris-message .p-message.p-message-warn .p-message-icon:after {
  font-family: "icomoon";
  content: "";
  margin-left: -1em;
  color: black;
  font-size: 2rem;
}
.iris-message .p-message.p-message-info {
  border: 0.125rem solid #527edb;
}
.iris-message .p-message.p-message-info .p-message-icon:before {
  font-family: "icomoon";
  content: "";
  color: #527edb;
  font-size: 1.875rem;
}
.iris-message .p-message.p-message-error {
  border: 0.125rem solid #cd3c14;
}
.iris-message .p-message.p-message-error .p-message-icon:before {
  font-family: "icomoon";
  content: "";
  color: #cd3c14;
  font-size: 1.875rem;
}

.iris-navigation {
  left: 0;
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
}
.iris-navigation.p-tabmenu {
  overflow: initial;
}
.iris-navigation.p-tabmenu .p-tabmenu-nav {
  background-color: #0f1efa;
  border: none;
  justify-content: space-evenly;
}
.iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  font-family: Arial;
  font-weight: bold;
  font-size: 1.3125rem;
  color: rgba(255, 255, 255, 0.5);
  background-color: #0f1efa;
  border-color: #0f1efa;
  padding: 0.8125rem 0 0.375rem;
  border-radius: 0;
  flex-wrap: wrap;
}
.iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5);
}
.iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-text {
  font-size: 0.625rem;
  width: 100%;
  text-align: center;
}
.iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-icon {
  display: block;
  width: 100%;
  text-align: center;
  margin-right: 0;
  margin-bottom: 0.125rem;
}
.iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: white;
}
.iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background-color: #0f1efa;
  color: white;
}

@media screen and (min-width: 768px) {
  .iris-navigation {
    position: static;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav {
    background-color: #d73e0f;
    border: none;
    justify-content: flex-start;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 3.625rem;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-family: Arial;
    font-weight: bold;
    font-size: 1.3125rem;
    color: white;
    background-color: #d73e0f;
    border-color: #d73e0f;
    border-width: 0 0 0.3125rem 0;
    padding: 0.8125rem 0 0.375rem;
    border-radius: 0;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5);
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-text {
    font-size: 1.3125rem;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-icon {
    display: none;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-color: gold;
  }
  .iris-navigation.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background-color: #d73e0f;
    border-color: gold;
    color: white;
  }
}
.iris-breadcrumbs {
  background-color: #444444;
  border-color: #444444;
  padding: 0.75rem 0rem;
}
.iris-breadcrumbs.p-breadcrumb ul {
  max-width: 70.25rem;
  margin: 0 auto;
  padding: 0 1rem;
}
.iris-breadcrumbs.p-breadcrumb ul li.p-breadcrumb-chevron {
  color: white;
  margin: 0 1.25rem;
  font-size: 0.625rem;
}
.iris-breadcrumbs .p-menuitem-text {
  color: white;
}
.iris-breadcrumbs.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (min-width: 480px) {
  .iris-breadcrumbs.p-breadcrumb ul {
    max-width: 30rem;
  }
}
@media screen and (min-width: 768px) {
  .iris-breadcrumbs.p-breadcrumb ul {
    max-width: 48rem;
  }
}
@media screen and (min-width: 992px) {
  .iris-breadcrumbs.p-breadcrumb ul {
    max-width: 62rem;
  }
}
@media screen and (min-width: 1024px) {
  .iris-breadcrumbs.p-breadcrumb ul {
    max-width: 64rem;
  }
}
@media screen and (min-width: 1124px) {
  .iris-breadcrumbs.p-breadcrumb ul {
    max-width: 70.25rem;
  }
}
.iris-billing {
  background-color: white;
  height: 16.875rem;
  padding: 3.25rem 1.8125rem;
}
.iris-billing__title {
  font-size: 1.25rem;
  color: #0f1efa;
  line-height: 1.1875rem;
  margin-bottom: 1.5rem;
}
.iris-billing__payment-info {
  margin-bottom: 0.75rem;
}
.iris-billing__sub-title {
  font-size: 1rem;
  color: black;
  margin-bottom: 0.625rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.iris-billing__payment-method {
  color: #444444;
  font-size: 1rem;
  line-height: 1.25rem;
}
.iris-billing__link {
  font-size: 1rem;
  color: #444444;
  text-decoration: underline;
}
.iris-billing__link:hover {
  color: #444444;
}

.iris-webform {
  background-color: white;
  padding: 2.375rem 1.8125rem;
}
.iris-webform__legend {
  font-size: 1.875rem;
  color: #0f1efa;
  font-family: obviously, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 2.0625rem;
}
.iris-webform__section-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  color: black;
  margin-right: 1.8125rem;
}
.iris-webform__section-subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  color: #444444;
  font-style: italic;
}
.iris-webform__txt {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #444444;
}
.iris-webform__txt--bold {
  font-weight: bold;
  color: black;
}
.iris-webform .o-inputnumber.p-float-label input {
  max-width: none;
  width: 100%;
}
.iris-webform .o-inputtext__input {
  max-width: none;
  width: 100%;
}
.iris-webform .o-btn {
  width: 100%;
}
.iris-webform .o-password__strength {
  vertical-align: middle;
  margin-left: 1.5625rem;
  position: absolute;
  top: 1.3125rem;
  right: 0;
}

@media screen and (min-width: 768px) {
  .iris-webform .o-inputnumber.p-float-label input {
    max-width: 16.25rem;
  }
  .iris-webform .o-inputtext__input {
    max-width: 16.25rem;
  }
  .iris-webform .o-btn {
    width: auto;
    min-width: 11.25rem;
  }
  .iris-webform .o-password__strength {
    margin-left: 1.375rem;
    top: 1rem;
    right: auto;
  }
}
.obe-vertical-tabs {
  background-color: white;
  display: none;
  /* Account information section */
  /* Subscriptions section */
  /* Contact preferences */
  /* Notifications section */
  /* Users and permissions section */
  /* Privacy section */
}
.obe-vertical-tabs__accountinfo-section {
  color: black;
}
.obe-vertical-tabs__accountinfo-section p-divider {
  display: none;
}
.obe-vertical-tabs__accountinfo-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}
.obe-vertical-tabs__accountinfo-title {
  font-family: "Helvetica-Neue-75";
  font-size: 1.25rem;
  line-height: 1.375rem;
  width: 100%;
}
.obe-vertical-tabs__accountinfo-number {
  font-family: "Helvetica-Neue-75";
  font-size: 1rem;
  margin-bottom: 0.875rem;
  color: #d73e0f;
}
.obe-vertical-tabs__accountinfo-info-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.obe-vertical-tabs__accountinfo-info-row.width100 {
  margin-bottom: 1.25rem;
}
.obe-vertical-tabs__accountinfo-info-row--none {
  margin-bottom: 1.25rem;
}
.obe-vertical-tabs__accountinfo-info-col {
  margin-bottom: 0.3125rem;
  width: 100%;
}
.obe-vertical-tabs__accountinfo-info-col:first-child {
  font-family: "Helvetica-Neue-75";
  width: 12.1875rem;
}
.obe-vertical-tabs__accountinfo-info-col span {
  line-height: 1.375rem;
}
.obe-vertical-tabs__accountinfo-info-col .o-btn.o-btn--link-italic.alignRight {
  display: none;
}
.obe-vertical-tabs__accountinfo-info-col .o-btn.o-btn--link-italic {
  color: #0f1efa;
  font-style: normal;
}
.obe-vertical-tabs__accountinfo-info-text a {
  text-decoration: underline;
}
.obe-vertical-tabs__subscriptions {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.obe-vertical-tabs__subscriptions-section:not(:last-child) {
  margin-bottom: 2.5rem;
}
.obe-vertical-tabs__subscriptions-section .o-btn.o-btn--link-italic {
  color: #0f1efa;
  font-style: normal;
}
.obe-vertical-tabs__subscriptions-title-container .o-btn.o-btn--link-italic {
  display: none;
  color: #0f1efa;
  font-style: normal;
}
.obe-vertical-tabs__subscriptions-title {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.obe-vertical-tabs__subscriptions-number {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 0.875rem;
  color: #d73e0f;
}
.obe-vertical-tabs__subscriptions-info-row {
  margin-bottom: 0.3125rem;
}
.obe-vertical-tabs__subscriptions-info-col {
  display: inline-block;
  font-size: 0.875rem;
}
.obe-vertical-tabs__subscriptions-info-col:first-child {
  width: 53%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.obe-vertical-tabs__subscriptions-info-col:last-child {
  width: 47%;
}
.obe-vertical-tabs__subscriptions-info {
  margin-bottom: 1.4375rem;
}
.obe-vertical-tabs__contact {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: black;
}
.obe-vertical-tabs__contact-block:not(:last-child) {
  margin-bottom: 1.625rem;
  border-bottom: 0.0625rem solid #eeeeee;
}
.obe-vertical-tabs__contact-text {
  margin-bottom: 1.1875rem;
}
.obe-vertical-tabs__contact-text--title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  color: #d73e0f;
}
.obe-vertical-tabs__contact-radio-wrapper {
  margin-bottom: 3.125rem;
}
.obe-vertical-tabs__contact-radio-wrapper .o-radiobutton {
  display: block;
  margin-bottom: 1.3125rem;
}
.obe-vertical-tabs__contact-radio-wrapper .p-radiobutton-label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.obe-vertical-tabs__contact-radio-wrapper .o-radio {
  margin-bottom: 1.25rem;
}
.obe-vertical-tabs__contact-radio-wrapper .o-radio__label {
  align-items: flex-start;
}
.obe-vertical-tabs__contact-radio-wrapper .o-radio__icon {
  margin-top: 0.125rem;
}
.obe-vertical-tabs__contact-radio-wrapper .o-radio__txt {
  font-size: 0.875rem;
}
.obe-vertical-tabs__contact-check-wrapper .o-check {
  align-items: flex-start;
}
.obe-vertical-tabs__contact-check-wrapper .o-check__wrapper {
  display: block;
}
.obe-vertical-tabs__contact-check-wrapper .p-checkbox-label {
  line-height: 1.125rem;
}
.obe-vertical-tabs__contact-check-wrapper > .o-checkbox {
  margin-bottom: 1.8125rem;
}
.obe-vertical-tabs__contact-check-wrapper .o-checkbox__label {
  align-items: flex-start;
}
.obe-vertical-tabs__contact-check-wrapper .o-checkbox__txt {
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.obe-vertical-tabs__contact-check-wrapper--mb-medium {
  margin-bottom: 2.25rem;
}
.obe-vertical-tabs__contact-check-wrapper--mb-xmedium {
  margin-bottom: 1.875rem;
}
.obe-vertical-tabs__contact-check-wrapper--inline {
  padding-left: 1.875rem;
}
.obe-vertical-tabs__contact-check-wrapper--inline .o-check__wrapper {
  margin-bottom: 0.4375rem;
}
.obe-vertical-tabs__contact-check-wrapper--inline .o-checkbox {
  margin-bottom: 1rem;
}
.obe-vertical-tabs__contact-check-sub {
  padding-left: 1.875rem;
}
.obe-vertical-tabs__contact-check-sub .o-check__wrapper {
  margin-bottom: 0.6875rem;
}
.obe-vertical-tabs__contact-check-sub .o-checkbox {
  margin-bottom: 1rem;
}
.obe-vertical-tabs__notifications {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.obe-vertical-tabs__notifications-switch {
  margin-bottom: 0.25rem;
}
.obe-vertical-tabs__notifications-switch-title {
  display: block;
  margin-top: 1.3125rem;
}
.obe-vertical-tabs__notifications-switch-label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.1875rem;
}
.obe-vertical-tabs__notifications-switch-sub {
  font-size: 0.875rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  vertical-align: top;
  color: #d73e0f;
}
.obe-vertical-tabs__notifications-switch-sub:hover {
  color: #d73e0f;
}
.obe-vertical-tabs__notifications-switch-wrapper {
  display: inline-block;
  margin-top: 1.3125rem;
  vertical-align: top;
}
.obe-vertical-tabs__notifications-switch-wrapper .o-switch {
  margin-right: 1.625rem;
}
.obe-vertical-tabs__notifications-switch-wrapper .p-inputswitch {
  vertical-align: middle;
}
.obe-vertical-tabs__notifications-submit {
  margin-top: 1.25rem;
}
.obe-vertical-tabs__permissions {
  padding-right: 0.875rem;
}
.obe-vertical-tabs__permissions .o-radiobutton {
  margin-right: 2.5rem;
}
.obe-vertical-tabs__permissions .o-radiobutton .p-radiobutton-label {
  margin-left: 0.625rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.obe-vertical-tabs__permissions-row {
  margin-bottom: 1.5625rem;
}
.obe-vertical-tabs__permissions-row .o-btn.o-btn--small {
  display: block;
  width: 100%;
}
.obe-vertical-tabs__permissions-row--mb-small {
  margin-bottom: 0.9375rem;
}
.obe-vertical-tabs__permissions-radio-wrapper {
  color: black;
}
.obe-vertical-tabs__permissions-radio-wrapper .o-radio {
  display: inline-block;
  width: auto;
  margin-right: 2.5rem;
}
.obe-vertical-tabs__permissions-radio-title {
  margin-bottom: 0.625rem;
}
.obe-vertical-tabs__permissions-col {
  display: inline-block;
  width: auto;
  margin-right: 2.1875rem;
}
.obe-vertical-tabs__privacy {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.obe-vertical-tabs__privacy-row {
  margin-bottom: 2.6875rem;
}
.obe-vertical-tabs__privacy-row--mb-medium {
  margin-bottom: 1.8125rem;
}
.obe-vertical-tabs__privacy-text {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 1.3125rem;
  line-height: 1.25rem;
  color: black;
}
.obe-vertical-tabs__privacy-text--italic {
  font-style: italic;
}
.obe-vertical-tabs__privacy-check-wrapper .o-check {
  display: inline-block;
}
.obe-vertical-tabs__privacy-radio-wrapper .p-radiobutton-label {
  margin-left: 0.625rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

/* Mobile component */
.obe-vertical-tabs-resp {
  display: block;
  /* Accordion */
}
.obe-vertical-tabs-resp .p-accordion {
  /* Accordion content */
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem 1.1875rem;
  border-color: #0f1efa;
  background: #0f1efa;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  position: absolute;
  right: 0.9375rem;
  margin-right: 0;
  color: white;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-header-text {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  font-family: obviously,  sans-serif;
  font-weight: 700;
  font-style: normal;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header .p-accordion-header-link:focus, .obe-vertical-tabs-resp .p-accordion .p-accordion-header .p-accordion-header-link:hover {
  text-decoration: none;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #0f1efa;
  border-color: #0f1efa;
  color: white;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link .p-accordion-header-text,
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link .p-accordion-toggle-icon {
  color: gold;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #0f1efa;
  border-color: #0f1efa;
  color: white;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link .p-accordion-toggle-icon {
  color: gold;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link .p-accordion-header-text {
  color: gold;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}
.obe-vertical-tabs-resp .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.obe-vertical-tabs-resp .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.obe-vertical-tabs-resp .p-accordion .p-accordion-content {
  padding: 1.125rem 1.25rem;
}

@media screen and (min-width: 768px) {
  /* Desktop component */
  .obe-vertical-tabs {
    display: flex;
    flex-direction: row;
    min-height: 21.5625rem;
    background-color: white;
    /* Tabs */
    /* Content */
    /* Account section */
    /* Subscriptions section */
    /* Contact preferences section */
    /* Notifications section */
    /* Users and permissions section */
    /* Privacy section */
  }
  .obe-vertical-tabs__tabs {
    display: inline-block;
    width: 18.75rem;
    background-color: #0f1efa;
    width: 19.75rem;
  }
  .obe-vertical-tabs__tabs-list {
    padding-top: 0.8125rem;
  }
  .obe-vertical-tabs__tabs-item {
    border-width: 0 0 0.0625rem 0;
    border-style: solid;
    font-family: "Helvetica-Neue-75";
    border-color: #0f1efa;
  }
  .obe-vertical-tabs__tabs-item button {
    width: 100%;
    padding: 0.9375rem 1.25rem;
    text-align: left;
    cursor: pointer;
    color: white;
    font-family: obviously,  sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  .obe-vertical-tabs__tabs-item--active button {
    background-color: #0f1efa;
    color: gold;
  }
  .obe-vertical-tabs__content {
    display: inline-block;
    padding: 1.875rem 2.5rem;
    border-left: 0.0625rem solid #eeeeee;
    width: calc(100% - 316px);
    position: relative;
  }
  .obe-vertical-tabs .displayFlex {
    display: flex;
    flex-wrap: wrap;
  }
  .obe-vertical-tabs .displayFlex p-divider {
    width: 100%;
  }
  .obe-vertical-tabs .widthAuto {
    width: auto;
  }
  .obe-vertical-tabs .widthCol {
    width: 12.1875rem;
  }
  .obe-vertical-tabs .width100 {
    width: 100%;
  }
  .obe-vertical-tabs__accountinfo-section p-divider {
    display: none;
  }
  .obe-vertical-tabs__accountinfo-info .displayFlex {
    margin-bottom: 1.375rem;
  }
  .obe-vertical-tabs__accountinfo-info-col .o-btn.o-btn--link-italic.alignRight {
    display: inline-block;
    position: absolute;
    right: 0px;
    padding: 0.625rem;
    margin-right: 2.5rem;
    color: #0f1efa;
    font-style: normal;
  }
  .obe-vertical-tabs__accountinfo-section {
    padding-top: 2.5rem;
  }
  .obe-vertical-tabs__accountinfo-section:first-child {
    padding-top: 0;
    padding-bottom: 1.875rem;
  }
  .obe-vertical-tabs__accountinfo-section:last-child {
    border-bottom: 0;
  }
  .obe-vertical-tabs__accountinfo-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }
  .obe-vertical-tabs__accountinfo-title {
    font-family: "Helvetica-Neue-75";
    font-size: 1.25rem;
    line-height: 1.375rem;
    width: 100%;
  }
  .obe-vertical-tabs__accountinfo-number {
    font-family: "Helvetica-Neue-75";
    font-size: 1rem;
    margin-bottom: 0.875rem;
    color: #d73e0f;
  }
  .obe-vertical-tabs__accountinfo-info-row {
    display: flex;
    flex-direction: row;
  }
  .obe-vertical-tabs__accountinfo-info-row--none {
    display: none;
  }
  .obe-vertical-tabs__accountinfo-info-row:nth-child(2) {
    margin-bottom: 1.75rem;
  }
  .obe-vertical-tabs__accountinfo-info-row.width100 {
    margin-bottom: 0;
  }
  .obe-vertical-tabs__accountinfo-info-row.displayFlex {
    margin-bottom: 1.25rem;
  }
  .obe-vertical-tabs__accountinfo-info-col {
    margin-bottom: 0.3125rem;
  }
  .obe-vertical-tabs__accountinfo-info-col:first-child {
    font-family: "Helvetica-Neue-75";
  }
  .obe-vertical-tabs__accountinfo-info-col span {
    line-height: 1.375rem;
  }
  .obe-vertical-tabs__subscriptions-section {
    padding-top: 2.5rem;
  }
  .obe-vertical-tabs__subscriptions-section:first-child {
    padding-top: 0;
    padding-bottom: 1.875rem;
  }
  .obe-vertical-tabs__subscriptions-section:last-child {
    border-bottom: 0;
  }
  .obe-vertical-tabs__subscriptions-section:not(:last-child) {
    margin-bottom: 0;
  }
  .obe-vertical-tabs__subscriptions-section > .o-btn.o-btn--link-italic {
    display: none;
  }
  .obe-vertical-tabs__subscriptions-section .o-btn.o-btn--link-italic {
    color: #0f1efa;
    font-style: normal;
  }
  .obe-vertical-tabs__subscriptions-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }
  .obe-vertical-tabs__subscriptions-title-container .o-btn.o-btn--link-italic {
    display: inline-block;
  }
  .obe-vertical-tabs__subscriptions-title {
    font-family: "Helvetica-Neue-75";
    font-size: 1.25rem;
    line-height: 1.375rem;
    width: 100%;
  }
  .obe-vertical-tabs__subscriptions-number {
    font-family: "Helvetica-Neue-75";
    font-size: 1rem;
    margin-bottom: 0.875rem;
    color: #d73e0f;
  }
  .obe-vertical-tabs__subscriptions-info {
    margin-bottom: 0;
  }
  .obe-vertical-tabs__subscriptions-info-row {
    display: flex;
    flex-direction: row;
  }
  .obe-vertical-tabs__subscriptions-info-col {
    margin-bottom: 0.3125rem;
    font-size: 1rem;
  }
  .obe-vertical-tabs__subscriptions-info-col:first-child {
    font-family: "Helvetica-Neue-75";
    width: 12.1875rem;
  }
  .obe-vertical-tabs__subscriptions-info-col span {
    line-height: 1.375rem;
  }
  .obe-vertical-tabs__contact .o-checkbox {
    margin-bottom: 0;
  }
  .obe-vertical-tabs__contact-block {
    margin-bottom: 2.625rem;
    border-bottom: 0.0625rem solid #eeeeee;
  }
  .obe-vertical-tabs__contact-block:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  .obe-vertical-tabs__contact-block:not(:last-child) {
    margin-bottom: 2.5625rem;
  }
  .obe-vertical-tabs__contact-block--no-border {
    border-bottom: none;
  }
  .obe-vertical-tabs__contact-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.125rem;
    color: black;
  }
  .obe-vertical-tabs__contact-text--title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    color: #d73e0f;
  }
  .obe-vertical-tabs__contact-text--mb-medium {
    margin-bottom: 2.0625rem;
  }
  .obe-vertical-tabs__contact-text--mb-small {
    margin-bottom: 1.75rem;
  }
  .obe-vertical-tabs__contact-text strong {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  .obe-vertical-tabs__contact-radio-wrapper {
    margin-bottom: 2.5625rem;
  }
  .obe-vertical-tabs__contact-radio-wrapper .o-dropdown .p-dropdown {
    margin-top: 6px;
    margin-left: 30px;
  }
  .obe-vertical-tabs__contact-check-wrapper {
    margin-bottom: 0.6875rem;
  }
  .obe-vertical-tabs__contact-check-wrapper > .o-check__wrapper {
    margin-bottom: 0rem;
  }
  .obe-vertical-tabs__contact-check-wrapper .p-checkbox-label {
    font-size: 1rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--mb-medium {
    margin-bottom: 1.5rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--mb-big {
    margin-bottom: 2.5rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--mb-xmedium {
    margin-bottom: 1.875rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--inline {
    padding-left: 0;
  }
  .obe-vertical-tabs__contact-check-wrapper--inline .o-checkbox {
    display: inline-block;
    width: auto;
    margin-right: 1.875rem;
    margin-bottom: 0.6875rem;
  }
  .obe-vertical-tabs__contact-check-sub {
    padding-top: 0.6875rem;
    padding-left: 2.5625rem;
  }
  .obe-vertical-tabs__contact-check-sub .o-checkbox {
    display: inline-block;
    width: auto;
    margin-right: 1.875rem;
  }
  .obe-vertical-tabs__notifications-switch {
    margin-bottom: 0.25rem;
  }
  .obe-vertical-tabs__notifications-switch-title {
    display: inline-block;
    width: 64%;
    margin-top: 1.3125rem;
    vertical-align: top;
  }
  .obe-vertical-tabs__notifications-switch-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 1.1875rem;
  }
  .obe-vertical-tabs__notifications-switch-sub {
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    vertical-align: top;
    color: #d73e0f;
  }
  .obe-vertical-tabs__notifications-switch-wrapper {
    display: inline-block;
    width: 36%;
    margin-top: 1.3125rem;
    vertical-align: top;
  }
  .obe-vertical-tabs__notifications-switch-wrapper .p-inputswitch {
    vertical-align: middle;
  }
  .obe-vertical-tabs__notifications-submit {
    margin-top: 1.25rem;
  }
  .obe-vertical-tabs__permissions {
    padding-right: 0.875rem;
  }
  .obe-vertical-tabs__permissions-row {
    margin-bottom: 1.5625rem;
  }
  .obe-vertical-tabs__permissions-row .o-btn.o-btn--small {
    width: auto;
  }
  .obe-vertical-tabs__permissions-row--mb-small {
    margin-bottom: 0.9375rem;
  }
  .obe-vertical-tabs__permissions-radio-wrapper .o-radio {
    display: inline-block;
    width: auto;
    margin-right: 2.5rem;
  }
  .obe-vertical-tabs__permissions-radio-title {
    margin-bottom: 0.625rem;
  }
  .obe-vertical-tabs__permissions-col {
    display: inline-block;
    width: auto;
    margin-right: 2.1875rem;
  }
  .obe-vertical-tabs__privacy-row {
    margin-bottom: 2.6875rem;
  }
  .obe-vertical-tabs__privacy-row--mb-medium {
    margin-bottom: 1.8125rem;
  }
  .obe-vertical-tabs__privacy-text {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 1.3125rem;
    line-height: 1.25rem;
    color: black;
  }
  .obe-vertical-tabs__privacy-text--italic {
    font-style: italic;
  }
  .obe-vertical-tabs__privacy-check-wrapper .o-checkbox {
    margin-bottom: 0.6875rem;
  }
  .obe-vertical-tabs__privacy-radio-wrapper .o-radio {
    display: inline-block;
    width: auto;
    margin-right: 2.5rem;
  }

  /* Mobile component */
  .obe-vertical-tabs-resp {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  /* Desktop component */
  .obe-vertical-tabs {
    /* Subscriptions section */
    /* Contact preferences section */
    /* Notifications section */
    /* Users and permissions section */
    /* Privacy section */
  }
  .obe-vertical-tabs__subscriptions-title {
    width: auto;
  }
  .obe-vertical-tabs__contact .o-checkbox {
    margin-bottom: 0;
  }
  .obe-vertical-tabs__contact-block {
    margin-bottom: 2.625rem;
    border-bottom: 0.0625rem solid #eeeeee;
  }
  .obe-vertical-tabs__contact-block:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  .obe-vertical-tabs__contact-block--no-border {
    border-bottom: none;
  }
  .obe-vertical-tabs__contact-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.125rem;
    color: black;
  }
  .obe-vertical-tabs__contact-text--title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    color: #d73e0f;
  }
  .obe-vertical-tabs__contact-text--mb-medium {
    margin-bottom: 2.0625rem;
  }
  .obe-vertical-tabs__contact-text--mb-small {
    margin-bottom: 1.75rem;
  }
  .obe-vertical-tabs__contact-text strong {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  .obe-vertical-tabs__contact-radio-wrapper {
    margin-bottom: 2.0625rem;
  }
  .obe-vertical-tabs__contact-radio-wrapper .o-radiobutton {
    display: block;
    margin-bottom: 0.75rem;
  }
  .obe-vertical-tabs__contact-radio-wrapper .p-radiobutton-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    margin-left: 0.375rem;
  }
  .obe-vertical-tabs__contact-radio-wrapper .o-dropdown .p-dropdown {
    margin-top: 6px;
    margin-left: 30px;
  }
  .obe-vertical-tabs__contact-check-wrapper {
    margin-bottom: 0.375rem;
  }
  .obe-vertical-tabs__contact-check-wrapper .o-check__wrapper {
    display: inline-block;
  }
  .obe-vertical-tabs__contact-check-wrapper--mb-medium {
    margin-bottom: 1.1875rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--mb-big {
    margin-bottom: 2.25rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--mb-xmedium {
    margin-bottom: 1.875rem;
  }
  .obe-vertical-tabs__contact-check-wrapper--inline .o-checkbox {
    display: inline-block;
    width: auto;
    margin-right: 1.875rem;
    margin-bottom: 0.6875rem;
  }
  .obe-vertical-tabs__contact-check-sub {
    padding-top: 0.375rem;
    padding-left: 2.5625rem;
  }
  .obe-vertical-tabs__contact-check-sub .o-check__wrapper {
    margin-bottom: 0.4375rem;
  }
  .obe-vertical-tabs__contact-check-sub .o-checkbox {
    display: inline-block;
    width: auto;
    margin-right: 1.875rem;
  }
  .obe-vertical-tabs__notifications-switch {
    margin-bottom: 0.25rem;
  }
  .obe-vertical-tabs__notifications-switch-title {
    display: inline-block;
    width: 64%;
    margin-top: 1.3125rem;
    vertical-align: top;
  }
  .obe-vertical-tabs__notifications-switch-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 1.1875rem;
  }
  .obe-vertical-tabs__notifications-switch-sub {
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    vertical-align: top;
    color: #d73e0f;
  }
  .obe-vertical-tabs__notifications-switch-wrapper {
    display: inline-block;
    width: 36%;
    margin-top: 1.3125rem;
    vertical-align: top;
  }
  .obe-vertical-tabs__notifications-switch-wrapper .p-inputswitch {
    vertical-align: middle;
  }
  .obe-vertical-tabs__notifications-submit {
    margin-top: 1.25rem;
  }
  .obe-vertical-tabs__permissions {
    padding-right: 0.875rem;
  }
  .obe-vertical-tabs__permissions-row {
    margin-bottom: 1.5625rem;
  }
  .obe-vertical-tabs__permissions-row--mb-small {
    margin-bottom: 0.9375rem;
  }
  .obe-vertical-tabs__permissions-radio-wrapper .o-radio {
    display: inline-block;
    width: auto;
    margin-right: 2.5rem;
  }
  .obe-vertical-tabs__permissions-radio-title {
    margin-bottom: 0.625rem;
  }
  .obe-vertical-tabs__permissions-col {
    display: inline-block;
    width: auto;
    margin-right: 2.1875rem;
  }
  .obe-vertical-tabs__privacy-row {
    margin-bottom: 2.6875rem;
  }
  .obe-vertical-tabs__privacy-row--mb-medium {
    margin-bottom: 1.8125rem;
  }
  .obe-vertical-tabs__privacy-text {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 1.3125rem;
    line-height: 1.25rem;
    color: black;
  }
  .obe-vertical-tabs__privacy-text--italic {
    font-style: italic;
  }
  .obe-vertical-tabs__privacy-check-wrapper .o-checkbox {
    margin-bottom: 0.6875rem;
  }
  .obe-vertical-tabs__privacy-radio-wrapper .o-radio {
    display: inline-block;
    width: auto;
    margin-right: 2.5rem;
  }

  /* Mobile component */
  .obe-vertical-tabs-resp {
    display: none;
  }
}
.iris-spinner .p-progress-spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.obe-spinner .p-progress-spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.iris-admin {
  background-color: white;
  padding: 2rem;
}
.iris-admin h2 {
  font-size: 1.125rem;
  color: black;
}
.iris-admin .o-btn {
  align-self: flex-end;
}
.iris-admin .o-inputtext__input {
  align-self: flex-end;
}
.iris-admin .o-inputtext,
.iris-admin .o-dropdown .p-dropdown {
  max-width: 15.8125rem;
}

.t-color-background-primary {
  background-color: #D73E0F !important;
}

.t-color-border-primary {
  border-color: #D73E0F !important;
}

.t-color-primary {
  color: #D73E0F !important;
}

.t-color-background-focus-primary:focus {
  background-color: #D73E0F !important;
}

.t-color-border-focus-primary:focus {
  border-color: #D73E0F !important;
}

.t-color-focus-primary:focus {
  color: #D73E0F !important;
}

.t-color-background-active-primary:active {
  background-color: #D73E0F !important;
}

.t-color-border-active-primary:active {
  border-color: #D73E0F !important;
}

.t-color-active-primary:active {
  color: #D73E0F !important;
}

.t-color-background-hover-primary:hover {
  background-color: #D73E0F !important;
}

.t-color-border-hover-primary:hover {
  border-color: #D73E0F !important;
}

.t-color-hover-primary:hover {
  color: #D73E0F !important;
}

.t-color-background-secondary {
  background-color: #FFD700 !important;
}

.t-color-border-secondary {
  border-color: #FFD700 !important;
}

.t-color-secondary {
  color: #FFD700 !important;
}

.t-color-background-focus-secondary:focus {
  background-color: #FFD700 !important;
}

.t-color-border-focus-secondary:focus {
  border-color: #FFD700 !important;
}

.t-color-focus-secondary:focus {
  color: #FFD700 !important;
}

.t-color-background-active-secondary:active {
  background-color: #FFD700 !important;
}

.t-color-border-active-secondary:active {
  border-color: #FFD700 !important;
}

.t-color-active-secondary:active {
  color: #FFD700 !important;
}

.t-color-background-hover-secondary:hover {
  background-color: #FFD700 !important;
}

.t-color-border-hover-secondary:hover {
  border-color: #FFD700 !important;
}

.t-color-hover-secondary:hover {
  color: #FFD700 !important;
}

.t-color-background-terciary {
  background-color: #0F1EFA !important;
}

.t-color-border-terciary {
  border-color: #0F1EFA !important;
}

.t-color-terciary {
  color: #0F1EFA !important;
}

.t-color-background-focus-terciary:focus {
  background-color: #0F1EFA !important;
}

.t-color-border-focus-terciary:focus {
  border-color: #0F1EFA !important;
}

.t-color-focus-terciary:focus {
  color: #0F1EFA !important;
}

.t-color-background-active-terciary:active {
  background-color: #0F1EFA !important;
}

.t-color-border-active-terciary:active {
  border-color: #0F1EFA !important;
}

.t-color-active-terciary:active {
  color: #0F1EFA !important;
}

.t-color-background-hover-terciary:hover {
  background-color: #0F1EFA !important;
}

.t-color-border-hover-terciary:hover {
  border-color: #0F1EFA !important;
}

.t-color-hover-terciary:hover {
  color: #0F1EFA !important;
}

.t-color-background-quaternary {
  background-color: #444444 !important;
}

.t-color-border-quaternary {
  border-color: #444444 !important;
}

.t-color-quaternary {
  color: #444444 !important;
}

.t-color-background-focus-quaternary:focus {
  background-color: #444444 !important;
}

.t-color-border-focus-quaternary:focus {
  border-color: #444444 !important;
}

.t-color-focus-quaternary:focus {
  color: #444444 !important;
}

.t-color-background-active-quaternary:active {
  background-color: #444444 !important;
}

.t-color-border-active-quaternary:active {
  border-color: #444444 !important;
}

.t-color-active-quaternary:active {
  color: #444444 !important;
}

.t-color-background-hover-quaternary:hover {
  background-color: #444444 !important;
}

.t-color-border-hover-quaternary:hover {
  border-color: #444444 !important;
}

.t-color-hover-quaternary:hover {
  color: #444444 !important;
}

.t-color-background-white {
  background-color: #ffffff !important;
}

.t-color-border-white {
  border-color: #ffffff !important;
}

.t-color-white {
  color: #ffffff !important;
}

.t-color-background-focus-white:focus {
  background-color: #ffffff !important;
}

.t-color-border-focus-white:focus {
  border-color: #ffffff !important;
}

.t-color-focus-white:focus {
  color: #ffffff !important;
}

.t-color-background-active-white:active {
  background-color: #ffffff !important;
}

.t-color-border-active-white:active {
  border-color: #ffffff !important;
}

.t-color-active-white:active {
  color: #ffffff !important;
}

.t-color-background-hover-white:hover {
  background-color: #ffffff !important;
}

.t-color-border-hover-white:hover {
  border-color: #ffffff !important;
}

.t-color-hover-white:hover {
  color: #ffffff !important;
}

.t-color-background-black {
  background-color: #000000 !important;
}

.t-color-border-black {
  border-color: #000000 !important;
}

.t-color-black {
  color: #000000 !important;
}

.t-color-background-focus-black:focus {
  background-color: #000000 !important;
}

.t-color-border-focus-black:focus {
  border-color: #000000 !important;
}

.t-color-focus-black:focus {
  color: #000000 !important;
}

.t-color-background-active-black:active {
  background-color: #000000 !important;
}

.t-color-border-active-black:active {
  border-color: #000000 !important;
}

.t-color-active-black:active {
  color: #000000 !important;
}

.t-color-background-hover-black:hover {
  background-color: #000000 !important;
}

.t-color-border-hover-black:hover {
  border-color: #000000 !important;
}

.t-color-hover-black:hover {
  color: #000000 !important;
}

.t-color-background-blue-1 {
  background-color: #0F22FA !important;
}

.t-color-border-blue-1 {
  border-color: #0F22FA !important;
}

.t-color-blue-1 {
  color: #0F22FA !important;
}

.t-color-background-focus-blue-1:focus {
  background-color: #0F22FA !important;
}

.t-color-border-focus-blue-1:focus {
  border-color: #0F22FA !important;
}

.t-color-focus-blue-1:focus {
  color: #0F22FA !important;
}

.t-color-background-active-blue-1:active {
  background-color: #0F22FA !important;
}

.t-color-border-active-blue-1:active {
  border-color: #0F22FA !important;
}

.t-color-active-blue-1:active {
  color: #0F22FA !important;
}

.t-color-background-hover-blue-1:hover {
  background-color: #0F22FA !important;
}

.t-color-border-hover-blue-1:hover {
  border-color: #0F22FA !important;
}

.t-color-hover-blue-1:hover {
  color: #0F22FA !important;
}

.t-color-background-blue-2 {
  background-color: #0B56FB !important;
}

.t-color-border-blue-2 {
  border-color: #0B56FB !important;
}

.t-color-blue-2 {
  color: #0B56FB !important;
}

.t-color-background-focus-blue-2:focus {
  background-color: #0B56FB !important;
}

.t-color-border-focus-blue-2:focus {
  border-color: #0B56FB !important;
}

.t-color-focus-blue-2:focus {
  color: #0B56FB !important;
}

.t-color-background-active-blue-2:active {
  background-color: #0B56FB !important;
}

.t-color-border-active-blue-2:active {
  border-color: #0B56FB !important;
}

.t-color-active-blue-2:active {
  color: #0B56FB !important;
}

.t-color-background-hover-blue-2:hover {
  background-color: #0B56FB !important;
}

.t-color-border-hover-blue-2:hover {
  border-color: #0B56FB !important;
}

.t-color-hover-blue-2:hover {
  color: #0B56FB !important;
}

.t-color-background-grey-background {
  background-color: #E6E6E6 !important;
}

.t-color-border-grey-background {
  border-color: #E6E6E6 !important;
}

.t-color-grey-background {
  color: #E6E6E6 !important;
}

.t-color-background-focus-grey-background:focus {
  background-color: #E6E6E6 !important;
}

.t-color-border-focus-grey-background:focus {
  border-color: #E6E6E6 !important;
}

.t-color-focus-grey-background:focus {
  color: #E6E6E6 !important;
}

.t-color-background-active-grey-background:active {
  background-color: #E6E6E6 !important;
}

.t-color-border-active-grey-background:active {
  border-color: #E6E6E6 !important;
}

.t-color-active-grey-background:active {
  color: #E6E6E6 !important;
}

.t-color-background-hover-grey-background:hover {
  background-color: #E6E6E6 !important;
}

.t-color-border-hover-grey-background:hover {
  border-color: #E6E6E6 !important;
}

.t-color-hover-grey-background:hover {
  color: #E6E6E6 !important;
}

.t-color-background-grey-1 {
  background-color: #CCCCCC !important;
}

.t-color-border-grey-1 {
  border-color: #CCCCCC !important;
}

.t-color-grey-1 {
  color: #CCCCCC !important;
}

.t-color-background-focus-grey-1:focus {
  background-color: #CCCCCC !important;
}

.t-color-border-focus-grey-1:focus {
  border-color: #CCCCCC !important;
}

.t-color-focus-grey-1:focus {
  color: #CCCCCC !important;
}

.t-color-background-active-grey-1:active {
  background-color: #CCCCCC !important;
}

.t-color-border-active-grey-1:active {
  border-color: #CCCCCC !important;
}

.t-color-active-grey-1:active {
  color: #CCCCCC !important;
}

.t-color-background-hover-grey-1:hover {
  background-color: #CCCCCC !important;
}

.t-color-border-hover-grey-1:hover {
  border-color: #CCCCCC !important;
}

.t-color-hover-grey-1:hover {
  color: #CCCCCC !important;
}

.t-color-background-grey-2 {
  background-color: #333333 !important;
}

.t-color-border-grey-2 {
  border-color: #333333 !important;
}

.t-color-grey-2 {
  color: #333333 !important;
}

.t-color-background-focus-grey-2:focus {
  background-color: #333333 !important;
}

.t-color-border-focus-grey-2:focus {
  border-color: #333333 !important;
}

.t-color-focus-grey-2:focus {
  color: #333333 !important;
}

.t-color-background-active-grey-2:active {
  background-color: #333333 !important;
}

.t-color-border-active-grey-2:active {
  border-color: #333333 !important;
}

.t-color-active-grey-2:active {
  color: #333333 !important;
}

.t-color-background-hover-grey-2:hover {
  background-color: #333333 !important;
}

.t-color-border-hover-grey-2:hover {
  border-color: #333333 !important;
}

.t-color-hover-grey-2:hover {
  color: #333333 !important;
}

.t-color-background-grey-3 {
  background-color: #666666 !important;
}

.t-color-border-grey-3 {
  border-color: #666666 !important;
}

.t-color-grey-3 {
  color: #666666 !important;
}

.t-color-background-focus-grey-3:focus {
  background-color: #666666 !important;
}

.t-color-border-focus-grey-3:focus {
  border-color: #666666 !important;
}

.t-color-focus-grey-3:focus {
  color: #666666 !important;
}

.t-color-background-active-grey-3:active {
  background-color: #666666 !important;
}

.t-color-border-active-grey-3:active {
  border-color: #666666 !important;
}

.t-color-active-grey-3:active {
  color: #666666 !important;
}

.t-color-background-hover-grey-3:hover {
  background-color: #666666 !important;
}

.t-color-border-hover-grey-3:hover {
  border-color: #666666 !important;
}

.t-color-hover-grey-3:hover {
  color: #666666 !important;
}

.t-color-background-grey-4 {
  background-color: #EEEEEE !important;
}

.t-color-border-grey-4 {
  border-color: #EEEEEE !important;
}

.t-color-grey-4 {
  color: #EEEEEE !important;
}

.t-color-background-focus-grey-4:focus {
  background-color: #EEEEEE !important;
}

.t-color-border-focus-grey-4:focus {
  border-color: #EEEEEE !important;
}

.t-color-focus-grey-4:focus {
  color: #EEEEEE !important;
}

.t-color-background-active-grey-4:active {
  background-color: #EEEEEE !important;
}

.t-color-border-active-grey-4:active {
  border-color: #EEEEEE !important;
}

.t-color-active-grey-4:active {
  color: #EEEEEE !important;
}

.t-color-background-hover-grey-4:hover {
  background-color: #EEEEEE !important;
}

.t-color-border-hover-grey-4:hover {
  border-color: #EEEEEE !important;
}

.t-color-hover-grey-4:hover {
  color: #EEEEEE !important;
}

.t-color-background-grey-5 {
  background-color: #888888 !important;
}

.t-color-border-grey-5 {
  border-color: #888888 !important;
}

.t-color-grey-5 {
  color: #888888 !important;
}

.t-color-background-focus-grey-5:focus {
  background-color: #888888 !important;
}

.t-color-border-focus-grey-5:focus {
  border-color: #888888 !important;
}

.t-color-focus-grey-5:focus {
  color: #888888 !important;
}

.t-color-background-active-grey-5:active {
  background-color: #888888 !important;
}

.t-color-border-active-grey-5:active {
  border-color: #888888 !important;
}

.t-color-active-grey-5:active {
  color: #888888 !important;
}

.t-color-background-hover-grey-5:hover {
  background-color: #888888 !important;
}

.t-color-border-hover-grey-5:hover {
  border-color: #888888 !important;
}

.t-color-hover-grey-5:hover {
  color: #888888 !important;
}

.t-color-background-success {
  background-color: #32C832 !important;
}

.t-color-border-success {
  border-color: #32C832 !important;
}

.t-color-success {
  color: #32C832 !important;
}

.t-color-background-focus-success:focus {
  background-color: #32C832 !important;
}

.t-color-border-focus-success:focus {
  border-color: #32C832 !important;
}

.t-color-focus-success:focus {
  color: #32C832 !important;
}

.t-color-background-active-success:active {
  background-color: #32C832 !important;
}

.t-color-border-active-success:active {
  border-color: #32C832 !important;
}

.t-color-active-success:active {
  color: #32C832 !important;
}

.t-color-background-hover-success:hover {
  background-color: #32C832 !important;
}

.t-color-border-hover-success:hover {
  border-color: #32C832 !important;
}

.t-color-hover-success:hover {
  color: #32C832 !important;
}

.t-color-background-info {
  background-color: #527EDB !important;
}

.t-color-border-info {
  border-color: #527EDB !important;
}

.t-color-info {
  color: #527EDB !important;
}

.t-color-background-focus-info:focus {
  background-color: #527EDB !important;
}

.t-color-border-focus-info:focus {
  border-color: #527EDB !important;
}

.t-color-focus-info:focus {
  color: #527EDB !important;
}

.t-color-background-active-info:active {
  background-color: #527EDB !important;
}

.t-color-border-active-info:active {
  border-color: #527EDB !important;
}

.t-color-active-info:active {
  color: #527EDB !important;
}

.t-color-background-hover-info:hover {
  background-color: #527EDB !important;
}

.t-color-border-hover-info:hover {
  border-color: #527EDB !important;
}

.t-color-hover-info:hover {
  color: #527EDB !important;
}

.t-color-background-error {
  background-color: #CD3C14 !important;
}

.t-color-border-error {
  border-color: #CD3C14 !important;
}

.t-color-error {
  color: #CD3C14 !important;
}

.t-color-background-focus-error:focus {
  background-color: #CD3C14 !important;
}

.t-color-border-focus-error:focus {
  border-color: #CD3C14 !important;
}

.t-color-focus-error:focus {
  color: #CD3C14 !important;
}

.t-color-background-active-error:active {
  background-color: #CD3C14 !important;
}

.t-color-border-active-error:active {
  border-color: #CD3C14 !important;
}

.t-color-active-error:active {
  color: #CD3C14 !important;
}

.t-color-background-hover-error:hover {
  background-color: #CD3C14 !important;
}

.t-color-border-hover-error:hover {
  border-color: #CD3C14 !important;
}

.t-color-hover-error:hover {
  color: #CD3C14 !important;
}

.t-color-background-unpaid {
  background-color: #C30C0C !important;
}

.t-color-border-unpaid {
  border-color: #C30C0C !important;
}

.t-color-unpaid {
  color: #C30C0C !important;
}

.t-color-background-focus-unpaid:focus {
  background-color: #C30C0C !important;
}

.t-color-border-focus-unpaid:focus {
  border-color: #C30C0C !important;
}

.t-color-focus-unpaid:focus {
  color: #C30C0C !important;
}

.t-color-background-active-unpaid:active {
  background-color: #C30C0C !important;
}

.t-color-border-active-unpaid:active {
  border-color: #C30C0C !important;
}

.t-color-active-unpaid:active {
  color: #C30C0C !important;
}

.t-color-background-hover-unpaid:hover {
  background-color: #C30C0C !important;
}

.t-color-border-hover-unpaid:hover {
  border-color: #C30C0C !important;
}

.t-color-hover-unpaid:hover {
  color: #C30C0C !important;
}

.t-color-background-warning {
  background-color: #FFCC00 !important;
}

.t-color-border-warning {
  border-color: #FFCC00 !important;
}

.t-color-warning {
  color: #FFCC00 !important;
}

.t-color-background-focus-warning:focus {
  background-color: #FFCC00 !important;
}

.t-color-border-focus-warning:focus {
  border-color: #FFCC00 !important;
}

.t-color-focus-warning:focus {
  color: #FFCC00 !important;
}

.t-color-background-active-warning:active {
  background-color: #FFCC00 !important;
}

.t-color-border-active-warning:active {
  border-color: #FFCC00 !important;
}

.t-color-active-warning:active {
  color: #FFCC00 !important;
}

.t-color-background-hover-warning:hover {
  background-color: #FFCC00 !important;
}

.t-color-border-hover-warning:hover {
  border-color: #FFCC00 !important;
}

.t-color-hover-warning:hover {
  color: #FFCC00 !important;
}

.t-color-background-yellow {
  background-color: #FECC32 !important;
}

.t-color-border-yellow {
  border-color: #FECC32 !important;
}

.t-color-yellow {
  color: #FECC32 !important;
}

.t-color-background-focus-yellow:focus {
  background-color: #FECC32 !important;
}

.t-color-border-focus-yellow:focus {
  border-color: #FECC32 !important;
}

.t-color-focus-yellow:focus {
  color: #FECC32 !important;
}

.t-color-background-active-yellow:active {
  background-color: #FECC32 !important;
}

.t-color-border-active-yellow:active {
  border-color: #FECC32 !important;
}

.t-color-active-yellow:active {
  color: #FECC32 !important;
}

.t-color-background-hover-yellow:hover {
  background-color: #FECC32 !important;
}

.t-color-border-hover-yellow:hover {
  border-color: #FECC32 !important;
}

.t-color-hover-yellow:hover {
  color: #FECC32 !important;
}

.t-color-background-strong {
  background-color: #14E003 !important;
}

.t-color-border-strong {
  border-color: #14E003 !important;
}

.t-color-strong {
  color: #14E003 !important;
}

.t-color-background-focus-strong:focus {
  background-color: #14E003 !important;
}

.t-color-border-focus-strong:focus {
  border-color: #14E003 !important;
}

.t-color-focus-strong:focus {
  color: #14E003 !important;
}

.t-color-background-active-strong:active {
  background-color: #14E003 !important;
}

.t-color-border-active-strong:active {
  border-color: #14E003 !important;
}

.t-color-active-strong:active {
  color: #14E003 !important;
}

.t-color-background-hover-strong:hover {
  background-color: #14E003 !important;
}

.t-color-border-hover-strong:hover {
  border-color: #14E003 !important;
}

.t-color-hover-strong:hover {
  color: #14E003 !important;
}

.t-color-background-medium {
  background-color: #FECC32 !important;
}

.t-color-border-medium {
  border-color: #FECC32 !important;
}

.t-color-medium {
  color: #FECC32 !important;
}

.t-color-background-focus-medium:focus {
  background-color: #FECC32 !important;
}

.t-color-border-focus-medium:focus {
  border-color: #FECC32 !important;
}

.t-color-focus-medium:focus {
  color: #FECC32 !important;
}

.t-color-background-active-medium:active {
  background-color: #FECC32 !important;
}

.t-color-border-active-medium:active {
  border-color: #FECC32 !important;
}

.t-color-active-medium:active {
  color: #FECC32 !important;
}

.t-color-background-hover-medium:hover {
  background-color: #FECC32 !important;
}

.t-color-border-hover-medium:hover {
  border-color: #FECC32 !important;
}

.t-color-hover-medium:hover {
  color: #FECC32 !important;
}

.t-color-background-weak {
  background-color: #E00303 !important;
}

.t-color-border-weak {
  border-color: #E00303 !important;
}

.t-color-weak {
  color: #E00303 !important;
}

.t-color-background-focus-weak:focus {
  background-color: #E00303 !important;
}

.t-color-border-focus-weak:focus {
  border-color: #E00303 !important;
}

.t-color-focus-weak:focus {
  color: #E00303 !important;
}

.t-color-background-active-weak:active {
  background-color: #E00303 !important;
}

.t-color-border-active-weak:active {
  border-color: #E00303 !important;
}

.t-color-active-weak:active {
  color: #E00303 !important;
}

.t-color-background-hover-weak:hover {
  background-color: #E00303 !important;
}

.t-color-border-hover-weak:hover {
  border-color: #E00303 !important;
}

.t-color-hover-weak:hover {
  color: #E00303 !important;
}

.t-color-background-disabled {
  background-color: #f1f1f1 !important;
}

.t-color-border-disabled {
  border-color: #f1f1f1 !important;
}

.t-color-disabled {
  color: #f1f1f1 !important;
}

.t-color-background-focus-disabled:focus {
  background-color: #f1f1f1 !important;
}

.t-color-border-focus-disabled:focus {
  border-color: #f1f1f1 !important;
}

.t-color-focus-disabled:focus {
  color: #f1f1f1 !important;
}

.t-color-background-active-disabled:active {
  background-color: #f1f1f1 !important;
}

.t-color-border-active-disabled:active {
  border-color: #f1f1f1 !important;
}

.t-color-active-disabled:active {
  color: #f1f1f1 !important;
}

.t-color-background-hover-disabled:hover {
  background-color: #f1f1f1 !important;
}

.t-color-border-hover-disabled:hover {
  border-color: #f1f1f1 !important;
}

.t-color-hover-disabled:hover {
  color: #f1f1f1 !important;
}

.t-color-background-verystrong {
  background-color: #005300 !important;
}

.t-color-border-verystrong {
  border-color: #005300 !important;
}

.t-color-verystrong {
  color: #005300 !important;
}

.t-color-background-focus-verystrong:focus {
  background-color: #005300 !important;
}

.t-color-border-focus-verystrong:focus {
  border-color: #005300 !important;
}

.t-color-focus-verystrong:focus {
  color: #005300 !important;
}

.t-color-background-active-verystrong:active {
  background-color: #005300 !important;
}

.t-color-border-active-verystrong:active {
  border-color: #005300 !important;
}

.t-color-active-verystrong:active {
  color: #005300 !important;
}

.t-color-background-hover-verystrong:hover {
  background-color: #005300 !important;
}

.t-color-border-hover-verystrong:hover {
  border-color: #005300 !important;
}

.t-color-hover-verystrong:hover {
  color: #005300 !important;
}

.t-color-background-pink {
  background-color: #F57DE0 !important;
}

.t-color-border-pink {
  border-color: #F57DE0 !important;
}

.t-color-pink {
  color: #F57DE0 !important;
}

.t-color-background-focus-pink:focus {
  background-color: #F57DE0 !important;
}

.t-color-border-focus-pink:focus {
  border-color: #F57DE0 !important;
}

.t-color-focus-pink:focus {
  color: #F57DE0 !important;
}

.t-color-background-active-pink:active {
  background-color: #F57DE0 !important;
}

.t-color-border-active-pink:active {
  border-color: #F57DE0 !important;
}

.t-color-active-pink:active {
  color: #F57DE0 !important;
}

.t-color-background-hover-pink:hover {
  background-color: #F57DE0 !important;
}

.t-color-border-hover-pink:hover {
  border-color: #F57DE0 !important;
}

.t-color-hover-pink:hover {
  color: #F57DE0 !important;
}

.t-visible {
  visibility: visible !important;
}

@media screen and (min-width: 480px) {
  .t-visible-xs {
    visibility: visible !important;
  }
}

@media screen and (min-width: 768px) {
  .t-visible-sm {
    visibility: visible !important;
  }
}

@media screen and (min-width: 992px) {
  .t-visible-md {
    visibility: visible !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-visible-lg {
    visibility: visible !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-visible-xl {
    visibility: visible !important;
  }
}

.t-invisible {
  visibility: hidden !important;
}

@media screen and (min-width: 480px) {
  .t-invisible-xs {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 768px) {
  .t-invisible-sm {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 992px) {
  .t-invisible-md {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-invisible-lg {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-invisible-xl {
    visibility: hidden !important;
  }
}

.t-display-none {
  display: none !important;
}

@media screen and (min-width: 480px) {
  .t-display-none-xs {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-none-sm {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-none-md {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-none-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-none-xl {
    display: none !important;
  }
}

.t-display-inline {
  display: inline !important;
}

@media screen and (min-width: 480px) {
  .t-display-inline-xs {
    display: inline !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-inline-sm {
    display: inline !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-inline-md {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-inline-lg {
    display: inline !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-inline-xl {
    display: inline !important;
  }
}

.t-display-inline-block {
  display: inline-block !important;
}

@media screen and (min-width: 480px) {
  .t-display-inline-block-xs {
    display: inline-block !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-inline-block-sm {
    display: inline-block !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-inline-block-md {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-inline-block-lg {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-inline-block-xl {
    display: inline-block !important;
  }
}

.t-display-block {
  display: block !important;
}

@media screen and (min-width: 480px) {
  .t-display-block-xs {
    display: block !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-block-sm {
    display: block !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-block-md {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-block-lg {
    display: block !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-block-xl {
    display: block !important;
  }
}

.t-display-table {
  display: table !important;
}

@media screen and (min-width: 480px) {
  .t-display-table-xs {
    display: table !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-table-sm {
    display: table !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-table-md {
    display: table !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-table-lg {
    display: table !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-table-xl {
    display: table !important;
  }
}

.t-display-table-cell {
  display: table-cell !important;
}

@media screen and (min-width: 480px) {
  .t-display-table-cell-xs {
    display: table-cell !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-table-cell-sm {
    display: table-cell !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-table-cell-md {
    display: table-cell !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-table-cell-lg {
    display: table-cell !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-table-cell-xl {
    display: table-cell !important;
  }
}

.t-display-table-row {
  display: table-row !important;
}

@media screen and (min-width: 480px) {
  .t-display-table-row-xs {
    display: table-row !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-table-row-sm {
    display: table-row !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-table-row-md {
    display: table-row !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-table-row-lg {
    display: table-row !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-table-row-xl {
    display: table-row !important;
  }
}

.t-display-flex {
  display: flex !important;
}

@media screen and (min-width: 480px) {
  .t-display-flex-xs {
    display: flex !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-flex-sm {
    display: flex !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-flex-md {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-flex-lg {
    display: flex !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-flex-xl {
    display: flex !important;
  }
}

.t-display-inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 480px) {
  .t-display-inline-flex-xs {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-inline-flex-sm {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-inline-flex-md {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-inline-flex-lg {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-inline-flex-xl {
    display: inline-flex !important;
  }
}

.t-display-list-item {
  display: list-item !important;
}

@media screen and (min-width: 480px) {
  .t-display-list-item-xs {
    display: list-item !important;
  }
}

@media screen and (min-width: 768px) {
  .t-display-list-item-sm {
    display: list-item !important;
  }
}

@media screen and (min-width: 992px) {
  .t-display-list-item-md {
    display: list-item !important;
  }
}

@media screen and (min-width: 1024px) {
  .t-display-list-item-lg {
    display: list-item !important;
  }
}

@media screen and (min-width: 1124px) {
  .t-display-list-item-xl {
    display: list-item !important;
  }
}

.t-margin-0 {
  margin: 0px !important;
}

.t-margin-top-0 {
  margin-top: 0px !important;
}

.t-margin-right-0 {
  margin-right: 0px !important;
}

.t-margin-bottom-0 {
  margin-bottom: 0px !important;
}

.t-margin-left-0 {
  margin-left: 0px !important;
}

.t-margin-1 {
  margin: 1px !important;
}

.t-margin-top-1 {
  margin-top: 1px !important;
}

.t-margin-right-1 {
  margin-right: 1px !important;
}

.t-margin-bottom-1 {
  margin-bottom: 1px !important;
}

.t-margin-left-1 {
  margin-left: 1px !important;
}

.t-margin-2 {
  margin: 2px !important;
}

.t-margin-top-2 {
  margin-top: 2px !important;
}

.t-margin-right-2 {
  margin-right: 2px !important;
}

.t-margin-bottom-2 {
  margin-bottom: 2px !important;
}

.t-margin-left-2 {
  margin-left: 2px !important;
}

.t-margin-3 {
  margin: 3px !important;
}

.t-margin-top-3 {
  margin-top: 3px !important;
}

.t-margin-right-3 {
  margin-right: 3px !important;
}

.t-margin-bottom-3 {
  margin-bottom: 3px !important;
}

.t-margin-left-3 {
  margin-left: 3px !important;
}

.t-margin-4 {
  margin: 4px !important;
}

.t-margin-top-4 {
  margin-top: 4px !important;
}

.t-margin-right-4 {
  margin-right: 4px !important;
}

.t-margin-bottom-4 {
  margin-bottom: 4px !important;
}

.t-margin-left-4 {
  margin-left: 4px !important;
}

.t-margin-5 {
  margin: 5px !important;
}

.t-margin-top-5 {
  margin-top: 5px !important;
}

.t-margin-right-5 {
  margin-right: 5px !important;
}

.t-margin-bottom-5 {
  margin-bottom: 5px !important;
}

.t-margin-left-5 {
  margin-left: 5px !important;
}

.t-margin-6 {
  margin: 6px !important;
}

.t-margin-top-6 {
  margin-top: 6px !important;
}

.t-margin-right-6 {
  margin-right: 6px !important;
}

.t-margin-bottom-6 {
  margin-bottom: 6px !important;
}

.t-margin-left-6 {
  margin-left: 6px !important;
}

.t-margin-7 {
  margin: 7px !important;
}

.t-margin-top-7 {
  margin-top: 7px !important;
}

.t-margin-right-7 {
  margin-right: 7px !important;
}

.t-margin-bottom-7 {
  margin-bottom: 7px !important;
}

.t-margin-left-7 {
  margin-left: 7px !important;
}

.t-margin-8 {
  margin: 8px !important;
}

.t-margin-top-8 {
  margin-top: 8px !important;
}

.t-margin-right-8 {
  margin-right: 8px !important;
}

.t-margin-bottom-8 {
  margin-bottom: 8px !important;
}

.t-margin-left-8 {
  margin-left: 8px !important;
}

.t-margin-9 {
  margin: 9px !important;
}

.t-margin-top-9 {
  margin-top: 9px !important;
}

.t-margin-right-9 {
  margin-right: 9px !important;
}

.t-margin-bottom-9 {
  margin-bottom: 9px !important;
}

.t-margin-left-9 {
  margin-left: 9px !important;
}

.t-margin-10 {
  margin: 10px !important;
}

.t-margin-top-10 {
  margin-top: 10px !important;
}

.t-margin-right-10 {
  margin-right: 10px !important;
}

.t-margin-bottom-10 {
  margin-bottom: 10px !important;
}

.t-margin-left-10 {
  margin-left: 10px !important;
}

.t-margin-11 {
  margin: 11px !important;
}

.t-margin-top-11 {
  margin-top: 11px !important;
}

.t-margin-right-11 {
  margin-right: 11px !important;
}

.t-margin-bottom-11 {
  margin-bottom: 11px !important;
}

.t-margin-left-11 {
  margin-left: 11px !important;
}

.t-margin-12 {
  margin: 12px !important;
}

.t-margin-top-12 {
  margin-top: 12px !important;
}

.t-margin-right-12 {
  margin-right: 12px !important;
}

.t-margin-bottom-12 {
  margin-bottom: 12px !important;
}

.t-margin-left-12 {
  margin-left: 12px !important;
}

.t-margin-13 {
  margin: 13px !important;
}

.t-margin-top-13 {
  margin-top: 13px !important;
}

.t-margin-right-13 {
  margin-right: 13px !important;
}

.t-margin-bottom-13 {
  margin-bottom: 13px !important;
}

.t-margin-left-13 {
  margin-left: 13px !important;
}

.t-margin-14 {
  margin: 14px !important;
}

.t-margin-top-14 {
  margin-top: 14px !important;
}

.t-margin-right-14 {
  margin-right: 14px !important;
}

.t-margin-bottom-14 {
  margin-bottom: 14px !important;
}

.t-margin-left-14 {
  margin-left: 14px !important;
}

.t-margin-15 {
  margin: 15px !important;
}

.t-margin-top-15 {
  margin-top: 15px !important;
}

.t-margin-right-15 {
  margin-right: 15px !important;
}

.t-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.t-margin-left-15 {
  margin-left: 15px !important;
}

.t-margin-16 {
  margin: 16px !important;
}

.t-margin-top-16 {
  margin-top: 16px !important;
}

.t-margin-right-16 {
  margin-right: 16px !important;
}

.t-margin-bottom-16 {
  margin-bottom: 16px !important;
}

.t-margin-left-16 {
  margin-left: 16px !important;
}

.t-margin-17 {
  margin: 17px !important;
}

.t-margin-top-17 {
  margin-top: 17px !important;
}

.t-margin-right-17 {
  margin-right: 17px !important;
}

.t-margin-bottom-17 {
  margin-bottom: 17px !important;
}

.t-margin-left-17 {
  margin-left: 17px !important;
}

.t-margin-18 {
  margin: 18px !important;
}

.t-margin-top-18 {
  margin-top: 18px !important;
}

.t-margin-right-18 {
  margin-right: 18px !important;
}

.t-margin-bottom-18 {
  margin-bottom: 18px !important;
}

.t-margin-left-18 {
  margin-left: 18px !important;
}

.t-margin-19 {
  margin: 19px !important;
}

.t-margin-top-19 {
  margin-top: 19px !important;
}

.t-margin-right-19 {
  margin-right: 19px !important;
}

.t-margin-bottom-19 {
  margin-bottom: 19px !important;
}

.t-margin-left-19 {
  margin-left: 19px !important;
}

.t-margin-20 {
  margin: 20px !important;
}

.t-margin-top-20 {
  margin-top: 20px !important;
}

.t-margin-right-20 {
  margin-right: 20px !important;
}

.t-margin-bottom-20 {
  margin-bottom: 20px !important;
}

.t-margin-left-20 {
  margin-left: 20px !important;
}

.t-margin-21 {
  margin: 21px !important;
}

.t-margin-top-21 {
  margin-top: 21px !important;
}

.t-margin-right-21 {
  margin-right: 21px !important;
}

.t-margin-bottom-21 {
  margin-bottom: 21px !important;
}

.t-margin-left-21 {
  margin-left: 21px !important;
}

.t-margin-22 {
  margin: 22px !important;
}

.t-margin-top-22 {
  margin-top: 22px !important;
}

.t-margin-right-22 {
  margin-right: 22px !important;
}

.t-margin-bottom-22 {
  margin-bottom: 22px !important;
}

.t-margin-left-22 {
  margin-left: 22px !important;
}

.t-margin-23 {
  margin: 23px !important;
}

.t-margin-top-23 {
  margin-top: 23px !important;
}

.t-margin-right-23 {
  margin-right: 23px !important;
}

.t-margin-bottom-23 {
  margin-bottom: 23px !important;
}

.t-margin-left-23 {
  margin-left: 23px !important;
}

.t-margin-24 {
  margin: 24px !important;
}

.t-margin-top-24 {
  margin-top: 24px !important;
}

.t-margin-right-24 {
  margin-right: 24px !important;
}

.t-margin-bottom-24 {
  margin-bottom: 24px !important;
}

.t-margin-left-24 {
  margin-left: 24px !important;
}

.t-margin-25 {
  margin: 25px !important;
}

.t-margin-top-25 {
  margin-top: 25px !important;
}

.t-margin-right-25 {
  margin-right: 25px !important;
}

.t-margin-bottom-25 {
  margin-bottom: 25px !important;
}

.t-margin-left-25 {
  margin-left: 25px !important;
}

.t-margin-26 {
  margin: 26px !important;
}

.t-margin-top-26 {
  margin-top: 26px !important;
}

.t-margin-right-26 {
  margin-right: 26px !important;
}

.t-margin-bottom-26 {
  margin-bottom: 26px !important;
}

.t-margin-left-26 {
  margin-left: 26px !important;
}

.t-margin-27 {
  margin: 27px !important;
}

.t-margin-top-27 {
  margin-top: 27px !important;
}

.t-margin-right-27 {
  margin-right: 27px !important;
}

.t-margin-bottom-27 {
  margin-bottom: 27px !important;
}

.t-margin-left-27 {
  margin-left: 27px !important;
}

.t-margin-28 {
  margin: 28px !important;
}

.t-margin-top-28 {
  margin-top: 28px !important;
}

.t-margin-right-28 {
  margin-right: 28px !important;
}

.t-margin-bottom-28 {
  margin-bottom: 28px !important;
}

.t-margin-left-28 {
  margin-left: 28px !important;
}

.t-margin-29 {
  margin: 29px !important;
}

.t-margin-top-29 {
  margin-top: 29px !important;
}

.t-margin-right-29 {
  margin-right: 29px !important;
}

.t-margin-bottom-29 {
  margin-bottom: 29px !important;
}

.t-margin-left-29 {
  margin-left: 29px !important;
}

.t-margin-30 {
  margin: 30px !important;
}

.t-margin-top-30 {
  margin-top: 30px !important;
}

.t-margin-right-30 {
  margin-right: 30px !important;
}

.t-margin-bottom-30 {
  margin-bottom: 30px !important;
}

.t-margin-left-30 {
  margin-left: 30px !important;
}

.t-margin-31 {
  margin: 31px !important;
}

.t-margin-top-31 {
  margin-top: 31px !important;
}

.t-margin-right-31 {
  margin-right: 31px !important;
}

.t-margin-bottom-31 {
  margin-bottom: 31px !important;
}

.t-margin-left-31 {
  margin-left: 31px !important;
}

.t-margin-32 {
  margin: 32px !important;
}

.t-margin-top-32 {
  margin-top: 32px !important;
}

.t-margin-right-32 {
  margin-right: 32px !important;
}

.t-margin-bottom-32 {
  margin-bottom: 32px !important;
}

.t-margin-left-32 {
  margin-left: 32px !important;
}

.t-margin-33 {
  margin: 33px !important;
}

.t-margin-top-33 {
  margin-top: 33px !important;
}

.t-margin-right-33 {
  margin-right: 33px !important;
}

.t-margin-bottom-33 {
  margin-bottom: 33px !important;
}

.t-margin-left-33 {
  margin-left: 33px !important;
}

.t-margin-34 {
  margin: 34px !important;
}

.t-margin-top-34 {
  margin-top: 34px !important;
}

.t-margin-right-34 {
  margin-right: 34px !important;
}

.t-margin-bottom-34 {
  margin-bottom: 34px !important;
}

.t-margin-left-34 {
  margin-left: 34px !important;
}

.t-margin-35 {
  margin: 35px !important;
}

.t-margin-top-35 {
  margin-top: 35px !important;
}

.t-margin-right-35 {
  margin-right: 35px !important;
}

.t-margin-bottom-35 {
  margin-bottom: 35px !important;
}

.t-margin-left-35 {
  margin-left: 35px !important;
}

.t-margin-36 {
  margin: 36px !important;
}

.t-margin-top-36 {
  margin-top: 36px !important;
}

.t-margin-right-36 {
  margin-right: 36px !important;
}

.t-margin-bottom-36 {
  margin-bottom: 36px !important;
}

.t-margin-left-36 {
  margin-left: 36px !important;
}

.t-margin-37 {
  margin: 37px !important;
}

.t-margin-top-37 {
  margin-top: 37px !important;
}

.t-margin-right-37 {
  margin-right: 37px !important;
}

.t-margin-bottom-37 {
  margin-bottom: 37px !important;
}

.t-margin-left-37 {
  margin-left: 37px !important;
}

.t-margin-38 {
  margin: 38px !important;
}

.t-margin-top-38 {
  margin-top: 38px !important;
}

.t-margin-right-38 {
  margin-right: 38px !important;
}

.t-margin-bottom-38 {
  margin-bottom: 38px !important;
}

.t-margin-left-38 {
  margin-left: 38px !important;
}

.t-margin-39 {
  margin: 39px !important;
}

.t-margin-top-39 {
  margin-top: 39px !important;
}

.t-margin-right-39 {
  margin-right: 39px !important;
}

.t-margin-bottom-39 {
  margin-bottom: 39px !important;
}

.t-margin-left-39 {
  margin-left: 39px !important;
}

.t-margin-40 {
  margin: 40px !important;
}

.t-margin-top-40 {
  margin-top: 40px !important;
}

.t-margin-right-40 {
  margin-right: 40px !important;
}

.t-margin-bottom-40 {
  margin-bottom: 40px !important;
}

.t-margin-left-40 {
  margin-left: 40px !important;
}

.t-margin-41 {
  margin: 41px !important;
}

.t-margin-top-41 {
  margin-top: 41px !important;
}

.t-margin-right-41 {
  margin-right: 41px !important;
}

.t-margin-bottom-41 {
  margin-bottom: 41px !important;
}

.t-margin-left-41 {
  margin-left: 41px !important;
}

.t-margin-42 {
  margin: 42px !important;
}

.t-margin-top-42 {
  margin-top: 42px !important;
}

.t-margin-right-42 {
  margin-right: 42px !important;
}

.t-margin-bottom-42 {
  margin-bottom: 42px !important;
}

.t-margin-left-42 {
  margin-left: 42px !important;
}

.t-margin-43 {
  margin: 43px !important;
}

.t-margin-top-43 {
  margin-top: 43px !important;
}

.t-margin-right-43 {
  margin-right: 43px !important;
}

.t-margin-bottom-43 {
  margin-bottom: 43px !important;
}

.t-margin-left-43 {
  margin-left: 43px !important;
}

.t-margin-44 {
  margin: 44px !important;
}

.t-margin-top-44 {
  margin-top: 44px !important;
}

.t-margin-right-44 {
  margin-right: 44px !important;
}

.t-margin-bottom-44 {
  margin-bottom: 44px !important;
}

.t-margin-left-44 {
  margin-left: 44px !important;
}

.t-margin-45 {
  margin: 45px !important;
}

.t-margin-top-45 {
  margin-top: 45px !important;
}

.t-margin-right-45 {
  margin-right: 45px !important;
}

.t-margin-bottom-45 {
  margin-bottom: 45px !important;
}

.t-margin-left-45 {
  margin-left: 45px !important;
}

.t-margin-46 {
  margin: 46px !important;
}

.t-margin-top-46 {
  margin-top: 46px !important;
}

.t-margin-right-46 {
  margin-right: 46px !important;
}

.t-margin-bottom-46 {
  margin-bottom: 46px !important;
}

.t-margin-left-46 {
  margin-left: 46px !important;
}

.t-margin-47 {
  margin: 47px !important;
}

.t-margin-top-47 {
  margin-top: 47px !important;
}

.t-margin-right-47 {
  margin-right: 47px !important;
}

.t-margin-bottom-47 {
  margin-bottom: 47px !important;
}

.t-margin-left-47 {
  margin-left: 47px !important;
}

.t-margin-48 {
  margin: 48px !important;
}

.t-margin-top-48 {
  margin-top: 48px !important;
}

.t-margin-right-48 {
  margin-right: 48px !important;
}

.t-margin-bottom-48 {
  margin-bottom: 48px !important;
}

.t-margin-left-48 {
  margin-left: 48px !important;
}

.t-margin-49 {
  margin: 49px !important;
}

.t-margin-top-49 {
  margin-top: 49px !important;
}

.t-margin-right-49 {
  margin-right: 49px !important;
}

.t-margin-bottom-49 {
  margin-bottom: 49px !important;
}

.t-margin-left-49 {
  margin-left: 49px !important;
}

.t-margin-50 {
  margin: 50px !important;
}

.t-margin-top-50 {
  margin-top: 50px !important;
}

.t-margin-right-50 {
  margin-right: 50px !important;
}

.t-margin-bottom-50 {
  margin-bottom: 50px !important;
}

.t-margin-left-50 {
  margin-left: 50px !important;
}

.t-margin-51 {
  margin: 51px !important;
}

.t-margin-top-51 {
  margin-top: 51px !important;
}

.t-margin-right-51 {
  margin-right: 51px !important;
}

.t-margin-bottom-51 {
  margin-bottom: 51px !important;
}

.t-margin-left-51 {
  margin-left: 51px !important;
}

.t-margin-52 {
  margin: 52px !important;
}

.t-margin-top-52 {
  margin-top: 52px !important;
}

.t-margin-right-52 {
  margin-right: 52px !important;
}

.t-margin-bottom-52 {
  margin-bottom: 52px !important;
}

.t-margin-left-52 {
  margin-left: 52px !important;
}

.t-margin-53 {
  margin: 53px !important;
}

.t-margin-top-53 {
  margin-top: 53px !important;
}

.t-margin-right-53 {
  margin-right: 53px !important;
}

.t-margin-bottom-53 {
  margin-bottom: 53px !important;
}

.t-margin-left-53 {
  margin-left: 53px !important;
}

.t-margin-54 {
  margin: 54px !important;
}

.t-margin-top-54 {
  margin-top: 54px !important;
}

.t-margin-right-54 {
  margin-right: 54px !important;
}

.t-margin-bottom-54 {
  margin-bottom: 54px !important;
}

.t-margin-left-54 {
  margin-left: 54px !important;
}

.t-margin-55 {
  margin: 55px !important;
}

.t-margin-top-55 {
  margin-top: 55px !important;
}

.t-margin-right-55 {
  margin-right: 55px !important;
}

.t-margin-bottom-55 {
  margin-bottom: 55px !important;
}

.t-margin-left-55 {
  margin-left: 55px !important;
}

.t-margin-56 {
  margin: 56px !important;
}

.t-margin-top-56 {
  margin-top: 56px !important;
}

.t-margin-right-56 {
  margin-right: 56px !important;
}

.t-margin-bottom-56 {
  margin-bottom: 56px !important;
}

.t-margin-left-56 {
  margin-left: 56px !important;
}

.t-margin-57 {
  margin: 57px !important;
}

.t-margin-top-57 {
  margin-top: 57px !important;
}

.t-margin-right-57 {
  margin-right: 57px !important;
}

.t-margin-bottom-57 {
  margin-bottom: 57px !important;
}

.t-margin-left-57 {
  margin-left: 57px !important;
}

.t-margin-58 {
  margin: 58px !important;
}

.t-margin-top-58 {
  margin-top: 58px !important;
}

.t-margin-right-58 {
  margin-right: 58px !important;
}

.t-margin-bottom-58 {
  margin-bottom: 58px !important;
}

.t-margin-left-58 {
  margin-left: 58px !important;
}

.t-margin-59 {
  margin: 59px !important;
}

.t-margin-top-59 {
  margin-top: 59px !important;
}

.t-margin-right-59 {
  margin-right: 59px !important;
}

.t-margin-bottom-59 {
  margin-bottom: 59px !important;
}

.t-margin-left-59 {
  margin-left: 59px !important;
}

.t-margin-60 {
  margin: 60px !important;
}

.t-margin-top-60 {
  margin-top: 60px !important;
}

.t-margin-right-60 {
  margin-right: 60px !important;
}

.t-margin-bottom-60 {
  margin-bottom: 60px !important;
}

.t-margin-left-60 {
  margin-left: 60px !important;
}

.t-margin-61 {
  margin: 61px !important;
}

.t-margin-top-61 {
  margin-top: 61px !important;
}

.t-margin-right-61 {
  margin-right: 61px !important;
}

.t-margin-bottom-61 {
  margin-bottom: 61px !important;
}

.t-margin-left-61 {
  margin-left: 61px !important;
}

.t-margin-62 {
  margin: 62px !important;
}

.t-margin-top-62 {
  margin-top: 62px !important;
}

.t-margin-right-62 {
  margin-right: 62px !important;
}

.t-margin-bottom-62 {
  margin-bottom: 62px !important;
}

.t-margin-left-62 {
  margin-left: 62px !important;
}

.t-margin-63 {
  margin: 63px !important;
}

.t-margin-top-63 {
  margin-top: 63px !important;
}

.t-margin-right-63 {
  margin-right: 63px !important;
}

.t-margin-bottom-63 {
  margin-bottom: 63px !important;
}

.t-margin-left-63 {
  margin-left: 63px !important;
}

.t-margin-64 {
  margin: 64px !important;
}

.t-margin-top-64 {
  margin-top: 64px !important;
}

.t-margin-right-64 {
  margin-right: 64px !important;
}

.t-margin-bottom-64 {
  margin-bottom: 64px !important;
}

.t-margin-left-64 {
  margin-left: 64px !important;
}

.t-margin-65 {
  margin: 65px !important;
}

.t-margin-top-65 {
  margin-top: 65px !important;
}

.t-margin-right-65 {
  margin-right: 65px !important;
}

.t-margin-bottom-65 {
  margin-bottom: 65px !important;
}

.t-margin-left-65 {
  margin-left: 65px !important;
}

.t-margin-66 {
  margin: 66px !important;
}

.t-margin-top-66 {
  margin-top: 66px !important;
}

.t-margin-right-66 {
  margin-right: 66px !important;
}

.t-margin-bottom-66 {
  margin-bottom: 66px !important;
}

.t-margin-left-66 {
  margin-left: 66px !important;
}

.t-margin-67 {
  margin: 67px !important;
}

.t-margin-top-67 {
  margin-top: 67px !important;
}

.t-margin-right-67 {
  margin-right: 67px !important;
}

.t-margin-bottom-67 {
  margin-bottom: 67px !important;
}

.t-margin-left-67 {
  margin-left: 67px !important;
}

.t-margin-68 {
  margin: 68px !important;
}

.t-margin-top-68 {
  margin-top: 68px !important;
}

.t-margin-right-68 {
  margin-right: 68px !important;
}

.t-margin-bottom-68 {
  margin-bottom: 68px !important;
}

.t-margin-left-68 {
  margin-left: 68px !important;
}

.t-margin-69 {
  margin: 69px !important;
}

.t-margin-top-69 {
  margin-top: 69px !important;
}

.t-margin-right-69 {
  margin-right: 69px !important;
}

.t-margin-bottom-69 {
  margin-bottom: 69px !important;
}

.t-margin-left-69 {
  margin-left: 69px !important;
}

.t-margin-70 {
  margin: 70px !important;
}

.t-margin-top-70 {
  margin-top: 70px !important;
}

.t-margin-right-70 {
  margin-right: 70px !important;
}

.t-margin-bottom-70 {
  margin-bottom: 70px !important;
}

.t-margin-left-70 {
  margin-left: 70px !important;
}

.t-margin-71 {
  margin: 71px !important;
}

.t-margin-top-71 {
  margin-top: 71px !important;
}

.t-margin-right-71 {
  margin-right: 71px !important;
}

.t-margin-bottom-71 {
  margin-bottom: 71px !important;
}

.t-margin-left-71 {
  margin-left: 71px !important;
}

.t-margin-72 {
  margin: 72px !important;
}

.t-margin-top-72 {
  margin-top: 72px !important;
}

.t-margin-right-72 {
  margin-right: 72px !important;
}

.t-margin-bottom-72 {
  margin-bottom: 72px !important;
}

.t-margin-left-72 {
  margin-left: 72px !important;
}

.t-margin-73 {
  margin: 73px !important;
}

.t-margin-top-73 {
  margin-top: 73px !important;
}

.t-margin-right-73 {
  margin-right: 73px !important;
}

.t-margin-bottom-73 {
  margin-bottom: 73px !important;
}

.t-margin-left-73 {
  margin-left: 73px !important;
}

.t-margin-74 {
  margin: 74px !important;
}

.t-margin-top-74 {
  margin-top: 74px !important;
}

.t-margin-right-74 {
  margin-right: 74px !important;
}

.t-margin-bottom-74 {
  margin-bottom: 74px !important;
}

.t-margin-left-74 {
  margin-left: 74px !important;
}

.t-margin-75 {
  margin: 75px !important;
}

.t-margin-top-75 {
  margin-top: 75px !important;
}

.t-margin-right-75 {
  margin-right: 75px !important;
}

.t-margin-bottom-75 {
  margin-bottom: 75px !important;
}

.t-margin-left-75 {
  margin-left: 75px !important;
}

.t-margin-76 {
  margin: 76px !important;
}

.t-margin-top-76 {
  margin-top: 76px !important;
}

.t-margin-right-76 {
  margin-right: 76px !important;
}

.t-margin-bottom-76 {
  margin-bottom: 76px !important;
}

.t-margin-left-76 {
  margin-left: 76px !important;
}

.t-margin-77 {
  margin: 77px !important;
}

.t-margin-top-77 {
  margin-top: 77px !important;
}

.t-margin-right-77 {
  margin-right: 77px !important;
}

.t-margin-bottom-77 {
  margin-bottom: 77px !important;
}

.t-margin-left-77 {
  margin-left: 77px !important;
}

.t-margin-78 {
  margin: 78px !important;
}

.t-margin-top-78 {
  margin-top: 78px !important;
}

.t-margin-right-78 {
  margin-right: 78px !important;
}

.t-margin-bottom-78 {
  margin-bottom: 78px !important;
}

.t-margin-left-78 {
  margin-left: 78px !important;
}

.t-margin-79 {
  margin: 79px !important;
}

.t-margin-top-79 {
  margin-top: 79px !important;
}

.t-margin-right-79 {
  margin-right: 79px !important;
}

.t-margin-bottom-79 {
  margin-bottom: 79px !important;
}

.t-margin-left-79 {
  margin-left: 79px !important;
}

.t-margin-80 {
  margin: 80px !important;
}

.t-margin-top-80 {
  margin-top: 80px !important;
}

.t-margin-right-80 {
  margin-right: 80px !important;
}

.t-margin-bottom-80 {
  margin-bottom: 80px !important;
}

.t-margin-left-80 {
  margin-left: 80px !important;
}

.t-margin-81 {
  margin: 81px !important;
}

.t-margin-top-81 {
  margin-top: 81px !important;
}

.t-margin-right-81 {
  margin-right: 81px !important;
}

.t-margin-bottom-81 {
  margin-bottom: 81px !important;
}

.t-margin-left-81 {
  margin-left: 81px !important;
}

.t-margin-82 {
  margin: 82px !important;
}

.t-margin-top-82 {
  margin-top: 82px !important;
}

.t-margin-right-82 {
  margin-right: 82px !important;
}

.t-margin-bottom-82 {
  margin-bottom: 82px !important;
}

.t-margin-left-82 {
  margin-left: 82px !important;
}

.t-margin-83 {
  margin: 83px !important;
}

.t-margin-top-83 {
  margin-top: 83px !important;
}

.t-margin-right-83 {
  margin-right: 83px !important;
}

.t-margin-bottom-83 {
  margin-bottom: 83px !important;
}

.t-margin-left-83 {
  margin-left: 83px !important;
}

.t-margin-84 {
  margin: 84px !important;
}

.t-margin-top-84 {
  margin-top: 84px !important;
}

.t-margin-right-84 {
  margin-right: 84px !important;
}

.t-margin-bottom-84 {
  margin-bottom: 84px !important;
}

.t-margin-left-84 {
  margin-left: 84px !important;
}

.t-margin-85 {
  margin: 85px !important;
}

.t-margin-top-85 {
  margin-top: 85px !important;
}

.t-margin-right-85 {
  margin-right: 85px !important;
}

.t-margin-bottom-85 {
  margin-bottom: 85px !important;
}

.t-margin-left-85 {
  margin-left: 85px !important;
}

.t-margin-86 {
  margin: 86px !important;
}

.t-margin-top-86 {
  margin-top: 86px !important;
}

.t-margin-right-86 {
  margin-right: 86px !important;
}

.t-margin-bottom-86 {
  margin-bottom: 86px !important;
}

.t-margin-left-86 {
  margin-left: 86px !important;
}

.t-margin-87 {
  margin: 87px !important;
}

.t-margin-top-87 {
  margin-top: 87px !important;
}

.t-margin-right-87 {
  margin-right: 87px !important;
}

.t-margin-bottom-87 {
  margin-bottom: 87px !important;
}

.t-margin-left-87 {
  margin-left: 87px !important;
}

.t-margin-88 {
  margin: 88px !important;
}

.t-margin-top-88 {
  margin-top: 88px !important;
}

.t-margin-right-88 {
  margin-right: 88px !important;
}

.t-margin-bottom-88 {
  margin-bottom: 88px !important;
}

.t-margin-left-88 {
  margin-left: 88px !important;
}

.t-margin-89 {
  margin: 89px !important;
}

.t-margin-top-89 {
  margin-top: 89px !important;
}

.t-margin-right-89 {
  margin-right: 89px !important;
}

.t-margin-bottom-89 {
  margin-bottom: 89px !important;
}

.t-margin-left-89 {
  margin-left: 89px !important;
}

.t-margin-90 {
  margin: 90px !important;
}

.t-margin-top-90 {
  margin-top: 90px !important;
}

.t-margin-right-90 {
  margin-right: 90px !important;
}

.t-margin-bottom-90 {
  margin-bottom: 90px !important;
}

.t-margin-left-90 {
  margin-left: 90px !important;
}

.t-margin-91 {
  margin: 91px !important;
}

.t-margin-top-91 {
  margin-top: 91px !important;
}

.t-margin-right-91 {
  margin-right: 91px !important;
}

.t-margin-bottom-91 {
  margin-bottom: 91px !important;
}

.t-margin-left-91 {
  margin-left: 91px !important;
}

.t-margin-92 {
  margin: 92px !important;
}

.t-margin-top-92 {
  margin-top: 92px !important;
}

.t-margin-right-92 {
  margin-right: 92px !important;
}

.t-margin-bottom-92 {
  margin-bottom: 92px !important;
}

.t-margin-left-92 {
  margin-left: 92px !important;
}

.t-margin-93 {
  margin: 93px !important;
}

.t-margin-top-93 {
  margin-top: 93px !important;
}

.t-margin-right-93 {
  margin-right: 93px !important;
}

.t-margin-bottom-93 {
  margin-bottom: 93px !important;
}

.t-margin-left-93 {
  margin-left: 93px !important;
}

.t-margin-94 {
  margin: 94px !important;
}

.t-margin-top-94 {
  margin-top: 94px !important;
}

.t-margin-right-94 {
  margin-right: 94px !important;
}

.t-margin-bottom-94 {
  margin-bottom: 94px !important;
}

.t-margin-left-94 {
  margin-left: 94px !important;
}

.t-margin-95 {
  margin: 95px !important;
}

.t-margin-top-95 {
  margin-top: 95px !important;
}

.t-margin-right-95 {
  margin-right: 95px !important;
}

.t-margin-bottom-95 {
  margin-bottom: 95px !important;
}

.t-margin-left-95 {
  margin-left: 95px !important;
}

.t-margin-96 {
  margin: 96px !important;
}

.t-margin-top-96 {
  margin-top: 96px !important;
}

.t-margin-right-96 {
  margin-right: 96px !important;
}

.t-margin-bottom-96 {
  margin-bottom: 96px !important;
}

.t-margin-left-96 {
  margin-left: 96px !important;
}

.t-margin-97 {
  margin: 97px !important;
}

.t-margin-top-97 {
  margin-top: 97px !important;
}

.t-margin-right-97 {
  margin-right: 97px !important;
}

.t-margin-bottom-97 {
  margin-bottom: 97px !important;
}

.t-margin-left-97 {
  margin-left: 97px !important;
}

.t-margin-98 {
  margin: 98px !important;
}

.t-margin-top-98 {
  margin-top: 98px !important;
}

.t-margin-right-98 {
  margin-right: 98px !important;
}

.t-margin-bottom-98 {
  margin-bottom: 98px !important;
}

.t-margin-left-98 {
  margin-left: 98px !important;
}

.t-margin-99 {
  margin: 99px !important;
}

.t-margin-top-99 {
  margin-top: 99px !important;
}

.t-margin-right-99 {
  margin-right: 99px !important;
}

.t-margin-bottom-99 {
  margin-bottom: 99px !important;
}

.t-margin-left-99 {
  margin-left: 99px !important;
}

.t-margin-100 {
  margin: 100px !important;
}

.t-margin-top-100 {
  margin-top: 100px !important;
}

.t-margin-right-100 {
  margin-right: 100px !important;
}

.t-margin-bottom-100 {
  margin-bottom: 100px !important;
}

.t-margin-left-100 {
  margin-left: 100px !important;
}

.t-margin-101 {
  margin: 101px !important;
}

.t-margin-top-101 {
  margin-top: 101px !important;
}

.t-margin-right-101 {
  margin-right: 101px !important;
}

.t-margin-bottom-101 {
  margin-bottom: 101px !important;
}

.t-margin-left-101 {
  margin-left: 101px !important;
}

.t-margin-102 {
  margin: 102px !important;
}

.t-margin-top-102 {
  margin-top: 102px !important;
}

.t-margin-right-102 {
  margin-right: 102px !important;
}

.t-margin-bottom-102 {
  margin-bottom: 102px !important;
}

.t-margin-left-102 {
  margin-left: 102px !important;
}

.t-margin-103 {
  margin: 103px !important;
}

.t-margin-top-103 {
  margin-top: 103px !important;
}

.t-margin-right-103 {
  margin-right: 103px !important;
}

.t-margin-bottom-103 {
  margin-bottom: 103px !important;
}

.t-margin-left-103 {
  margin-left: 103px !important;
}

.t-margin-104 {
  margin: 104px !important;
}

.t-margin-top-104 {
  margin-top: 104px !important;
}

.t-margin-right-104 {
  margin-right: 104px !important;
}

.t-margin-bottom-104 {
  margin-bottom: 104px !important;
}

.t-margin-left-104 {
  margin-left: 104px !important;
}

.t-margin-105 {
  margin: 105px !important;
}

.t-margin-top-105 {
  margin-top: 105px !important;
}

.t-margin-right-105 {
  margin-right: 105px !important;
}

.t-margin-bottom-105 {
  margin-bottom: 105px !important;
}

.t-margin-left-105 {
  margin-left: 105px !important;
}

.t-margin-106 {
  margin: 106px !important;
}

.t-margin-top-106 {
  margin-top: 106px !important;
}

.t-margin-right-106 {
  margin-right: 106px !important;
}

.t-margin-bottom-106 {
  margin-bottom: 106px !important;
}

.t-margin-left-106 {
  margin-left: 106px !important;
}

.t-margin-107 {
  margin: 107px !important;
}

.t-margin-top-107 {
  margin-top: 107px !important;
}

.t-margin-right-107 {
  margin-right: 107px !important;
}

.t-margin-bottom-107 {
  margin-bottom: 107px !important;
}

.t-margin-left-107 {
  margin-left: 107px !important;
}

.t-margin-108 {
  margin: 108px !important;
}

.t-margin-top-108 {
  margin-top: 108px !important;
}

.t-margin-right-108 {
  margin-right: 108px !important;
}

.t-margin-bottom-108 {
  margin-bottom: 108px !important;
}

.t-margin-left-108 {
  margin-left: 108px !important;
}

.t-margin-109 {
  margin: 109px !important;
}

.t-margin-top-109 {
  margin-top: 109px !important;
}

.t-margin-right-109 {
  margin-right: 109px !important;
}

.t-margin-bottom-109 {
  margin-bottom: 109px !important;
}

.t-margin-left-109 {
  margin-left: 109px !important;
}

.t-margin-110 {
  margin: 110px !important;
}

.t-margin-top-110 {
  margin-top: 110px !important;
}

.t-margin-right-110 {
  margin-right: 110px !important;
}

.t-margin-bottom-110 {
  margin-bottom: 110px !important;
}

.t-margin-left-110 {
  margin-left: 110px !important;
}

.t-margin-111 {
  margin: 111px !important;
}

.t-margin-top-111 {
  margin-top: 111px !important;
}

.t-margin-right-111 {
  margin-right: 111px !important;
}

.t-margin-bottom-111 {
  margin-bottom: 111px !important;
}

.t-margin-left-111 {
  margin-left: 111px !important;
}

.t-margin-112 {
  margin: 112px !important;
}

.t-margin-top-112 {
  margin-top: 112px !important;
}

.t-margin-right-112 {
  margin-right: 112px !important;
}

.t-margin-bottom-112 {
  margin-bottom: 112px !important;
}

.t-margin-left-112 {
  margin-left: 112px !important;
}

.t-margin-113 {
  margin: 113px !important;
}

.t-margin-top-113 {
  margin-top: 113px !important;
}

.t-margin-right-113 {
  margin-right: 113px !important;
}

.t-margin-bottom-113 {
  margin-bottom: 113px !important;
}

.t-margin-left-113 {
  margin-left: 113px !important;
}

.t-margin-114 {
  margin: 114px !important;
}

.t-margin-top-114 {
  margin-top: 114px !important;
}

.t-margin-right-114 {
  margin-right: 114px !important;
}

.t-margin-bottom-114 {
  margin-bottom: 114px !important;
}

.t-margin-left-114 {
  margin-left: 114px !important;
}

.t-margin-115 {
  margin: 115px !important;
}

.t-margin-top-115 {
  margin-top: 115px !important;
}

.t-margin-right-115 {
  margin-right: 115px !important;
}

.t-margin-bottom-115 {
  margin-bottom: 115px !important;
}

.t-margin-left-115 {
  margin-left: 115px !important;
}

.t-margin-116 {
  margin: 116px !important;
}

.t-margin-top-116 {
  margin-top: 116px !important;
}

.t-margin-right-116 {
  margin-right: 116px !important;
}

.t-margin-bottom-116 {
  margin-bottom: 116px !important;
}

.t-margin-left-116 {
  margin-left: 116px !important;
}

.t-margin-117 {
  margin: 117px !important;
}

.t-margin-top-117 {
  margin-top: 117px !important;
}

.t-margin-right-117 {
  margin-right: 117px !important;
}

.t-margin-bottom-117 {
  margin-bottom: 117px !important;
}

.t-margin-left-117 {
  margin-left: 117px !important;
}

.t-margin-118 {
  margin: 118px !important;
}

.t-margin-top-118 {
  margin-top: 118px !important;
}

.t-margin-right-118 {
  margin-right: 118px !important;
}

.t-margin-bottom-118 {
  margin-bottom: 118px !important;
}

.t-margin-left-118 {
  margin-left: 118px !important;
}

.t-margin-119 {
  margin: 119px !important;
}

.t-margin-top-119 {
  margin-top: 119px !important;
}

.t-margin-right-119 {
  margin-right: 119px !important;
}

.t-margin-bottom-119 {
  margin-bottom: 119px !important;
}

.t-margin-left-119 {
  margin-left: 119px !important;
}

.t-margin-120 {
  margin: 120px !important;
}

.t-margin-top-120 {
  margin-top: 120px !important;
}

.t-margin-right-120 {
  margin-right: 120px !important;
}

.t-margin-bottom-120 {
  margin-bottom: 120px !important;
}

.t-margin-left-120 {
  margin-left: 120px !important;
}

.t-margin-121 {
  margin: 121px !important;
}

.t-margin-top-121 {
  margin-top: 121px !important;
}

.t-margin-right-121 {
  margin-right: 121px !important;
}

.t-margin-bottom-121 {
  margin-bottom: 121px !important;
}

.t-margin-left-121 {
  margin-left: 121px !important;
}

.t-margin-122 {
  margin: 122px !important;
}

.t-margin-top-122 {
  margin-top: 122px !important;
}

.t-margin-right-122 {
  margin-right: 122px !important;
}

.t-margin-bottom-122 {
  margin-bottom: 122px !important;
}

.t-margin-left-122 {
  margin-left: 122px !important;
}

.t-margin-123 {
  margin: 123px !important;
}

.t-margin-top-123 {
  margin-top: 123px !important;
}

.t-margin-right-123 {
  margin-right: 123px !important;
}

.t-margin-bottom-123 {
  margin-bottom: 123px !important;
}

.t-margin-left-123 {
  margin-left: 123px !important;
}

.t-margin-124 {
  margin: 124px !important;
}

.t-margin-top-124 {
  margin-top: 124px !important;
}

.t-margin-right-124 {
  margin-right: 124px !important;
}

.t-margin-bottom-124 {
  margin-bottom: 124px !important;
}

.t-margin-left-124 {
  margin-left: 124px !important;
}

.t-margin-125 {
  margin: 125px !important;
}

.t-margin-top-125 {
  margin-top: 125px !important;
}

.t-margin-right-125 {
  margin-right: 125px !important;
}

.t-margin-bottom-125 {
  margin-bottom: 125px !important;
}

.t-margin-left-125 {
  margin-left: 125px !important;
}

.t-margin-126 {
  margin: 126px !important;
}

.t-margin-top-126 {
  margin-top: 126px !important;
}

.t-margin-right-126 {
  margin-right: 126px !important;
}

.t-margin-bottom-126 {
  margin-bottom: 126px !important;
}

.t-margin-left-126 {
  margin-left: 126px !important;
}

.t-margin-127 {
  margin: 127px !important;
}

.t-margin-top-127 {
  margin-top: 127px !important;
}

.t-margin-right-127 {
  margin-right: 127px !important;
}

.t-margin-bottom-127 {
  margin-bottom: 127px !important;
}

.t-margin-left-127 {
  margin-left: 127px !important;
}

.t-margin-128 {
  margin: 128px !important;
}

.t-margin-top-128 {
  margin-top: 128px !important;
}

.t-margin-right-128 {
  margin-right: 128px !important;
}

.t-margin-bottom-128 {
  margin-bottom: 128px !important;
}

.t-margin-left-128 {
  margin-left: 128px !important;
}

.t-margin-129 {
  margin: 129px !important;
}

.t-margin-top-129 {
  margin-top: 129px !important;
}

.t-margin-right-129 {
  margin-right: 129px !important;
}

.t-margin-bottom-129 {
  margin-bottom: 129px !important;
}

.t-margin-left-129 {
  margin-left: 129px !important;
}

.t-margin-130 {
  margin: 130px !important;
}

.t-margin-top-130 {
  margin-top: 130px !important;
}

.t-margin-right-130 {
  margin-right: 130px !important;
}

.t-margin-bottom-130 {
  margin-bottom: 130px !important;
}

.t-margin-left-130 {
  margin-left: 130px !important;
}

.t-margin-131 {
  margin: 131px !important;
}

.t-margin-top-131 {
  margin-top: 131px !important;
}

.t-margin-right-131 {
  margin-right: 131px !important;
}

.t-margin-bottom-131 {
  margin-bottom: 131px !important;
}

.t-margin-left-131 {
  margin-left: 131px !important;
}

.t-margin-132 {
  margin: 132px !important;
}

.t-margin-top-132 {
  margin-top: 132px !important;
}

.t-margin-right-132 {
  margin-right: 132px !important;
}

.t-margin-bottom-132 {
  margin-bottom: 132px !important;
}

.t-margin-left-132 {
  margin-left: 132px !important;
}

.t-margin-133 {
  margin: 133px !important;
}

.t-margin-top-133 {
  margin-top: 133px !important;
}

.t-margin-right-133 {
  margin-right: 133px !important;
}

.t-margin-bottom-133 {
  margin-bottom: 133px !important;
}

.t-margin-left-133 {
  margin-left: 133px !important;
}

.t-margin-134 {
  margin: 134px !important;
}

.t-margin-top-134 {
  margin-top: 134px !important;
}

.t-margin-right-134 {
  margin-right: 134px !important;
}

.t-margin-bottom-134 {
  margin-bottom: 134px !important;
}

.t-margin-left-134 {
  margin-left: 134px !important;
}

.t-margin-135 {
  margin: 135px !important;
}

.t-margin-top-135 {
  margin-top: 135px !important;
}

.t-margin-right-135 {
  margin-right: 135px !important;
}

.t-margin-bottom-135 {
  margin-bottom: 135px !important;
}

.t-margin-left-135 {
  margin-left: 135px !important;
}

.t-margin-136 {
  margin: 136px !important;
}

.t-margin-top-136 {
  margin-top: 136px !important;
}

.t-margin-right-136 {
  margin-right: 136px !important;
}

.t-margin-bottom-136 {
  margin-bottom: 136px !important;
}

.t-margin-left-136 {
  margin-left: 136px !important;
}

.t-margin-137 {
  margin: 137px !important;
}

.t-margin-top-137 {
  margin-top: 137px !important;
}

.t-margin-right-137 {
  margin-right: 137px !important;
}

.t-margin-bottom-137 {
  margin-bottom: 137px !important;
}

.t-margin-left-137 {
  margin-left: 137px !important;
}

.t-margin-138 {
  margin: 138px !important;
}

.t-margin-top-138 {
  margin-top: 138px !important;
}

.t-margin-right-138 {
  margin-right: 138px !important;
}

.t-margin-bottom-138 {
  margin-bottom: 138px !important;
}

.t-margin-left-138 {
  margin-left: 138px !important;
}

.t-margin-139 {
  margin: 139px !important;
}

.t-margin-top-139 {
  margin-top: 139px !important;
}

.t-margin-right-139 {
  margin-right: 139px !important;
}

.t-margin-bottom-139 {
  margin-bottom: 139px !important;
}

.t-margin-left-139 {
  margin-left: 139px !important;
}

.t-margin-140 {
  margin: 140px !important;
}

.t-margin-top-140 {
  margin-top: 140px !important;
}

.t-margin-right-140 {
  margin-right: 140px !important;
}

.t-margin-bottom-140 {
  margin-bottom: 140px !important;
}

.t-margin-left-140 {
  margin-left: 140px !important;
}

.t-margin-141 {
  margin: 141px !important;
}

.t-margin-top-141 {
  margin-top: 141px !important;
}

.t-margin-right-141 {
  margin-right: 141px !important;
}

.t-margin-bottom-141 {
  margin-bottom: 141px !important;
}

.t-margin-left-141 {
  margin-left: 141px !important;
}

.t-margin-142 {
  margin: 142px !important;
}

.t-margin-top-142 {
  margin-top: 142px !important;
}

.t-margin-right-142 {
  margin-right: 142px !important;
}

.t-margin-bottom-142 {
  margin-bottom: 142px !important;
}

.t-margin-left-142 {
  margin-left: 142px !important;
}

.t-margin-143 {
  margin: 143px !important;
}

.t-margin-top-143 {
  margin-top: 143px !important;
}

.t-margin-right-143 {
  margin-right: 143px !important;
}

.t-margin-bottom-143 {
  margin-bottom: 143px !important;
}

.t-margin-left-143 {
  margin-left: 143px !important;
}

.t-margin-144 {
  margin: 144px !important;
}

.t-margin-top-144 {
  margin-top: 144px !important;
}

.t-margin-right-144 {
  margin-right: 144px !important;
}

.t-margin-bottom-144 {
  margin-bottom: 144px !important;
}

.t-margin-left-144 {
  margin-left: 144px !important;
}

.t-margin-145 {
  margin: 145px !important;
}

.t-margin-top-145 {
  margin-top: 145px !important;
}

.t-margin-right-145 {
  margin-right: 145px !important;
}

.t-margin-bottom-145 {
  margin-bottom: 145px !important;
}

.t-margin-left-145 {
  margin-left: 145px !important;
}

.t-margin-146 {
  margin: 146px !important;
}

.t-margin-top-146 {
  margin-top: 146px !important;
}

.t-margin-right-146 {
  margin-right: 146px !important;
}

.t-margin-bottom-146 {
  margin-bottom: 146px !important;
}

.t-margin-left-146 {
  margin-left: 146px !important;
}

.t-margin-147 {
  margin: 147px !important;
}

.t-margin-top-147 {
  margin-top: 147px !important;
}

.t-margin-right-147 {
  margin-right: 147px !important;
}

.t-margin-bottom-147 {
  margin-bottom: 147px !important;
}

.t-margin-left-147 {
  margin-left: 147px !important;
}

.t-margin-148 {
  margin: 148px !important;
}

.t-margin-top-148 {
  margin-top: 148px !important;
}

.t-margin-right-148 {
  margin-right: 148px !important;
}

.t-margin-bottom-148 {
  margin-bottom: 148px !important;
}

.t-margin-left-148 {
  margin-left: 148px !important;
}

.t-margin-149 {
  margin: 149px !important;
}

.t-margin-top-149 {
  margin-top: 149px !important;
}

.t-margin-right-149 {
  margin-right: 149px !important;
}

.t-margin-bottom-149 {
  margin-bottom: 149px !important;
}

.t-margin-left-149 {
  margin-left: 149px !important;
}

.t-margin-150 {
  margin: 150px !important;
}

.t-margin-top-150 {
  margin-top: 150px !important;
}

.t-margin-right-150 {
  margin-right: 150px !important;
}

.t-margin-bottom-150 {
  margin-bottom: 150px !important;
}

.t-margin-left-150 {
  margin-left: 150px !important;
}

.t-margin-151 {
  margin: 151px !important;
}

.t-margin-top-151 {
  margin-top: 151px !important;
}

.t-margin-right-151 {
  margin-right: 151px !important;
}

.t-margin-bottom-151 {
  margin-bottom: 151px !important;
}

.t-margin-left-151 {
  margin-left: 151px !important;
}

.t-margin-152 {
  margin: 152px !important;
}

.t-margin-top-152 {
  margin-top: 152px !important;
}

.t-margin-right-152 {
  margin-right: 152px !important;
}

.t-margin-bottom-152 {
  margin-bottom: 152px !important;
}

.t-margin-left-152 {
  margin-left: 152px !important;
}

.t-margin-153 {
  margin: 153px !important;
}

.t-margin-top-153 {
  margin-top: 153px !important;
}

.t-margin-right-153 {
  margin-right: 153px !important;
}

.t-margin-bottom-153 {
  margin-bottom: 153px !important;
}

.t-margin-left-153 {
  margin-left: 153px !important;
}

.t-margin-154 {
  margin: 154px !important;
}

.t-margin-top-154 {
  margin-top: 154px !important;
}

.t-margin-right-154 {
  margin-right: 154px !important;
}

.t-margin-bottom-154 {
  margin-bottom: 154px !important;
}

.t-margin-left-154 {
  margin-left: 154px !important;
}

.t-margin-155 {
  margin: 155px !important;
}

.t-margin-top-155 {
  margin-top: 155px !important;
}

.t-margin-right-155 {
  margin-right: 155px !important;
}

.t-margin-bottom-155 {
  margin-bottom: 155px !important;
}

.t-margin-left-155 {
  margin-left: 155px !important;
}

.t-margin-156 {
  margin: 156px !important;
}

.t-margin-top-156 {
  margin-top: 156px !important;
}

.t-margin-right-156 {
  margin-right: 156px !important;
}

.t-margin-bottom-156 {
  margin-bottom: 156px !important;
}

.t-margin-left-156 {
  margin-left: 156px !important;
}

.t-margin-157 {
  margin: 157px !important;
}

.t-margin-top-157 {
  margin-top: 157px !important;
}

.t-margin-right-157 {
  margin-right: 157px !important;
}

.t-margin-bottom-157 {
  margin-bottom: 157px !important;
}

.t-margin-left-157 {
  margin-left: 157px !important;
}

.t-margin-158 {
  margin: 158px !important;
}

.t-margin-top-158 {
  margin-top: 158px !important;
}

.t-margin-right-158 {
  margin-right: 158px !important;
}

.t-margin-bottom-158 {
  margin-bottom: 158px !important;
}

.t-margin-left-158 {
  margin-left: 158px !important;
}

.t-margin-159 {
  margin: 159px !important;
}

.t-margin-top-159 {
  margin-top: 159px !important;
}

.t-margin-right-159 {
  margin-right: 159px !important;
}

.t-margin-bottom-159 {
  margin-bottom: 159px !important;
}

.t-margin-left-159 {
  margin-left: 159px !important;
}

.t-margin-160 {
  margin: 160px !important;
}

.t-margin-top-160 {
  margin-top: 160px !important;
}

.t-margin-right-160 {
  margin-right: 160px !important;
}

.t-margin-bottom-160 {
  margin-bottom: 160px !important;
}

.t-margin-left-160 {
  margin-left: 160px !important;
}

.t-margin-161 {
  margin: 161px !important;
}

.t-margin-top-161 {
  margin-top: 161px !important;
}

.t-margin-right-161 {
  margin-right: 161px !important;
}

.t-margin-bottom-161 {
  margin-bottom: 161px !important;
}

.t-margin-left-161 {
  margin-left: 161px !important;
}

.t-margin-162 {
  margin: 162px !important;
}

.t-margin-top-162 {
  margin-top: 162px !important;
}

.t-margin-right-162 {
  margin-right: 162px !important;
}

.t-margin-bottom-162 {
  margin-bottom: 162px !important;
}

.t-margin-left-162 {
  margin-left: 162px !important;
}

.t-margin-163 {
  margin: 163px !important;
}

.t-margin-top-163 {
  margin-top: 163px !important;
}

.t-margin-right-163 {
  margin-right: 163px !important;
}

.t-margin-bottom-163 {
  margin-bottom: 163px !important;
}

.t-margin-left-163 {
  margin-left: 163px !important;
}

.t-margin-164 {
  margin: 164px !important;
}

.t-margin-top-164 {
  margin-top: 164px !important;
}

.t-margin-right-164 {
  margin-right: 164px !important;
}

.t-margin-bottom-164 {
  margin-bottom: 164px !important;
}

.t-margin-left-164 {
  margin-left: 164px !important;
}

.t-margin-165 {
  margin: 165px !important;
}

.t-margin-top-165 {
  margin-top: 165px !important;
}

.t-margin-right-165 {
  margin-right: 165px !important;
}

.t-margin-bottom-165 {
  margin-bottom: 165px !important;
}

.t-margin-left-165 {
  margin-left: 165px !important;
}

.t-margin-166 {
  margin: 166px !important;
}

.t-margin-top-166 {
  margin-top: 166px !important;
}

.t-margin-right-166 {
  margin-right: 166px !important;
}

.t-margin-bottom-166 {
  margin-bottom: 166px !important;
}

.t-margin-left-166 {
  margin-left: 166px !important;
}

.t-margin-167 {
  margin: 167px !important;
}

.t-margin-top-167 {
  margin-top: 167px !important;
}

.t-margin-right-167 {
  margin-right: 167px !important;
}

.t-margin-bottom-167 {
  margin-bottom: 167px !important;
}

.t-margin-left-167 {
  margin-left: 167px !important;
}

.t-margin-168 {
  margin: 168px !important;
}

.t-margin-top-168 {
  margin-top: 168px !important;
}

.t-margin-right-168 {
  margin-right: 168px !important;
}

.t-margin-bottom-168 {
  margin-bottom: 168px !important;
}

.t-margin-left-168 {
  margin-left: 168px !important;
}

.t-margin-169 {
  margin: 169px !important;
}

.t-margin-top-169 {
  margin-top: 169px !important;
}

.t-margin-right-169 {
  margin-right: 169px !important;
}

.t-margin-bottom-169 {
  margin-bottom: 169px !important;
}

.t-margin-left-169 {
  margin-left: 169px !important;
}

.t-margin-170 {
  margin: 170px !important;
}

.t-margin-top-170 {
  margin-top: 170px !important;
}

.t-margin-right-170 {
  margin-right: 170px !important;
}

.t-margin-bottom-170 {
  margin-bottom: 170px !important;
}

.t-margin-left-170 {
  margin-left: 170px !important;
}

.t-margin-171 {
  margin: 171px !important;
}

.t-margin-top-171 {
  margin-top: 171px !important;
}

.t-margin-right-171 {
  margin-right: 171px !important;
}

.t-margin-bottom-171 {
  margin-bottom: 171px !important;
}

.t-margin-left-171 {
  margin-left: 171px !important;
}

.t-margin-172 {
  margin: 172px !important;
}

.t-margin-top-172 {
  margin-top: 172px !important;
}

.t-margin-right-172 {
  margin-right: 172px !important;
}

.t-margin-bottom-172 {
  margin-bottom: 172px !important;
}

.t-margin-left-172 {
  margin-left: 172px !important;
}

.t-margin-173 {
  margin: 173px !important;
}

.t-margin-top-173 {
  margin-top: 173px !important;
}

.t-margin-right-173 {
  margin-right: 173px !important;
}

.t-margin-bottom-173 {
  margin-bottom: 173px !important;
}

.t-margin-left-173 {
  margin-left: 173px !important;
}

.t-margin-174 {
  margin: 174px !important;
}

.t-margin-top-174 {
  margin-top: 174px !important;
}

.t-margin-right-174 {
  margin-right: 174px !important;
}

.t-margin-bottom-174 {
  margin-bottom: 174px !important;
}

.t-margin-left-174 {
  margin-left: 174px !important;
}

.t-margin-175 {
  margin: 175px !important;
}

.t-margin-top-175 {
  margin-top: 175px !important;
}

.t-margin-right-175 {
  margin-right: 175px !important;
}

.t-margin-bottom-175 {
  margin-bottom: 175px !important;
}

.t-margin-left-175 {
  margin-left: 175px !important;
}

.t-margin-176 {
  margin: 176px !important;
}

.t-margin-top-176 {
  margin-top: 176px !important;
}

.t-margin-right-176 {
  margin-right: 176px !important;
}

.t-margin-bottom-176 {
  margin-bottom: 176px !important;
}

.t-margin-left-176 {
  margin-left: 176px !important;
}

.t-margin-177 {
  margin: 177px !important;
}

.t-margin-top-177 {
  margin-top: 177px !important;
}

.t-margin-right-177 {
  margin-right: 177px !important;
}

.t-margin-bottom-177 {
  margin-bottom: 177px !important;
}

.t-margin-left-177 {
  margin-left: 177px !important;
}

.t-margin-178 {
  margin: 178px !important;
}

.t-margin-top-178 {
  margin-top: 178px !important;
}

.t-margin-right-178 {
  margin-right: 178px !important;
}

.t-margin-bottom-178 {
  margin-bottom: 178px !important;
}

.t-margin-left-178 {
  margin-left: 178px !important;
}

.t-margin-179 {
  margin: 179px !important;
}

.t-margin-top-179 {
  margin-top: 179px !important;
}

.t-margin-right-179 {
  margin-right: 179px !important;
}

.t-margin-bottom-179 {
  margin-bottom: 179px !important;
}

.t-margin-left-179 {
  margin-left: 179px !important;
}

.t-margin-180 {
  margin: 180px !important;
}

.t-margin-top-180 {
  margin-top: 180px !important;
}

.t-margin-right-180 {
  margin-right: 180px !important;
}

.t-margin-bottom-180 {
  margin-bottom: 180px !important;
}

.t-margin-left-180 {
  margin-left: 180px !important;
}

.t-margin-181 {
  margin: 181px !important;
}

.t-margin-top-181 {
  margin-top: 181px !important;
}

.t-margin-right-181 {
  margin-right: 181px !important;
}

.t-margin-bottom-181 {
  margin-bottom: 181px !important;
}

.t-margin-left-181 {
  margin-left: 181px !important;
}

.t-margin-182 {
  margin: 182px !important;
}

.t-margin-top-182 {
  margin-top: 182px !important;
}

.t-margin-right-182 {
  margin-right: 182px !important;
}

.t-margin-bottom-182 {
  margin-bottom: 182px !important;
}

.t-margin-left-182 {
  margin-left: 182px !important;
}

.t-margin-183 {
  margin: 183px !important;
}

.t-margin-top-183 {
  margin-top: 183px !important;
}

.t-margin-right-183 {
  margin-right: 183px !important;
}

.t-margin-bottom-183 {
  margin-bottom: 183px !important;
}

.t-margin-left-183 {
  margin-left: 183px !important;
}

.t-margin-184 {
  margin: 184px !important;
}

.t-margin-top-184 {
  margin-top: 184px !important;
}

.t-margin-right-184 {
  margin-right: 184px !important;
}

.t-margin-bottom-184 {
  margin-bottom: 184px !important;
}

.t-margin-left-184 {
  margin-left: 184px !important;
}

.t-margin-185 {
  margin: 185px !important;
}

.t-margin-top-185 {
  margin-top: 185px !important;
}

.t-margin-right-185 {
  margin-right: 185px !important;
}

.t-margin-bottom-185 {
  margin-bottom: 185px !important;
}

.t-margin-left-185 {
  margin-left: 185px !important;
}

.t-margin-186 {
  margin: 186px !important;
}

.t-margin-top-186 {
  margin-top: 186px !important;
}

.t-margin-right-186 {
  margin-right: 186px !important;
}

.t-margin-bottom-186 {
  margin-bottom: 186px !important;
}

.t-margin-left-186 {
  margin-left: 186px !important;
}

.t-margin-187 {
  margin: 187px !important;
}

.t-margin-top-187 {
  margin-top: 187px !important;
}

.t-margin-right-187 {
  margin-right: 187px !important;
}

.t-margin-bottom-187 {
  margin-bottom: 187px !important;
}

.t-margin-left-187 {
  margin-left: 187px !important;
}

.t-margin-188 {
  margin: 188px !important;
}

.t-margin-top-188 {
  margin-top: 188px !important;
}

.t-margin-right-188 {
  margin-right: 188px !important;
}

.t-margin-bottom-188 {
  margin-bottom: 188px !important;
}

.t-margin-left-188 {
  margin-left: 188px !important;
}

.t-margin-189 {
  margin: 189px !important;
}

.t-margin-top-189 {
  margin-top: 189px !important;
}

.t-margin-right-189 {
  margin-right: 189px !important;
}

.t-margin-bottom-189 {
  margin-bottom: 189px !important;
}

.t-margin-left-189 {
  margin-left: 189px !important;
}

.t-margin-190 {
  margin: 190px !important;
}

.t-margin-top-190 {
  margin-top: 190px !important;
}

.t-margin-right-190 {
  margin-right: 190px !important;
}

.t-margin-bottom-190 {
  margin-bottom: 190px !important;
}

.t-margin-left-190 {
  margin-left: 190px !important;
}

.t-margin-191 {
  margin: 191px !important;
}

.t-margin-top-191 {
  margin-top: 191px !important;
}

.t-margin-right-191 {
  margin-right: 191px !important;
}

.t-margin-bottom-191 {
  margin-bottom: 191px !important;
}

.t-margin-left-191 {
  margin-left: 191px !important;
}

.t-margin-192 {
  margin: 192px !important;
}

.t-margin-top-192 {
  margin-top: 192px !important;
}

.t-margin-right-192 {
  margin-right: 192px !important;
}

.t-margin-bottom-192 {
  margin-bottom: 192px !important;
}

.t-margin-left-192 {
  margin-left: 192px !important;
}

.t-margin-193 {
  margin: 193px !important;
}

.t-margin-top-193 {
  margin-top: 193px !important;
}

.t-margin-right-193 {
  margin-right: 193px !important;
}

.t-margin-bottom-193 {
  margin-bottom: 193px !important;
}

.t-margin-left-193 {
  margin-left: 193px !important;
}

.t-margin-194 {
  margin: 194px !important;
}

.t-margin-top-194 {
  margin-top: 194px !important;
}

.t-margin-right-194 {
  margin-right: 194px !important;
}

.t-margin-bottom-194 {
  margin-bottom: 194px !important;
}

.t-margin-left-194 {
  margin-left: 194px !important;
}

.t-margin-195 {
  margin: 195px !important;
}

.t-margin-top-195 {
  margin-top: 195px !important;
}

.t-margin-right-195 {
  margin-right: 195px !important;
}

.t-margin-bottom-195 {
  margin-bottom: 195px !important;
}

.t-margin-left-195 {
  margin-left: 195px !important;
}

.t-margin-196 {
  margin: 196px !important;
}

.t-margin-top-196 {
  margin-top: 196px !important;
}

.t-margin-right-196 {
  margin-right: 196px !important;
}

.t-margin-bottom-196 {
  margin-bottom: 196px !important;
}

.t-margin-left-196 {
  margin-left: 196px !important;
}

.t-margin-197 {
  margin: 197px !important;
}

.t-margin-top-197 {
  margin-top: 197px !important;
}

.t-margin-right-197 {
  margin-right: 197px !important;
}

.t-margin-bottom-197 {
  margin-bottom: 197px !important;
}

.t-margin-left-197 {
  margin-left: 197px !important;
}

.t-margin-198 {
  margin: 198px !important;
}

.t-margin-top-198 {
  margin-top: 198px !important;
}

.t-margin-right-198 {
  margin-right: 198px !important;
}

.t-margin-bottom-198 {
  margin-bottom: 198px !important;
}

.t-margin-left-198 {
  margin-left: 198px !important;
}

.t-margin-199 {
  margin: 199px !important;
}

.t-margin-top-199 {
  margin-top: 199px !important;
}

.t-margin-right-199 {
  margin-right: 199px !important;
}

.t-margin-bottom-199 {
  margin-bottom: 199px !important;
}

.t-margin-left-199 {
  margin-left: 199px !important;
}

.t-margin-200 {
  margin: 200px !important;
}

.t-margin-top-200 {
  margin-top: 200px !important;
}

.t-margin-right-200 {
  margin-right: 200px !important;
}

.t-margin-bottom-200 {
  margin-bottom: 200px !important;
}

.t-margin-left-200 {
  margin-left: 200px !important;
}

.t-margin-201 {
  margin: 201px !important;
}

.t-margin-top-201 {
  margin-top: 201px !important;
}

.t-margin-right-201 {
  margin-right: 201px !important;
}

.t-margin-bottom-201 {
  margin-bottom: 201px !important;
}

.t-margin-left-201 {
  margin-left: 201px !important;
}

.t-margin-202 {
  margin: 202px !important;
}

.t-margin-top-202 {
  margin-top: 202px !important;
}

.t-margin-right-202 {
  margin-right: 202px !important;
}

.t-margin-bottom-202 {
  margin-bottom: 202px !important;
}

.t-margin-left-202 {
  margin-left: 202px !important;
}

.t-margin-203 {
  margin: 203px !important;
}

.t-margin-top-203 {
  margin-top: 203px !important;
}

.t-margin-right-203 {
  margin-right: 203px !important;
}

.t-margin-bottom-203 {
  margin-bottom: 203px !important;
}

.t-margin-left-203 {
  margin-left: 203px !important;
}

.t-margin-204 {
  margin: 204px !important;
}

.t-margin-top-204 {
  margin-top: 204px !important;
}

.t-margin-right-204 {
  margin-right: 204px !important;
}

.t-margin-bottom-204 {
  margin-bottom: 204px !important;
}

.t-margin-left-204 {
  margin-left: 204px !important;
}

.t-margin-205 {
  margin: 205px !important;
}

.t-margin-top-205 {
  margin-top: 205px !important;
}

.t-margin-right-205 {
  margin-right: 205px !important;
}

.t-margin-bottom-205 {
  margin-bottom: 205px !important;
}

.t-margin-left-205 {
  margin-left: 205px !important;
}

.t-margin-206 {
  margin: 206px !important;
}

.t-margin-top-206 {
  margin-top: 206px !important;
}

.t-margin-right-206 {
  margin-right: 206px !important;
}

.t-margin-bottom-206 {
  margin-bottom: 206px !important;
}

.t-margin-left-206 {
  margin-left: 206px !important;
}

.t-margin-207 {
  margin: 207px !important;
}

.t-margin-top-207 {
  margin-top: 207px !important;
}

.t-margin-right-207 {
  margin-right: 207px !important;
}

.t-margin-bottom-207 {
  margin-bottom: 207px !important;
}

.t-margin-left-207 {
  margin-left: 207px !important;
}

.t-margin-208 {
  margin: 208px !important;
}

.t-margin-top-208 {
  margin-top: 208px !important;
}

.t-margin-right-208 {
  margin-right: 208px !important;
}

.t-margin-bottom-208 {
  margin-bottom: 208px !important;
}

.t-margin-left-208 {
  margin-left: 208px !important;
}

.t-margin-209 {
  margin: 209px !important;
}

.t-margin-top-209 {
  margin-top: 209px !important;
}

.t-margin-right-209 {
  margin-right: 209px !important;
}

.t-margin-bottom-209 {
  margin-bottom: 209px !important;
}

.t-margin-left-209 {
  margin-left: 209px !important;
}

.t-margin-210 {
  margin: 210px !important;
}

.t-margin-top-210 {
  margin-top: 210px !important;
}

.t-margin-right-210 {
  margin-right: 210px !important;
}

.t-margin-bottom-210 {
  margin-bottom: 210px !important;
}

.t-margin-left-210 {
  margin-left: 210px !important;
}

.t-margin-211 {
  margin: 211px !important;
}

.t-margin-top-211 {
  margin-top: 211px !important;
}

.t-margin-right-211 {
  margin-right: 211px !important;
}

.t-margin-bottom-211 {
  margin-bottom: 211px !important;
}

.t-margin-left-211 {
  margin-left: 211px !important;
}

.t-margin-212 {
  margin: 212px !important;
}

.t-margin-top-212 {
  margin-top: 212px !important;
}

.t-margin-right-212 {
  margin-right: 212px !important;
}

.t-margin-bottom-212 {
  margin-bottom: 212px !important;
}

.t-margin-left-212 {
  margin-left: 212px !important;
}

.t-margin-213 {
  margin: 213px !important;
}

.t-margin-top-213 {
  margin-top: 213px !important;
}

.t-margin-right-213 {
  margin-right: 213px !important;
}

.t-margin-bottom-213 {
  margin-bottom: 213px !important;
}

.t-margin-left-213 {
  margin-left: 213px !important;
}

.t-margin-214 {
  margin: 214px !important;
}

.t-margin-top-214 {
  margin-top: 214px !important;
}

.t-margin-right-214 {
  margin-right: 214px !important;
}

.t-margin-bottom-214 {
  margin-bottom: 214px !important;
}

.t-margin-left-214 {
  margin-left: 214px !important;
}

.t-margin-215 {
  margin: 215px !important;
}

.t-margin-top-215 {
  margin-top: 215px !important;
}

.t-margin-right-215 {
  margin-right: 215px !important;
}

.t-margin-bottom-215 {
  margin-bottom: 215px !important;
}

.t-margin-left-215 {
  margin-left: 215px !important;
}

.t-margin-216 {
  margin: 216px !important;
}

.t-margin-top-216 {
  margin-top: 216px !important;
}

.t-margin-right-216 {
  margin-right: 216px !important;
}

.t-margin-bottom-216 {
  margin-bottom: 216px !important;
}

.t-margin-left-216 {
  margin-left: 216px !important;
}

.t-margin-217 {
  margin: 217px !important;
}

.t-margin-top-217 {
  margin-top: 217px !important;
}

.t-margin-right-217 {
  margin-right: 217px !important;
}

.t-margin-bottom-217 {
  margin-bottom: 217px !important;
}

.t-margin-left-217 {
  margin-left: 217px !important;
}

.t-margin-218 {
  margin: 218px !important;
}

.t-margin-top-218 {
  margin-top: 218px !important;
}

.t-margin-right-218 {
  margin-right: 218px !important;
}

.t-margin-bottom-218 {
  margin-bottom: 218px !important;
}

.t-margin-left-218 {
  margin-left: 218px !important;
}

.t-margin-219 {
  margin: 219px !important;
}

.t-margin-top-219 {
  margin-top: 219px !important;
}

.t-margin-right-219 {
  margin-right: 219px !important;
}

.t-margin-bottom-219 {
  margin-bottom: 219px !important;
}

.t-margin-left-219 {
  margin-left: 219px !important;
}

.t-margin-220 {
  margin: 220px !important;
}

.t-margin-top-220 {
  margin-top: 220px !important;
}

.t-margin-right-220 {
  margin-right: 220px !important;
}

.t-margin-bottom-220 {
  margin-bottom: 220px !important;
}

.t-margin-left-220 {
  margin-left: 220px !important;
}

.t-margin-221 {
  margin: 221px !important;
}

.t-margin-top-221 {
  margin-top: 221px !important;
}

.t-margin-right-221 {
  margin-right: 221px !important;
}

.t-margin-bottom-221 {
  margin-bottom: 221px !important;
}

.t-margin-left-221 {
  margin-left: 221px !important;
}

.t-margin-222 {
  margin: 222px !important;
}

.t-margin-top-222 {
  margin-top: 222px !important;
}

.t-margin-right-222 {
  margin-right: 222px !important;
}

.t-margin-bottom-222 {
  margin-bottom: 222px !important;
}

.t-margin-left-222 {
  margin-left: 222px !important;
}

.t-margin-223 {
  margin: 223px !important;
}

.t-margin-top-223 {
  margin-top: 223px !important;
}

.t-margin-right-223 {
  margin-right: 223px !important;
}

.t-margin-bottom-223 {
  margin-bottom: 223px !important;
}

.t-margin-left-223 {
  margin-left: 223px !important;
}

.t-margin-224 {
  margin: 224px !important;
}

.t-margin-top-224 {
  margin-top: 224px !important;
}

.t-margin-right-224 {
  margin-right: 224px !important;
}

.t-margin-bottom-224 {
  margin-bottom: 224px !important;
}

.t-margin-left-224 {
  margin-left: 224px !important;
}

.t-margin-225 {
  margin: 225px !important;
}

.t-margin-top-225 {
  margin-top: 225px !important;
}

.t-margin-right-225 {
  margin-right: 225px !important;
}

.t-margin-bottom-225 {
  margin-bottom: 225px !important;
}

.t-margin-left-225 {
  margin-left: 225px !important;
}

.t-margin-226 {
  margin: 226px !important;
}

.t-margin-top-226 {
  margin-top: 226px !important;
}

.t-margin-right-226 {
  margin-right: 226px !important;
}

.t-margin-bottom-226 {
  margin-bottom: 226px !important;
}

.t-margin-left-226 {
  margin-left: 226px !important;
}

.t-margin-227 {
  margin: 227px !important;
}

.t-margin-top-227 {
  margin-top: 227px !important;
}

.t-margin-right-227 {
  margin-right: 227px !important;
}

.t-margin-bottom-227 {
  margin-bottom: 227px !important;
}

.t-margin-left-227 {
  margin-left: 227px !important;
}

.t-margin-228 {
  margin: 228px !important;
}

.t-margin-top-228 {
  margin-top: 228px !important;
}

.t-margin-right-228 {
  margin-right: 228px !important;
}

.t-margin-bottom-228 {
  margin-bottom: 228px !important;
}

.t-margin-left-228 {
  margin-left: 228px !important;
}

.t-margin-229 {
  margin: 229px !important;
}

.t-margin-top-229 {
  margin-top: 229px !important;
}

.t-margin-right-229 {
  margin-right: 229px !important;
}

.t-margin-bottom-229 {
  margin-bottom: 229px !important;
}

.t-margin-left-229 {
  margin-left: 229px !important;
}

.t-margin-230 {
  margin: 230px !important;
}

.t-margin-top-230 {
  margin-top: 230px !important;
}

.t-margin-right-230 {
  margin-right: 230px !important;
}

.t-margin-bottom-230 {
  margin-bottom: 230px !important;
}

.t-margin-left-230 {
  margin-left: 230px !important;
}

.t-margin-231 {
  margin: 231px !important;
}

.t-margin-top-231 {
  margin-top: 231px !important;
}

.t-margin-right-231 {
  margin-right: 231px !important;
}

.t-margin-bottom-231 {
  margin-bottom: 231px !important;
}

.t-margin-left-231 {
  margin-left: 231px !important;
}

.t-margin-232 {
  margin: 232px !important;
}

.t-margin-top-232 {
  margin-top: 232px !important;
}

.t-margin-right-232 {
  margin-right: 232px !important;
}

.t-margin-bottom-232 {
  margin-bottom: 232px !important;
}

.t-margin-left-232 {
  margin-left: 232px !important;
}

.t-margin-233 {
  margin: 233px !important;
}

.t-margin-top-233 {
  margin-top: 233px !important;
}

.t-margin-right-233 {
  margin-right: 233px !important;
}

.t-margin-bottom-233 {
  margin-bottom: 233px !important;
}

.t-margin-left-233 {
  margin-left: 233px !important;
}

.t-margin-234 {
  margin: 234px !important;
}

.t-margin-top-234 {
  margin-top: 234px !important;
}

.t-margin-right-234 {
  margin-right: 234px !important;
}

.t-margin-bottom-234 {
  margin-bottom: 234px !important;
}

.t-margin-left-234 {
  margin-left: 234px !important;
}

.t-margin-235 {
  margin: 235px !important;
}

.t-margin-top-235 {
  margin-top: 235px !important;
}

.t-margin-right-235 {
  margin-right: 235px !important;
}

.t-margin-bottom-235 {
  margin-bottom: 235px !important;
}

.t-margin-left-235 {
  margin-left: 235px !important;
}

.t-margin-236 {
  margin: 236px !important;
}

.t-margin-top-236 {
  margin-top: 236px !important;
}

.t-margin-right-236 {
  margin-right: 236px !important;
}

.t-margin-bottom-236 {
  margin-bottom: 236px !important;
}

.t-margin-left-236 {
  margin-left: 236px !important;
}

.t-margin-237 {
  margin: 237px !important;
}

.t-margin-top-237 {
  margin-top: 237px !important;
}

.t-margin-right-237 {
  margin-right: 237px !important;
}

.t-margin-bottom-237 {
  margin-bottom: 237px !important;
}

.t-margin-left-237 {
  margin-left: 237px !important;
}

.t-margin-238 {
  margin: 238px !important;
}

.t-margin-top-238 {
  margin-top: 238px !important;
}

.t-margin-right-238 {
  margin-right: 238px !important;
}

.t-margin-bottom-238 {
  margin-bottom: 238px !important;
}

.t-margin-left-238 {
  margin-left: 238px !important;
}

.t-margin-239 {
  margin: 239px !important;
}

.t-margin-top-239 {
  margin-top: 239px !important;
}

.t-margin-right-239 {
  margin-right: 239px !important;
}

.t-margin-bottom-239 {
  margin-bottom: 239px !important;
}

.t-margin-left-239 {
  margin-left: 239px !important;
}

.t-margin-240 {
  margin: 240px !important;
}

.t-margin-top-240 {
  margin-top: 240px !important;
}

.t-margin-right-240 {
  margin-right: 240px !important;
}

.t-margin-bottom-240 {
  margin-bottom: 240px !important;
}

.t-margin-left-240 {
  margin-left: 240px !important;
}

.t-margin-241 {
  margin: 241px !important;
}

.t-margin-top-241 {
  margin-top: 241px !important;
}

.t-margin-right-241 {
  margin-right: 241px !important;
}

.t-margin-bottom-241 {
  margin-bottom: 241px !important;
}

.t-margin-left-241 {
  margin-left: 241px !important;
}

.t-margin-242 {
  margin: 242px !important;
}

.t-margin-top-242 {
  margin-top: 242px !important;
}

.t-margin-right-242 {
  margin-right: 242px !important;
}

.t-margin-bottom-242 {
  margin-bottom: 242px !important;
}

.t-margin-left-242 {
  margin-left: 242px !important;
}

.t-margin-243 {
  margin: 243px !important;
}

.t-margin-top-243 {
  margin-top: 243px !important;
}

.t-margin-right-243 {
  margin-right: 243px !important;
}

.t-margin-bottom-243 {
  margin-bottom: 243px !important;
}

.t-margin-left-243 {
  margin-left: 243px !important;
}

.t-margin-244 {
  margin: 244px !important;
}

.t-margin-top-244 {
  margin-top: 244px !important;
}

.t-margin-right-244 {
  margin-right: 244px !important;
}

.t-margin-bottom-244 {
  margin-bottom: 244px !important;
}

.t-margin-left-244 {
  margin-left: 244px !important;
}

.t-margin-245 {
  margin: 245px !important;
}

.t-margin-top-245 {
  margin-top: 245px !important;
}

.t-margin-right-245 {
  margin-right: 245px !important;
}

.t-margin-bottom-245 {
  margin-bottom: 245px !important;
}

.t-margin-left-245 {
  margin-left: 245px !important;
}

.t-margin-246 {
  margin: 246px !important;
}

.t-margin-top-246 {
  margin-top: 246px !important;
}

.t-margin-right-246 {
  margin-right: 246px !important;
}

.t-margin-bottom-246 {
  margin-bottom: 246px !important;
}

.t-margin-left-246 {
  margin-left: 246px !important;
}

.t-margin-247 {
  margin: 247px !important;
}

.t-margin-top-247 {
  margin-top: 247px !important;
}

.t-margin-right-247 {
  margin-right: 247px !important;
}

.t-margin-bottom-247 {
  margin-bottom: 247px !important;
}

.t-margin-left-247 {
  margin-left: 247px !important;
}

.t-margin-248 {
  margin: 248px !important;
}

.t-margin-top-248 {
  margin-top: 248px !important;
}

.t-margin-right-248 {
  margin-right: 248px !important;
}

.t-margin-bottom-248 {
  margin-bottom: 248px !important;
}

.t-margin-left-248 {
  margin-left: 248px !important;
}

.t-margin-249 {
  margin: 249px !important;
}

.t-margin-top-249 {
  margin-top: 249px !important;
}

.t-margin-right-249 {
  margin-right: 249px !important;
}

.t-margin-bottom-249 {
  margin-bottom: 249px !important;
}

.t-margin-left-249 {
  margin-left: 249px !important;
}

.t-margin-250 {
  margin: 250px !important;
}

.t-margin-top-250 {
  margin-top: 250px !important;
}

.t-margin-right-250 {
  margin-right: 250px !important;
}

.t-margin-bottom-250 {
  margin-bottom: 250px !important;
}

.t-margin-left-250 {
  margin-left: 250px !important;
}

.t-margin-251 {
  margin: 251px !important;
}

.t-margin-top-251 {
  margin-top: 251px !important;
}

.t-margin-right-251 {
  margin-right: 251px !important;
}

.t-margin-bottom-251 {
  margin-bottom: 251px !important;
}

.t-margin-left-251 {
  margin-left: 251px !important;
}

.t-margin-252 {
  margin: 252px !important;
}

.t-margin-top-252 {
  margin-top: 252px !important;
}

.t-margin-right-252 {
  margin-right: 252px !important;
}

.t-margin-bottom-252 {
  margin-bottom: 252px !important;
}

.t-margin-left-252 {
  margin-left: 252px !important;
}

.t-margin-253 {
  margin: 253px !important;
}

.t-margin-top-253 {
  margin-top: 253px !important;
}

.t-margin-right-253 {
  margin-right: 253px !important;
}

.t-margin-bottom-253 {
  margin-bottom: 253px !important;
}

.t-margin-left-253 {
  margin-left: 253px !important;
}

.t-margin-254 {
  margin: 254px !important;
}

.t-margin-top-254 {
  margin-top: 254px !important;
}

.t-margin-right-254 {
  margin-right: 254px !important;
}

.t-margin-bottom-254 {
  margin-bottom: 254px !important;
}

.t-margin-left-254 {
  margin-left: 254px !important;
}

.t-margin-255 {
  margin: 255px !important;
}

.t-margin-top-255 {
  margin-top: 255px !important;
}

.t-margin-right-255 {
  margin-right: 255px !important;
}

.t-margin-bottom-255 {
  margin-bottom: 255px !important;
}

.t-margin-left-255 {
  margin-left: 255px !important;
}

.t-margin-256 {
  margin: 256px !important;
}

.t-margin-top-256 {
  margin-top: 256px !important;
}

.t-margin-right-256 {
  margin-right: 256px !important;
}

.t-margin-bottom-256 {
  margin-bottom: 256px !important;
}

.t-margin-left-256 {
  margin-left: 256px !important;
}

.t-margin-257 {
  margin: 257px !important;
}

.t-margin-top-257 {
  margin-top: 257px !important;
}

.t-margin-right-257 {
  margin-right: 257px !important;
}

.t-margin-bottom-257 {
  margin-bottom: 257px !important;
}

.t-margin-left-257 {
  margin-left: 257px !important;
}

.t-margin-258 {
  margin: 258px !important;
}

.t-margin-top-258 {
  margin-top: 258px !important;
}

.t-margin-right-258 {
  margin-right: 258px !important;
}

.t-margin-bottom-258 {
  margin-bottom: 258px !important;
}

.t-margin-left-258 {
  margin-left: 258px !important;
}

.t-margin-259 {
  margin: 259px !important;
}

.t-margin-top-259 {
  margin-top: 259px !important;
}

.t-margin-right-259 {
  margin-right: 259px !important;
}

.t-margin-bottom-259 {
  margin-bottom: 259px !important;
}

.t-margin-left-259 {
  margin-left: 259px !important;
}

.t-margin-260 {
  margin: 260px !important;
}

.t-margin-top-260 {
  margin-top: 260px !important;
}

.t-margin-right-260 {
  margin-right: 260px !important;
}

.t-margin-bottom-260 {
  margin-bottom: 260px !important;
}

.t-margin-left-260 {
  margin-left: 260px !important;
}

.t-margin-261 {
  margin: 261px !important;
}

.t-margin-top-261 {
  margin-top: 261px !important;
}

.t-margin-right-261 {
  margin-right: 261px !important;
}

.t-margin-bottom-261 {
  margin-bottom: 261px !important;
}

.t-margin-left-261 {
  margin-left: 261px !important;
}

.t-margin-262 {
  margin: 262px !important;
}

.t-margin-top-262 {
  margin-top: 262px !important;
}

.t-margin-right-262 {
  margin-right: 262px !important;
}

.t-margin-bottom-262 {
  margin-bottom: 262px !important;
}

.t-margin-left-262 {
  margin-left: 262px !important;
}

.t-margin-263 {
  margin: 263px !important;
}

.t-margin-top-263 {
  margin-top: 263px !important;
}

.t-margin-right-263 {
  margin-right: 263px !important;
}

.t-margin-bottom-263 {
  margin-bottom: 263px !important;
}

.t-margin-left-263 {
  margin-left: 263px !important;
}

.t-margin-264 {
  margin: 264px !important;
}

.t-margin-top-264 {
  margin-top: 264px !important;
}

.t-margin-right-264 {
  margin-right: 264px !important;
}

.t-margin-bottom-264 {
  margin-bottom: 264px !important;
}

.t-margin-left-264 {
  margin-left: 264px !important;
}

.t-margin-265 {
  margin: 265px !important;
}

.t-margin-top-265 {
  margin-top: 265px !important;
}

.t-margin-right-265 {
  margin-right: 265px !important;
}

.t-margin-bottom-265 {
  margin-bottom: 265px !important;
}

.t-margin-left-265 {
  margin-left: 265px !important;
}

.t-margin-266 {
  margin: 266px !important;
}

.t-margin-top-266 {
  margin-top: 266px !important;
}

.t-margin-right-266 {
  margin-right: 266px !important;
}

.t-margin-bottom-266 {
  margin-bottom: 266px !important;
}

.t-margin-left-266 {
  margin-left: 266px !important;
}

.t-margin-267 {
  margin: 267px !important;
}

.t-margin-top-267 {
  margin-top: 267px !important;
}

.t-margin-right-267 {
  margin-right: 267px !important;
}

.t-margin-bottom-267 {
  margin-bottom: 267px !important;
}

.t-margin-left-267 {
  margin-left: 267px !important;
}

.t-margin-268 {
  margin: 268px !important;
}

.t-margin-top-268 {
  margin-top: 268px !important;
}

.t-margin-right-268 {
  margin-right: 268px !important;
}

.t-margin-bottom-268 {
  margin-bottom: 268px !important;
}

.t-margin-left-268 {
  margin-left: 268px !important;
}

.t-margin-269 {
  margin: 269px !important;
}

.t-margin-top-269 {
  margin-top: 269px !important;
}

.t-margin-right-269 {
  margin-right: 269px !important;
}

.t-margin-bottom-269 {
  margin-bottom: 269px !important;
}

.t-margin-left-269 {
  margin-left: 269px !important;
}

.t-margin-270 {
  margin: 270px !important;
}

.t-margin-top-270 {
  margin-top: 270px !important;
}

.t-margin-right-270 {
  margin-right: 270px !important;
}

.t-margin-bottom-270 {
  margin-bottom: 270px !important;
}

.t-margin-left-270 {
  margin-left: 270px !important;
}

.t-margin-271 {
  margin: 271px !important;
}

.t-margin-top-271 {
  margin-top: 271px !important;
}

.t-margin-right-271 {
  margin-right: 271px !important;
}

.t-margin-bottom-271 {
  margin-bottom: 271px !important;
}

.t-margin-left-271 {
  margin-left: 271px !important;
}

.t-margin-272 {
  margin: 272px !important;
}

.t-margin-top-272 {
  margin-top: 272px !important;
}

.t-margin-right-272 {
  margin-right: 272px !important;
}

.t-margin-bottom-272 {
  margin-bottom: 272px !important;
}

.t-margin-left-272 {
  margin-left: 272px !important;
}

.t-margin-273 {
  margin: 273px !important;
}

.t-margin-top-273 {
  margin-top: 273px !important;
}

.t-margin-right-273 {
  margin-right: 273px !important;
}

.t-margin-bottom-273 {
  margin-bottom: 273px !important;
}

.t-margin-left-273 {
  margin-left: 273px !important;
}

.t-margin-274 {
  margin: 274px !important;
}

.t-margin-top-274 {
  margin-top: 274px !important;
}

.t-margin-right-274 {
  margin-right: 274px !important;
}

.t-margin-bottom-274 {
  margin-bottom: 274px !important;
}

.t-margin-left-274 {
  margin-left: 274px !important;
}

.t-margin-275 {
  margin: 275px !important;
}

.t-margin-top-275 {
  margin-top: 275px !important;
}

.t-margin-right-275 {
  margin-right: 275px !important;
}

.t-margin-bottom-275 {
  margin-bottom: 275px !important;
}

.t-margin-left-275 {
  margin-left: 275px !important;
}

.t-margin-276 {
  margin: 276px !important;
}

.t-margin-top-276 {
  margin-top: 276px !important;
}

.t-margin-right-276 {
  margin-right: 276px !important;
}

.t-margin-bottom-276 {
  margin-bottom: 276px !important;
}

.t-margin-left-276 {
  margin-left: 276px !important;
}

.t-margin-277 {
  margin: 277px !important;
}

.t-margin-top-277 {
  margin-top: 277px !important;
}

.t-margin-right-277 {
  margin-right: 277px !important;
}

.t-margin-bottom-277 {
  margin-bottom: 277px !important;
}

.t-margin-left-277 {
  margin-left: 277px !important;
}

.t-margin-278 {
  margin: 278px !important;
}

.t-margin-top-278 {
  margin-top: 278px !important;
}

.t-margin-right-278 {
  margin-right: 278px !important;
}

.t-margin-bottom-278 {
  margin-bottom: 278px !important;
}

.t-margin-left-278 {
  margin-left: 278px !important;
}

.t-margin-279 {
  margin: 279px !important;
}

.t-margin-top-279 {
  margin-top: 279px !important;
}

.t-margin-right-279 {
  margin-right: 279px !important;
}

.t-margin-bottom-279 {
  margin-bottom: 279px !important;
}

.t-margin-left-279 {
  margin-left: 279px !important;
}

.t-margin-280 {
  margin: 280px !important;
}

.t-margin-top-280 {
  margin-top: 280px !important;
}

.t-margin-right-280 {
  margin-right: 280px !important;
}

.t-margin-bottom-280 {
  margin-bottom: 280px !important;
}

.t-margin-left-280 {
  margin-left: 280px !important;
}

.t-margin-281 {
  margin: 281px !important;
}

.t-margin-top-281 {
  margin-top: 281px !important;
}

.t-margin-right-281 {
  margin-right: 281px !important;
}

.t-margin-bottom-281 {
  margin-bottom: 281px !important;
}

.t-margin-left-281 {
  margin-left: 281px !important;
}

.t-margin-282 {
  margin: 282px !important;
}

.t-margin-top-282 {
  margin-top: 282px !important;
}

.t-margin-right-282 {
  margin-right: 282px !important;
}

.t-margin-bottom-282 {
  margin-bottom: 282px !important;
}

.t-margin-left-282 {
  margin-left: 282px !important;
}

.t-margin-283 {
  margin: 283px !important;
}

.t-margin-top-283 {
  margin-top: 283px !important;
}

.t-margin-right-283 {
  margin-right: 283px !important;
}

.t-margin-bottom-283 {
  margin-bottom: 283px !important;
}

.t-margin-left-283 {
  margin-left: 283px !important;
}

.t-margin-284 {
  margin: 284px !important;
}

.t-margin-top-284 {
  margin-top: 284px !important;
}

.t-margin-right-284 {
  margin-right: 284px !important;
}

.t-margin-bottom-284 {
  margin-bottom: 284px !important;
}

.t-margin-left-284 {
  margin-left: 284px !important;
}

.t-margin-285 {
  margin: 285px !important;
}

.t-margin-top-285 {
  margin-top: 285px !important;
}

.t-margin-right-285 {
  margin-right: 285px !important;
}

.t-margin-bottom-285 {
  margin-bottom: 285px !important;
}

.t-margin-left-285 {
  margin-left: 285px !important;
}

.t-margin-286 {
  margin: 286px !important;
}

.t-margin-top-286 {
  margin-top: 286px !important;
}

.t-margin-right-286 {
  margin-right: 286px !important;
}

.t-margin-bottom-286 {
  margin-bottom: 286px !important;
}

.t-margin-left-286 {
  margin-left: 286px !important;
}

.t-margin-287 {
  margin: 287px !important;
}

.t-margin-top-287 {
  margin-top: 287px !important;
}

.t-margin-right-287 {
  margin-right: 287px !important;
}

.t-margin-bottom-287 {
  margin-bottom: 287px !important;
}

.t-margin-left-287 {
  margin-left: 287px !important;
}

.t-margin-288 {
  margin: 288px !important;
}

.t-margin-top-288 {
  margin-top: 288px !important;
}

.t-margin-right-288 {
  margin-right: 288px !important;
}

.t-margin-bottom-288 {
  margin-bottom: 288px !important;
}

.t-margin-left-288 {
  margin-left: 288px !important;
}

.t-margin-289 {
  margin: 289px !important;
}

.t-margin-top-289 {
  margin-top: 289px !important;
}

.t-margin-right-289 {
  margin-right: 289px !important;
}

.t-margin-bottom-289 {
  margin-bottom: 289px !important;
}

.t-margin-left-289 {
  margin-left: 289px !important;
}

.t-margin-290 {
  margin: 290px !important;
}

.t-margin-top-290 {
  margin-top: 290px !important;
}

.t-margin-right-290 {
  margin-right: 290px !important;
}

.t-margin-bottom-290 {
  margin-bottom: 290px !important;
}

.t-margin-left-290 {
  margin-left: 290px !important;
}

.t-margin-291 {
  margin: 291px !important;
}

.t-margin-top-291 {
  margin-top: 291px !important;
}

.t-margin-right-291 {
  margin-right: 291px !important;
}

.t-margin-bottom-291 {
  margin-bottom: 291px !important;
}

.t-margin-left-291 {
  margin-left: 291px !important;
}

.t-margin-292 {
  margin: 292px !important;
}

.t-margin-top-292 {
  margin-top: 292px !important;
}

.t-margin-right-292 {
  margin-right: 292px !important;
}

.t-margin-bottom-292 {
  margin-bottom: 292px !important;
}

.t-margin-left-292 {
  margin-left: 292px !important;
}

.t-margin-293 {
  margin: 293px !important;
}

.t-margin-top-293 {
  margin-top: 293px !important;
}

.t-margin-right-293 {
  margin-right: 293px !important;
}

.t-margin-bottom-293 {
  margin-bottom: 293px !important;
}

.t-margin-left-293 {
  margin-left: 293px !important;
}

.t-margin-294 {
  margin: 294px !important;
}

.t-margin-top-294 {
  margin-top: 294px !important;
}

.t-margin-right-294 {
  margin-right: 294px !important;
}

.t-margin-bottom-294 {
  margin-bottom: 294px !important;
}

.t-margin-left-294 {
  margin-left: 294px !important;
}

.t-margin-295 {
  margin: 295px !important;
}

.t-margin-top-295 {
  margin-top: 295px !important;
}

.t-margin-right-295 {
  margin-right: 295px !important;
}

.t-margin-bottom-295 {
  margin-bottom: 295px !important;
}

.t-margin-left-295 {
  margin-left: 295px !important;
}

.t-margin-296 {
  margin: 296px !important;
}

.t-margin-top-296 {
  margin-top: 296px !important;
}

.t-margin-right-296 {
  margin-right: 296px !important;
}

.t-margin-bottom-296 {
  margin-bottom: 296px !important;
}

.t-margin-left-296 {
  margin-left: 296px !important;
}

.t-margin-297 {
  margin: 297px !important;
}

.t-margin-top-297 {
  margin-top: 297px !important;
}

.t-margin-right-297 {
  margin-right: 297px !important;
}

.t-margin-bottom-297 {
  margin-bottom: 297px !important;
}

.t-margin-left-297 {
  margin-left: 297px !important;
}

.t-margin-298 {
  margin: 298px !important;
}

.t-margin-top-298 {
  margin-top: 298px !important;
}

.t-margin-right-298 {
  margin-right: 298px !important;
}

.t-margin-bottom-298 {
  margin-bottom: 298px !important;
}

.t-margin-left-298 {
  margin-left: 298px !important;
}

.t-margin-299 {
  margin: 299px !important;
}

.t-margin-top-299 {
  margin-top: 299px !important;
}

.t-margin-right-299 {
  margin-right: 299px !important;
}

.t-margin-bottom-299 {
  margin-bottom: 299px !important;
}

.t-margin-left-299 {
  margin-left: 299px !important;
}

.t-margin-300 {
  margin: 300px !important;
}

.t-margin-top-300 {
  margin-top: 300px !important;
}

.t-margin-right-300 {
  margin-right: 300px !important;
}

.t-margin-bottom-300 {
  margin-bottom: 300px !important;
}

.t-margin-left-300 {
  margin-left: 300px !important;
}

.t-padding-0 {
  padding: 0px !important;
}

.t-padding-top-0 {
  padding-top: 0px !important;
}

.t-padding-right-0 {
  padding-right: 0px !important;
}

.t-padding-bottom-0 {
  padding-bottom: 0px !important;
}

.t-padding-left-0 {
  padding-left: 0px !important;
}

.t-padding-1 {
  padding: 1px !important;
}

.t-padding-top-1 {
  padding-top: 1px !important;
}

.t-padding-right-1 {
  padding-right: 1px !important;
}

.t-padding-bottom-1 {
  padding-bottom: 1px !important;
}

.t-padding-left-1 {
  padding-left: 1px !important;
}

.t-padding-2 {
  padding: 2px !important;
}

.t-padding-top-2 {
  padding-top: 2px !important;
}

.t-padding-right-2 {
  padding-right: 2px !important;
}

.t-padding-bottom-2 {
  padding-bottom: 2px !important;
}

.t-padding-left-2 {
  padding-left: 2px !important;
}

.t-padding-3 {
  padding: 3px !important;
}

.t-padding-top-3 {
  padding-top: 3px !important;
}

.t-padding-right-3 {
  padding-right: 3px !important;
}

.t-padding-bottom-3 {
  padding-bottom: 3px !important;
}

.t-padding-left-3 {
  padding-left: 3px !important;
}

.t-padding-4 {
  padding: 4px !important;
}

.t-padding-top-4 {
  padding-top: 4px !important;
}

.t-padding-right-4 {
  padding-right: 4px !important;
}

.t-padding-bottom-4 {
  padding-bottom: 4px !important;
}

.t-padding-left-4 {
  padding-left: 4px !important;
}

.t-padding-5 {
  padding: 5px !important;
}

.t-padding-top-5 {
  padding-top: 5px !important;
}

.t-padding-right-5 {
  padding-right: 5px !important;
}

.t-padding-bottom-5 {
  padding-bottom: 5px !important;
}

.t-padding-left-5 {
  padding-left: 5px !important;
}

.t-padding-6 {
  padding: 6px !important;
}

.t-padding-top-6 {
  padding-top: 6px !important;
}

.t-padding-right-6 {
  padding-right: 6px !important;
}

.t-padding-bottom-6 {
  padding-bottom: 6px !important;
}

.t-padding-left-6 {
  padding-left: 6px !important;
}

.t-padding-7 {
  padding: 7px !important;
}

.t-padding-top-7 {
  padding-top: 7px !important;
}

.t-padding-right-7 {
  padding-right: 7px !important;
}

.t-padding-bottom-7 {
  padding-bottom: 7px !important;
}

.t-padding-left-7 {
  padding-left: 7px !important;
}

.t-padding-8 {
  padding: 8px !important;
}

.t-padding-top-8 {
  padding-top: 8px !important;
}

.t-padding-right-8 {
  padding-right: 8px !important;
}

.t-padding-bottom-8 {
  padding-bottom: 8px !important;
}

.t-padding-left-8 {
  padding-left: 8px !important;
}

.t-padding-9 {
  padding: 9px !important;
}

.t-padding-top-9 {
  padding-top: 9px !important;
}

.t-padding-right-9 {
  padding-right: 9px !important;
}

.t-padding-bottom-9 {
  padding-bottom: 9px !important;
}

.t-padding-left-9 {
  padding-left: 9px !important;
}

.t-padding-10 {
  padding: 10px !important;
}

.t-padding-top-10 {
  padding-top: 10px !important;
}

.t-padding-right-10 {
  padding-right: 10px !important;
}

.t-padding-bottom-10 {
  padding-bottom: 10px !important;
}

.t-padding-left-10 {
  padding-left: 10px !important;
}

.t-padding-11 {
  padding: 11px !important;
}

.t-padding-top-11 {
  padding-top: 11px !important;
}

.t-padding-right-11 {
  padding-right: 11px !important;
}

.t-padding-bottom-11 {
  padding-bottom: 11px !important;
}

.t-padding-left-11 {
  padding-left: 11px !important;
}

.t-padding-12 {
  padding: 12px !important;
}

.t-padding-top-12 {
  padding-top: 12px !important;
}

.t-padding-right-12 {
  padding-right: 12px !important;
}

.t-padding-bottom-12 {
  padding-bottom: 12px !important;
}

.t-padding-left-12 {
  padding-left: 12px !important;
}

.t-padding-13 {
  padding: 13px !important;
}

.t-padding-top-13 {
  padding-top: 13px !important;
}

.t-padding-right-13 {
  padding-right: 13px !important;
}

.t-padding-bottom-13 {
  padding-bottom: 13px !important;
}

.t-padding-left-13 {
  padding-left: 13px !important;
}

.t-padding-14 {
  padding: 14px !important;
}

.t-padding-top-14 {
  padding-top: 14px !important;
}

.t-padding-right-14 {
  padding-right: 14px !important;
}

.t-padding-bottom-14 {
  padding-bottom: 14px !important;
}

.t-padding-left-14 {
  padding-left: 14px !important;
}

.t-padding-15 {
  padding: 15px !important;
}

.t-padding-top-15 {
  padding-top: 15px !important;
}

.t-padding-right-15 {
  padding-right: 15px !important;
}

.t-padding-bottom-15 {
  padding-bottom: 15px !important;
}

.t-padding-left-15 {
  padding-left: 15px !important;
}

.t-padding-16 {
  padding: 16px !important;
}

.t-padding-top-16 {
  padding-top: 16px !important;
}

.t-padding-right-16 {
  padding-right: 16px !important;
}

.t-padding-bottom-16 {
  padding-bottom: 16px !important;
}

.t-padding-left-16 {
  padding-left: 16px !important;
}

.t-padding-17 {
  padding: 17px !important;
}

.t-padding-top-17 {
  padding-top: 17px !important;
}

.t-padding-right-17 {
  padding-right: 17px !important;
}

.t-padding-bottom-17 {
  padding-bottom: 17px !important;
}

.t-padding-left-17 {
  padding-left: 17px !important;
}

.t-padding-18 {
  padding: 18px !important;
}

.t-padding-top-18 {
  padding-top: 18px !important;
}

.t-padding-right-18 {
  padding-right: 18px !important;
}

.t-padding-bottom-18 {
  padding-bottom: 18px !important;
}

.t-padding-left-18 {
  padding-left: 18px !important;
}

.t-padding-19 {
  padding: 19px !important;
}

.t-padding-top-19 {
  padding-top: 19px !important;
}

.t-padding-right-19 {
  padding-right: 19px !important;
}

.t-padding-bottom-19 {
  padding-bottom: 19px !important;
}

.t-padding-left-19 {
  padding-left: 19px !important;
}

.t-padding-20 {
  padding: 20px !important;
}

.t-padding-top-20 {
  padding-top: 20px !important;
}

.t-padding-right-20 {
  padding-right: 20px !important;
}

.t-padding-bottom-20 {
  padding-bottom: 20px !important;
}

.t-padding-left-20 {
  padding-left: 20px !important;
}

.t-padding-21 {
  padding: 21px !important;
}

.t-padding-top-21 {
  padding-top: 21px !important;
}

.t-padding-right-21 {
  padding-right: 21px !important;
}

.t-padding-bottom-21 {
  padding-bottom: 21px !important;
}

.t-padding-left-21 {
  padding-left: 21px !important;
}

.t-padding-22 {
  padding: 22px !important;
}

.t-padding-top-22 {
  padding-top: 22px !important;
}

.t-padding-right-22 {
  padding-right: 22px !important;
}

.t-padding-bottom-22 {
  padding-bottom: 22px !important;
}

.t-padding-left-22 {
  padding-left: 22px !important;
}

.t-padding-23 {
  padding: 23px !important;
}

.t-padding-top-23 {
  padding-top: 23px !important;
}

.t-padding-right-23 {
  padding-right: 23px !important;
}

.t-padding-bottom-23 {
  padding-bottom: 23px !important;
}

.t-padding-left-23 {
  padding-left: 23px !important;
}

.t-padding-24 {
  padding: 24px !important;
}

.t-padding-top-24 {
  padding-top: 24px !important;
}

.t-padding-right-24 {
  padding-right: 24px !important;
}

.t-padding-bottom-24 {
  padding-bottom: 24px !important;
}

.t-padding-left-24 {
  padding-left: 24px !important;
}

.t-padding-25 {
  padding: 25px !important;
}

.t-padding-top-25 {
  padding-top: 25px !important;
}

.t-padding-right-25 {
  padding-right: 25px !important;
}

.t-padding-bottom-25 {
  padding-bottom: 25px !important;
}

.t-padding-left-25 {
  padding-left: 25px !important;
}

.t-padding-26 {
  padding: 26px !important;
}

.t-padding-top-26 {
  padding-top: 26px !important;
}

.t-padding-right-26 {
  padding-right: 26px !important;
}

.t-padding-bottom-26 {
  padding-bottom: 26px !important;
}

.t-padding-left-26 {
  padding-left: 26px !important;
}

.t-padding-27 {
  padding: 27px !important;
}

.t-padding-top-27 {
  padding-top: 27px !important;
}

.t-padding-right-27 {
  padding-right: 27px !important;
}

.t-padding-bottom-27 {
  padding-bottom: 27px !important;
}

.t-padding-left-27 {
  padding-left: 27px !important;
}

.t-padding-28 {
  padding: 28px !important;
}

.t-padding-top-28 {
  padding-top: 28px !important;
}

.t-padding-right-28 {
  padding-right: 28px !important;
}

.t-padding-bottom-28 {
  padding-bottom: 28px !important;
}

.t-padding-left-28 {
  padding-left: 28px !important;
}

.t-padding-29 {
  padding: 29px !important;
}

.t-padding-top-29 {
  padding-top: 29px !important;
}

.t-padding-right-29 {
  padding-right: 29px !important;
}

.t-padding-bottom-29 {
  padding-bottom: 29px !important;
}

.t-padding-left-29 {
  padding-left: 29px !important;
}

.t-padding-30 {
  padding: 30px !important;
}

.t-padding-top-30 {
  padding-top: 30px !important;
}

.t-padding-right-30 {
  padding-right: 30px !important;
}

.t-padding-bottom-30 {
  padding-bottom: 30px !important;
}

.t-padding-left-30 {
  padding-left: 30px !important;
}

.t-padding-31 {
  padding: 31px !important;
}

.t-padding-top-31 {
  padding-top: 31px !important;
}

.t-padding-right-31 {
  padding-right: 31px !important;
}

.t-padding-bottom-31 {
  padding-bottom: 31px !important;
}

.t-padding-left-31 {
  padding-left: 31px !important;
}

.t-padding-32 {
  padding: 32px !important;
}

.t-padding-top-32 {
  padding-top: 32px !important;
}

.t-padding-right-32 {
  padding-right: 32px !important;
}

.t-padding-bottom-32 {
  padding-bottom: 32px !important;
}

.t-padding-left-32 {
  padding-left: 32px !important;
}

.t-padding-33 {
  padding: 33px !important;
}

.t-padding-top-33 {
  padding-top: 33px !important;
}

.t-padding-right-33 {
  padding-right: 33px !important;
}

.t-padding-bottom-33 {
  padding-bottom: 33px !important;
}

.t-padding-left-33 {
  padding-left: 33px !important;
}

.t-padding-34 {
  padding: 34px !important;
}

.t-padding-top-34 {
  padding-top: 34px !important;
}

.t-padding-right-34 {
  padding-right: 34px !important;
}

.t-padding-bottom-34 {
  padding-bottom: 34px !important;
}

.t-padding-left-34 {
  padding-left: 34px !important;
}

.t-padding-35 {
  padding: 35px !important;
}

.t-padding-top-35 {
  padding-top: 35px !important;
}

.t-padding-right-35 {
  padding-right: 35px !important;
}

.t-padding-bottom-35 {
  padding-bottom: 35px !important;
}

.t-padding-left-35 {
  padding-left: 35px !important;
}

.t-padding-36 {
  padding: 36px !important;
}

.t-padding-top-36 {
  padding-top: 36px !important;
}

.t-padding-right-36 {
  padding-right: 36px !important;
}

.t-padding-bottom-36 {
  padding-bottom: 36px !important;
}

.t-padding-left-36 {
  padding-left: 36px !important;
}

/*------------------------------------
 # END TRUMP padding
------------------------------------*/
.t-text-align-right {
  text-align: right !important;
}

.t-text-align-center {
  text-align: center !important;
}

.t-text-align-left {
  text-align: left !important;
}