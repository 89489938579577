////
/// MESSAGE component
///
/// Custom project Message
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}message
/// @since version 1.0.0
////

.#{$ns}message {
  .p-message {
    font-family: $ns-font-default;
    font-weight: bold;

    .p-message-wrapper {
      background-color: color('white');
      padding: rem(15) rem(20);
    }

    .p-message-summary {
      color: color('black');
    }

    .p-message-detail {
      color: color('black');
    }

    &.p-message-success {
      border: rem(2) solid color('success');

      .p-message-icon {
        &:before {
          font-family: 'icomoon';
          content: "\e900";
          color: #32c832;
          font-size: rem(30);
        }
      }
    }

    &.p-message-warn {
      border: rem(2) solid color('warning');

      .p-message-icon {
        &:before {
          font-family: 'icomoon';
          content: "\e909";
          color: rgb(255, 204, 0);
          font-size: rem(32);
        }

        &:after {
          font-family: 'icomoon';
          content: "\e90a";
          margin-left: -1em;
          color: rgb(0, 0, 0);
          font-size: rem(32);
        }
      }
    }

    &.p-message-info {
      border: rem(2) solid color('info');

      .p-message-icon {
        &:before {
          font-family: 'icomoon';
          content: "\e908";
          color: color('info');
          font-size: rem(30);
        }
      }
    }

    &.p-message-error {
      border: rem(2) solid color('error');

      .p-message-icon {
        &:before {
          font-family: 'icomoon';
          content: "\e907";
          color: color('error');
          font-size: rem(30);
        }
      }
    }
  }
}
