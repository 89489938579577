////
/// FAQ component
///
/// Custom project FAQ
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}faq
/// @since version 1.0.0
////

.#{$ns}faq {
  background-color: color('white');
  position: relative;

  &__title {
    background-color: transparent;
    padding: rem(20) 0;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    top: rem(-34);

    &:after {
      content: '';
      position: absolute;
      background: color('quaternary');
      width: 100%;
      height: 100%;
      top: 0;
      left: rem(-38);
      transform: skewX(45deg);
      z-index: -1;
    }

    &:before {
      content: '';
      position: absolute;
      background: color('quaternary');
      width: 100%;
      height: 100%;
      transform: skewX(45deg);
      top: 0;
      left: calc(-100% - 37px);
    }
  }

  &__title-txt {
    color: color('secondary');
    font-size: rem(20);
    width: 100%;
    max-width: rem(1140);
    padding: 0 rem(23);
    margin: 0 auto;
  }

  &__content-title {
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('black');
    margin-bottom: rem(13);
    display: block;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(39);
  }

  &__list-item {
    width: 100%;
  }

  &__sub-list-item {
    &:before {
      font-family: 'icomoon';
      content: "\e904";
      color: color('primary');
      font-size: rem(12);
      margin-right: rem(7);
    }
  }

  &__list-content {
    color: color('quaternary');
    font-family: $ns-font-default;

    &:hover {
      color: color('quaternary');
    }
  }
}

@include media-query(xs) {
  .#{$ns}faq {
    &__title-txt {
      padding: 0 rem(16);
      max-width: rem(480);
    }
  }
}

@include media-query(sm) {
  .#{$ns}faq {
    &__title {
      &:after {
        width: 62%;
      }
    }

    &__title-txt {
      max-width: rem(768);
    }
  }
}

@include media-query(md) {
  .#{$ns}faq {
    &__title {
      &:after {
        width: 63%;
      }
    }

    &__title-txt {
      font-size: rem(28);
      max-width: rem(992);
    }

    &__content-title {
      margin-bottom: rem(22);
    }

    &__list-item {
      width: calc(100%/3);
    }
  }
}

@include media-query(lg) {
  .#{$ns}faq {
    &__title {
      &:after {
        width: 60%;
      }
    }

    &__title-txt {
      max-width: rem(1024);
    }
  }
}

@include media-query(xl) {
  .#{$ns}faq {
    &__title-txt {
      max-width: rem(1124);
    }
  }
}
