////
/// SUCCESS component
///
/// Custom project Success
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}success
/// @since version 1.0.0
////

.#{$ns}success {
  display: flex;
  flex-flow: column;
  font-family: $ns-font-default;
  background-color: color('white');
  padding: rem(24) rem(32);

  &__icon {
    font-size: rem(103);
    margin-bottom: rem(14);
  }

  &__title {
    font-size: rem(30);
    color: color('terciary');
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: rem(10);
  }

  &__txt {
    font-size: rem(16);
    line-height: rem(25);
    color: color('quaternary');
  }

  &__link {
    color: color('quaternary');

    .icon-chevron-light-left {
      font-size: rem(12);
      margin-right: rem(9);
    }

    &--bold {
      font-family: $ns-font-default;
      font-weight: bold;
    }

    span {
      &:last-child {
        font-family: $ns-font-default;
        font-weight: bold;
      }
    }

    &:hover {
      color: color('quaternary');
      text-decoration: none;

      span {
        &:last-child {
          text-decoration: underline;
        }
      }
    }
  }
}
