/*-------------------------------------------------------------
    #DROPDOWN
-----------------------------------------------------------------*/

.o-dropdown {
  .p-dropdown {
    border-radius: 0;
    border-width: rem(2);
    border-color: color('quaternary');
    width: 100%;
    max-width: rem(260);
    height: rem(42);
  }

  .p-dropdown-label {
    color: color('black');
    font-family: Arial, Helvetica, sans-serif;
    line-height: rem(23);
  }

  .p-dropdown-panel {
    border-radius: 0;

    .p-dropdown-items {
      .p-dropdown-item {
        color: color('black');

        &.p-highlight {
          background-color: color('primary');
          color: color('white');
        }
      }
    }
  }

  .p-dropdown-trigger-icon {
    color: color('black');
    font-size: rem(12);
  }

  .p-dropdown-items-wrapper {
    font-family: $ns-font-default;
  }

  &__label {
    display: block;
    font-size: rem(16);
    color: color('quaternary');

    &--inline {
      display: inline-block;
      margin-right: rem(8);
    }
  }
}
