////
/// INVOICE component
///
/// Custom project Invoice
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}invoice
/// @since version 1.0.0
////

.#{$ns}invoice {
  width: 100%;
  background-color: color('white');
  padding: rem(31) rem(29);
  font-family: $ns-font-default;
  position: relative;
  overflow: hidden;

  height: rem(456);

  &:after {
    content: '';
    position: absolute;
    width: rem(65);
    height: rem(65);
    background-color: color('primary');
    bottom: rem(-33);
    right: rem(-33);
    transform: rotate(45deg);
  }

  /* Header */
  &__header {
    margin-bottom: rem(11);
  }

  &__header-icon {
    display: none;
  }

  &__header-title-txt {
    font-size: rem(30);
    color: color('terciary');
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  &__header-title-date {
    display: none;
  }

  /* Main */
  &__main {
    display: flex;
    flex-wrap: wrap;
  }

  &__main-data {
    display: block;
    margin-bottom: rem(30);
    width: 100%;

    &:nth-child(3) {
      margin-bottom: rem(20);
    }
  }

  &__main-data-title {
    font-size: rem(16);
    color: color('quaternary');
    font-family: $ns-font-default;
    line-height: rem(19);
    margin-bottom: rem(4);
  }

  &__main-data-info {
    font-size: rem(24);
    color: color('black');
    font-family: $ns-font-default;
    font-weight: bold;
    line-height: rem(19);

    &--primary {
      color: color('primary');
    }
  }

  &__btn {
    &.o-btn {
      padding: rem(16) rem(38);
    }
  }

  /* Footer */
  &__footer {
    position: absolute;
    bottom: rem(31);
  }

  &__footer-link {
    display: block;
    font-family: $ns-font-default;
    color: color('quaternary');
    font-size: rem(16);
    line-height: rem(19);
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: rem(10);
    }

    &:hover {
      color: color('quaternary');
      text-decoration: none;

      a:first-child {
        text-decoration: underline;
      }

      .icon-chevron-light-right {
        text-decoration: none;
      }
    }

    .icon-chevron-light-right {
      color: color('primary');
      font-size: rem(11);
      margin-left: rem(13);
    }
  }

  /* Empty data */
  &__empty-data {
    height: rem(250);

    h3 {
      color: color('black');
    }
  }

  /* Large component */
  &--large {
    height: auto;

    &:after {
      content: none;
    }

    .#{$ns}invoice {
      &__header-title-date {
        display: block;
        font-size: rem(16);
        font-family: $ns-font-default;
        line-height: rem(26);
        color: color('quaternary');
      }

      &__main {
        & > section {
          margin-bottom: rem(30);

          &:nth-child(1) {
            order: 1;
            width: 100%;
          }

          &:nth-child(2) {
            order: 3;
            width: 50%;
          }

          &:nth-child(3) {
            order: 2;
            width: 50%;
          }

          &:nth-child(4) {
            display: block;
            order: 4;
          }
        }
      }

      &__main-data {
        margin-bottom: 0;

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 3;
          width: 50%;
        }

        &:nth-child(3) {
          order: 2;
          width: 50%;
        }

        &:nth-child(4) {
          display: block;
          order: 4;
          width: 100%;
        }
      }

      &__btn {
        width: 100%;
      }

      /* Footer */
      &__footer {
        display: none;
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}invoice {
    /* Large component */
    &--large {
      .#{$ns}invoice {

        &__main {
          & > section {
            &:nth-child(3) {
              width: 35%;
            }
          }
        }

        &__main-data {
          &:nth-child(3) {
            width: 35%;
          }
        }

        &__btn {
          width: auto;
        }
      }
    }
  }
}

@include media-query(md) {
  .#{$ns}invoice {
    /* Large component */
    &--large {
      padding: rem(41) rem(39);
      height: rem(270);

      &:after {
        content: none;
      }

      .#{$ns}invoice {
        /* Header */
        &__header {
          margin-bottom: rem(19);
        }

        &__header-icon {
          display: inline-block;
          font-size: rem(56);
          margin-right: rem(32);
        }

        &__header-title {
          display: inline-block;
        }

        &__header-title-txt {
          font-size: rem(30);
        }

        /* Main */
        &__main {
          justify-content: flex-start;
        }

        &__main {
          & > section {
            width: auto;
            margin-bottom: 0;
            margin-right: rem(61);

            &:nth-child(2) {
              order: 3;
              width: auto;
            }

            &:nth-child(3) {
              order: 2;
              width: auto;
            }

            &:nth-child(4) {
              width: auto;
            }
          }
        }

        &__main-data {
          width: auto;
          margin-bottom: 0;
          margin-right: rem(61);

          &:nth-child(2) {
            order: 3;
            width: auto;
          }

          &:nth-child(3) {
            order: 2;
            width: auto;
          }
        }

        &__main-data-title {
          font-size: rem(16);
          color: color('quaternary');
        }

        &__main-data-info {
          font-size: rem(24);
          color: color('black');
        }

        &__btn {
          margin-top: rem(25);
          font-size: rem(16);
        }
      }
    }
  }
}

@include media-query(lg) {
  .#{$ns}invoice {
    /* Large component */
    &--large {
      .#{$ns}invoice {
        &__main {
          & > section {
            &:nth-child(1) {
              width: auto;
            }
          }
        }
      }
    }
  }
}
