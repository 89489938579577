/*-------------------------------------------------------------
    #OVERLAY PANEL
-----------------------------------------------------------------*/
.iris-overlay {
  &.p-overlaypanel {
    border-radius: 0;
    opacity: 1;

    background-color: transparent;
    border: none;
    box-shadow: none;

    &.p-overlaypanel-flipped {
      &::before,
      &::after {
        border-top-color: color('quaternary');
      }
    }

    &:before,
    &:after {
      border-bottom-color: color('quaternary');
    }

    .p-overlaypanel-content {
      // background: transparent;
      background: color('quaternary');
      position: relative;
      overflow: hidden;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   width: calc(100%*2);
      //   height: calc(100%*2);
      //   transform: rotate(45deg);
      //   background: color('quaternary');
      //   z-index: -1;
      //   top: 0;
      //   left: -50%;
      //   // transform: skewX(-45deg);
      //   // transform: skewX(-45deg) translateX(50%);
      //   transform-origin: right top;
      // }
    }

    .o-overlay {
      width: rem(240);
      font-family: $ns-font-default;
      color: color('black');

      .p-overlaypanel-content {
        padding: 0;
      }

      &__content {
        text-align: left;
      }

      &__main-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          border-bottom: 0;
        }

        &:not(:last-child) {
          margin-bottom: rem(8);
        }

      }

      &__main-link {
        color: color('white');
        font-family: $ns-font-default;
        font-weight: bold;
        font-size: rem(16);
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(13);

        &:hover, &:focus {
          text-decoration: none;
          color: color('secondary');

          &::after {
            color: color('secondary');
          }
        }

        &::after{
          content: "\e901";
          font-family: 'primeicons';
          color: color('white');
          font-size: 0.75rem;
          float: right;
          line-height: 2rem;
          margin-left: rem(20);
        }
        &--mb-none {
          margin-bottom: 0;
        }

        &--no-icon {
          &::after {
            content: none;
          }
        }
      }

      &__main-link-icon {
        font-size: rem(12);

      }

      &__main-info {
        font-size: rem(14);
        color: color('grey-1');
        display: flex;
        justify-content: space-between;
        margin-right: rem(19);
        line-height: rem(19);
        margin-bottom: rem(7);
      }

      &__main-client-name {
        padding-bottom: rem(7);
        text-align: center;
        color: color('grey-1');
        font-family: $ns-font-default;

        span {
          &:first-child {
            margin-right: rem(3);
          }

          &:last-child {
            font-family: $ns-font-default;
            font-weight: bold;
          }
        }
      }
    }
  }

  &--medium {
    &.p-overlaypanel {
      min-width: rem(240);
    }
  }
}
@include media-query(sm) {
  .o-overlay {
    &__main-section {
      &--responsive {
        display: none;
      }
    }
  }
}
