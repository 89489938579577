////
/// DISPLAY trump
///
/// This is the hide triumphs styles
////

@if $include__trumps--display {
    
    ////
    /// Display trump
    /// @access public
    /// @author Dfront Specialist Team
    /// @group trumps
    /// @name .t-#{visible/invisible}(-#{breakpoint} ?)
    /// @since version 1.0.0
    ////
    @each $property, $value in (visible: visible, invisible: hidden) {
        .t-#{$property} {
            visibility: #{$value} !important;
        }
        @each $breakpoint, $size in $breakpoints {
            .t-#{$property}-#{$breakpoint} {
                @include media-query($breakpoint) {
                    visibility: #{$value} !important;
                }
            }
        }
    }

    ////
    /// Display trump
    /// @access public
    /// @author Dfront Specialist Team
    /// @group trumps
    /// @name .t-display-#{type}(-#{breakpoint} ?)
    /// @since version 1.0.0
    ////
    @each $type in $displays {
        .t-display-#{$type} {
            display: #{$type} !important;
        }
        @each $breakpoint, $size in $breakpoints {
            .t-display-#{$type}-#{$breakpoint} {
                @include media-query($breakpoint) {
                    display: #{$type} !important;
                }
            }
        }
    }

    // // Loop over our breakpoints defined in _settings.responsive.scss
    // @each $breakpoint in $breakpoints {
    //     // Get the name of the breakpoint.
    //     $alias: nth($breakpoint, 1);

    //     @include media-query($alias) {

    //         /// Hide trump in a given breakpoint
    //         /// @access public
    //         /// @author Dfront Specialist Team
    //         /// @group trumps
    //         /// @name .t-display-#{type}(-#{breakpoint} ?)
    //         /// @example scss .t-hide-sm
    //         // / media-query(sm){
    //         // /    .t-hide-sm{
    //         // /        display: none !important;
    //         // /    }
    //         // / }
    //         /// @see media-query
    //         /// @since version 1.0.0
    //         .t-hide-#{$alias} {
    //             display: none !important;
    //         }

    //     }

    //     // Close media query.
    // }

}
