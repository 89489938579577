////
/// MAIN component
///
/// Custom project main
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}main
/// @since version 1.0.0
////

.#{$ns}main {
  width: 100%;
  background-color: color('white');

  &__container {
    padding: rem(34) rem(22);
  }

  &__title {
    font-size: rem(30);
    line-height: rem(32);
    margin-bottom: rem(27);
  }

  &__txt {
    line-height: rem(20);
    color: color('quaternary');

    &--mb-small {
      margin-bottom: rem(30);
    }
  }

  &__success-txt {
    font-family: $ns-font-default;
    font-weight: bold;
    font-size: rem(20);
    line-height: rem(29);
    color: color('black');
    margin-bottom: rem(7);
  }

  &__success-subtxt {
    font-family: $ns-font-default;
    font-size: rem(16);
    line-height: rem(24);
    color: color('quaternary');

    a {
      color: color('quaternary');
      text-decoration: underline;
    }
  }

  &__wrapper {
    width: 100%;
    margin-bottom: rem(29);

    .p-float-label {
      &.o-inputnumber {
        .p-inputnumber-input {
          width: 100%;
          max-width: none;
        }
      }

      &.o-inputtext {
        .o-inputtext__input {
          width: 100%;
          max-width: none;
        }
      }

      &.o-password {
        .p-inputtext {
          width: 100%;
          max-width: none;
          padding-right: rem(94);
        }
      }

      label {
        top: rem(20);
      }
    }

    .o-inputnumber {
      width: 100%;
    }

    .o-inputnumber--inline-block {
      width: 100%;
      padding-right: 0;
    }

    .o-inputnumber__input {
      width: 100%;
      .p-inputnumber {
        height: rem(40);
        border-radius: 0;
      }
    }

    .o-inputtext {
      width: 100%;
    }

    .o-inputtext--inline-block {
      width: auto;
      padding-right: rem(25);
    }

    .o-inputtext__input.o-inputtext__input--small {
      width: rem(217);
      height: rem(40);
      border-radius: 0;
    }

    .o-password {
      position: relative;
    }

    .o-password__strength {
      vertical-align: middle;
      margin-left: rem(25);
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      right: 0;
    }

    .p-error {
      display: block;
      color: color('error');
      font-size: rem(12);
      margin-left: rem(3);
    }

    .o-btn--block-auto {
      width: 100%;
    }

    &--mb-medium {
      margin-bottom: rem(22);
    }
  }

  &__icon-big {
    font-size: rem(100);
    display: block;
    text-align: center;
    margin-top: rem(40);
    margin-bottom: rem(20);
  }

  &__link {
    color: color('quaternary');
    text-decoration: underline;
    font-size: rem(14);
    margin-top: rem(12);

    &:hover {
      color: color('quaternary');
    }
  }

  &__btn-clear {
    position: absolute;
    top: rem(20);
    left: rem(190);
    transform: translateY(-50%);
  }

  &__btn {
    &.p-button {
      background-color: color('terciary') !important;
      border-color: color('terciary') !important;
      width: 100% !important;
      margin-top: rem(5);

      &:hover {
        color: color('terciary') !important;
        background-color: color('secondary') !important;
        border-color: color('secondary') !important;
      }
    }
  }

  .#{$ns}tooltip {
    display: none;
    left: rem(235);
  }
}

@include media-query(sm) {
  .#{$ns}main {
    &__txt {
      &--mb-small {
        margin-bottom: rem(40);
      }
    }

    &__link {
      margin-top: rem(6);
    }

    &__wrapper {
      .p-float-label {
        &.o-inputnumber {
          .p-inputnumber-input {
            max-width: rem(260);
          }
        }

        &.o-inputtext {
          .o-inputtext__input {
            max-width: rem(260);
          }
        }

        &.o-password {
          .p-inputtext {
            max-width: rem(260);
            padding-right: rem(34);
          }
        }
      }

      .o-inputnumber--inline-block {
        width: rem(260);
      }

      .o-password__strength {
        margin-left: rem(22);
        right: auto;
      }

      .o-btn--block-auto {
        width: auto;
      }
    }

    &__btn {
      &.p-button {
        width: rem(108) !important;
      }
    }

    .#{$ns}tooltip {
      display: block;
    }
  }
}

@include media-query(md) {
  .#{$ns}main {
    &__container {
      margin-left: auto;
      margin-right: auto;
      padding: rem(35) rem(25);
    }

    &__title {
      font-size: rem(30);
      line-height: rem(20);
    }
  }
}

