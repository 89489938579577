/*-------------------------------------------------------------
	#INPUT NUMBER
-----------------------------------------------------------------*/

.o-inputnumber {
  &__input {
    .p-inputnumber-input {
      border-radius: 0;
      color: color('quaternary');
      font-family: $ns-font-default;
      font-size: rem(16);
      padding: rem(8) rem(15);
      border-color: color('quaternary');
      width: 100%;

      &:disabled {
        background-color: color('disabled');
      }

      &.ng-invalid.ng-dirty {
        border-color: color('error');
      }

      &:hover:not([disabled]) {
        border-color: color('terciary');
      }

      &.p-inputtext:enabled:focus {
        box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
        border-color: color('terciary');
      }
    }
  }

  &__label {
    font-size: rem(16);
    font-family: $ns-font-default;
  }

  &.p-float-label {
    display: block;

    .p-inputnumber {
      width: 100%;
    }

    input {
      border-width: 0 0 rem(1);
      padding: rem(8) 0;
      width: 100%;
      max-width: rem(260);
      height: rem(42);
      border-radius: 0;
      border-color: color('quaternary');
      font-family: $ns-font-default;
      color: color('quaternary');
      padding: rem(8) 0;

      &.p-inputtext:enabled:focus {
        box-shadow: none;
      }
    }

    .p-button {
      width: rem(108);
      height: rem(40);
      border-radius: 0;
      color: color('white');
      background-color: color('primary');
      font-family: $ns-font-default;
      font-weight: bold;
      font-size: rem(16);
    }

    > label {
      color: color('quaternary');
      font-size: rem(16);
      top: rem(23);
      left: 0;
    }

    input:focus ~ label,
    input.p-filled ~ label,
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label {
      color: color('terciary');
      font-size: rem(12);
      left: 0;
      top: rem(2);
    }

    &.ng-invalid {
      border-color: color('error');
    }

    > .ng-invalid.ng-dirty + label {
      color: color('error');
    }

    .p-error {
      margin-top: rem(6);
    }
  }

  .p-error {
    display: block;
    color: color('error');
    font-size: rem(12);
  }

  &--mb-small {
    margin-bottom: rem(27);
  }

  &--inline {
    &.p-float-label {
      display: inline;

    }
  }

  &--inline-block {
    &.p-float-label {
      display: inline-block;
      width: auto;
    }
  }

  &--rounded {
    .o-inputnumber__input {
      .p-inputnumber-input {
        border-radius: rem(10);

      }
    }
  }
}
