////
/// NEW TARIFF component
///
/// Custom project New tariff
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}new-tariff
/// @since version 1.0.0
////

.#{$ns}new-tariff {
  font-family: $ns-font-default;

  &__title {
    font-size: rem(20);
    margin-bottom: rem(16);
  }

  &__txt {
    line-height: rem(20);
  }

  &__tariff-wrapper {
    padding-top: rem(6);
    padding-bottom: rem(49);
  }

  &__tariff {
    background-color: color('white');
    width: rem(260);
    padding: rem(20);

    &:not(:last-child) {
      margin-right: rem(20);
    }
  }

  &__data {
    background-color: color('primary');
    width: 100%;
    height: rem(155);
    position: relative;
    text-align: right;
    margin-bottom: rem(18);
    overflow: hidden;

    &-title {
      color: color('white');
      font-family: obviously, sans-serif;
      font-weight: 700;
      font-style: normal;
      padding: 7px 6px;
      width: rem(90);
      height: rem(90);
      background-color: color('primary');
      display: inline-block;
      position: absolute;
      top: 50%;
      right: rem(20);
      transform: translateY(-50%);

      &:before,
      &:after {
        content: '';
        position: absolute;
        background-color: color('terciary');
      }

      &:before {
        top: 50vw;
        left: 100%;
        width: 100vw;
        height: calc(100% + 1px);
        transform: skewY(45deg);
      }

      &:after {
        top: 100%;
        left: 50vh;
        width: 100%;
        height: 100vh;
        transform: skewx(45deg);
      }

      & > span {
        display: block;

        &:first-child {
          font-size: rem(52);
        }

        &:last-child {
          font-size: rem(20);
        }
      }
    }

    &-recom {
      color: color('white');
      font-size: rem(14);
      font-family: obviously, sans-serif;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: top left;
      bottom: rem(-15);
      left: 0;
    }

    &--recom {
      background-color: color('pink');
    }
  }

  &__offer {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(18);

    &-txt {
      font-size: rem(16);
      font-family: obviously, sans-serif;
      font-weight: 700;
      font-style: normal;
      color: color('blue-2');
      line-height: rem(20);
      margin-top: rem(6);
    }

    &-price {
      font-family: obviously, sans-serif;
      font-weight: 700;
      font-style: normal;
      color: color('white');
      background-color: color('terciary');
      padding: rem(11) rem(16);
      border-radius: 50% 0 50% 50%;

      & > span {
        &:first-child {
          font-size: rem(40);
          display: block;
          line-height: rem(35);
        }

        &:last-child {
          font-size: rem(10);
        }
      }
    }
  }

  &__current {
    padding: rem(16) rem(32);
    line-height: rem(19);
    font-size: rem(16);
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: color('terciary');
  }

  &__tariff-title {
    margin-bottom: rem(5);
  }

  &__tariff-tip-title {
    font-size: rem(24);
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    padding: 0 rem(9);
    margin: rem(10) 0 rem(16);
  }

  &__txt {
    font-size: rem(16);
    font-family: $ns-font-default;
    color: color('quaternary');
    padding: 0 rem(9);
    line-height: rem(18);
    margin-bottom: rem(18);
  }

  &__tariff-tag {
    position: absolute;
    color: color('white');
    background-color: color('black');
    font-family: $ns-font-default;
    font-weight: bold;
    padding: rem(3) 0;
    width: 70%;
    border-radius: rem(20);
    top: rem(-16);
    left: 50%;
    transform: translateX(-50%);
  }

  &__tariff-contract {
    font-family: $ns-font-default;
    font-weight: bold;
    font-style: italic;
    color: color('primary');
    margin-bottom: rem(19);
  }

  ///////Carousel
  .p-carousel {
    .p-carousel-prev,
    .p-carousel-next {
      display: none;
    }

    .p-carousel-indicator {
      button {
        width: rem(15);
        height: rem(15);
        border-radius: 50%;
        background-color: color('quaternary');

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: color('secondary');
        }
      }

      &.p-highlight {
        button {
          background-color: color('secondary');

        }
      }
    }

    .p-carousel-item {
      display: flex;
      justify-content: center;
    }
  }
}

@include media-query(xl) {
  .#{$ns}new-tariff {
    &__tariff {
      &--tip {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: color('secondary');
          z-index: -1;
        }

        &:before {
          top: 50vw;
          left: 100%;
          width: 100vw;
          height: calc(100% + 1px);
          transform: skewY(45deg);
        }

        &:after {
          top: 100%;
          left: 50vh;
          width: 100%;
          height: 100vh;
          transform: skewx(45deg);
        }
      }
    }
    ///////Carousel
    .p-carousel {
      .p-carousel-indicators {
        display: none;
      }

      .p-carousel-content {
        overflow: visible;
      }

      .p-carousel-items-content {
        overflow: visible;
      }
    }
  }
}
