////
/// HEADER component
///
/// Custom project Header
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}header
/// @since version 1.0.0
////

.#{$ns}header {
  color: color('white');
  padding-top: rem(27);

  &__nav {
    padding: rem(10) rem(3) 0;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
  }

  &__item {
    font-size: rem(14);
    color: color('white');
    font-family: Arial;

    &:not(:last-child) {
      margin-right: rem(29);
    }

    &--desktop {
      display: none;
    }

    .icon-chevron-light-down {
      font-size: rem(12);
      margin-left: rem(4);
      vertical-align: middle;
    }
  }

  &__link {
    color: color('white');

    &:hover {
      color: color('white');
    }
  }

  &__btn {
    color: color('white');
  }

  &__logo {
    // background-image: url(../../images/logo.png);
    background-repeat: no-repeat;
    width: rem(107);
    height: rem(55);
    display: block;
    background-size: contain;
  }
}

@include media-query(sm) {
  .#{$ns}header {
    padding-top: 0;

    &__logo {
      width: rem(187);
      height: rem(91)
    }
  }
}
