////
/// PRODUCTS component
///
/// Custom project Products
///
/// @access public
/// @author Dfront Specialist Team
/// @group btn
/// @name .#{$ns}products
/// @since version 1.0.0
////

.#{$ns}products {
  font-family: $ns-font-default;

  &__list {
    position: relative;
  }

  &__item {
    background-color: color('white');
    padding: rem(27) rem(47) rem(39) rem(24);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    &:not(:last-child) {
      border-bottom: rem(1) solid color('quaternary');
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(17);
    border-bottom: rem(2) solid color('grey-1');
  }

  &__details-tariff {
    width: 100%;
    margin-bottom: rem(25);
  }

  &__details-tariff-number {
    font-size: rem(14);
    line-height: rem(22);
    font-family: $ns-font-default;
    display: block;
    color: color('quaternary');
    margin-bottom: rem(4);
  }

  &__details-tariff-name {
    font-size: rem(24);
    line-height: rem(22);
    font-family: obviously, sans-serif;
    font-weight: 700;
    font-style: normal;
    display: block;
    color: color('terciary');
  }

  &__details-info {
    display: flex;
    margin-bottom: rem(22);
    width: 100%;
  }

  &__details-info-block {
    &:first-child {
      margin-right: rem(56);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__details-info-title {
    text-transform: uppercase;
    font-size: rem(12);
    line-height: rem(22);
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
    display: block;
    margin-bottom: rem(3);
  }

  &__details-info-data {
    font-size: rem(16);
    line-height: rem(22);
    font-family: $ns-font-default;
    font-weight: bold;
    display: block;
    color: color('black');
  }

  &__links {
    margin-top: rem(9);
  }

  &__link {
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
    display: block;
    line-height: rem(26);

    &:hover {
      color: color('quaternary');
    }

    span {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &__link-icon {
    font-size: rem(12);
    margin-left: rem(12);
  }

  &__new-tariff {
    position: absolute;
    margin-left: rem(107);
    bottom: rem(-80);
  }

  &__new-tariff-link {
    border: rem(6) solid color('quaternary');
    border-radius: 50%;
    background-color: color('white');
    display: inline-block;
    padding: rem(10);
    margin-right: rem(20);

    &:hover {
      text-decoration: none;
    }
  }

  &__new-tariff-icon {
    font-size: rem(18);
    color: color('black');
    display: block;
  }

  &__new-tarif-txt {
    font-size: rem(16);
    font-family: $ns-font-default;
    font-weight: bold;
    color: color('quaternary');
  }
}

@include media-query(sm) {
  .#{$ns}products {
    &__link {
      display: inline-block;
      margin-right: rem(50);
      line-height: rem(24);
    }
  }
}

@include media-query(md) {
  .#{$ns}products {
    &__details-tariff {
      order: 1;
      margin-bottom: 0;
    }

    &__details-tariff-number {
      margin-bottom: rem(6);
    }

    &__details-info {
      order: 3;
      margin-bottom: 0;
      width: auto;
    }

    &__links {
      order: 2;
    }

    &__new-tarif-txt {
      font-size: rem(20);
    }
  }
}
